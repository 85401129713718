import { useGetAssetsQuery } from "basics/services/assetApi";
import PropTypes from "prop-types";
import selectAssets from "public_basics/selectAssets";
import React from "react";
import TreeSelect from "../Selects/TreeSelect";

function AssetSelection(props) {
  const {
    onChange,
    value,
    disabled,
    isGridCell,
    isValid,
    className,
    disableIndeterminate,
  } = props;

  const { assets } = useGetAssetsQuery(undefined, {
    selectFromResult: (res) => ({
      ...res,
      assets: selectAssets(res, { filterDeleted: true, excluded: value }),
    }),
  });

  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      backgroundColor: "inherit",
      boxShadow: "unset",
    }),
  };

  return (
    <TreeSelect
      onChange={onChange}
      options={assets}
      value={value ?? []}
      disabled={disabled}
      customStyles={isGridCell ? customStyles : null}
      isValid={isValid}
      portalTarget={props.portalTarget}
      className={className}
      disableIndeterminate={disableIndeterminate}
    />
  );
}

AssetSelection.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  isGridCell: PropTypes.bool,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  disableIndeterminate: PropTypes.bool,
};

export default AssetSelection;
