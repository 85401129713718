import AssetSelection from "../Assets/AssetSelection";
import { css } from "@emotion/css";
import React, { useState } from "react";

export default function FilterBuilderAssetSelect(props) {
  const [value, setValue] = useState(props.data.value);

  return (
    <AssetSelection
      className={css`
        min-width: 300px;
      `}
      value={value}
      disableIndeterminate
      onChange={(value) => {
        props.data.setValue(value);
        setValue(value);
      }}
    />
  );
}
