import { useComponent } from "@imes/module-host";

export const STATUS_COLUMN_VIEW = "STATUS_COLUMN_VIEW";
export const TRANSITION_ALERT = "TRANSITION_ALERT";
export const DATA_GRID_WRAPPER = "DATA_GRID_WRAPPER";
export const CUSTOM_MODAL = "CUSTOM_MODAL";
export const CONFIRM_DELETE_DIALOG = "CONFIRM_DELETE_DIALOG";
export const CONFIRM_DIALOG = "CONFIRM_DIALOG";
export const EXPANDABLE_TEXT_AREA = "EXPANDABLE_TEXT_AREA";
export const FLOATING_ACTION_BUTTON = "FLOATING_ACTION_BUTTON";
export const DATE_TIME_PICKER_INPUT = "DATE_TIME_PICKER_INPUT";
export const ICON_BUTTON = "ICON_BUTTON";
export const EXPAND_BADGE = "EXPAND_BADGE";
export const POSITIONED_DROPDOWN = "POSITIONED_DROPDOWN";
export const TOOLTIP = "TOOLTIP";
export const ASSET_SELECTION = "ASSET_SELECTION";
export const TABLE_SELECT = "TABLE_SELECT";
export const TEXT_SELECT = "TEXT_SELECT";
export const SKELETON_DATA_GRID = "SKELETON_DATA_GRID";
export const CUSTOMIZED_SELECT = "CUSTOMIZED_SELECT";
export const RELEVANT_ENTRIES_COUNT = "RELEVANT_ENTRIES_COUNT";
export const NAVIGATION_ITEM = "NAVIGATION_ITEM";
export const NAVIGATION_LINK = "NAVIGATION_LINK";
export const USER_AND_GROUP_SELECTION = "USER_AND_GROUP_SELECTION";
export const REFRESH_BUTTON = "REFRESH_BUTTON";
export const USER_GUID_TO_NAME = "USER_GUID_TO_NAME";
export const DYNAMIC_ICON = "DYNAMIC_ICON";
export const PICTURE_GALLERY = "PICTURE_GALLERY";
export const NAVIGATE_FROM_MIDDLEWARE = "NAVIGATE_FROM_MIDDLEWARE";
export const CONDITIONAL_OVERLAY = "CONDITIONAL_OVERLAY";
export const INFO_POPOVER = "INFO_POPOVER";
export const CHIP = "CHIP";
export const NUMBER_INPUT = "NUMBER_INPUT";
export const CHANGE_PASSWORD_DIALOG = "CHANGE_PASSWORD_DIALOG";
export const TIME_FILTER = "TIME_FILTER";
export const DATA_GRID_FABS = "DATA_GRID_FABS";
export const ENTRY_LINKS = "ENTRY_LINKS";
export const BASE_ENTRY_LINK = "BASE_ENTRY_LINK";
export const TREE_LIST = "TREE_LIST";

export const StatusColumnView = (props) =>
  useComponent(STATUS_COLUMN_VIEW, props);

export const TransitionAlert = (props) => useComponent(TRANSITION_ALERT, props);

export const FloatingActionButton = (props) =>
  useComponent(FLOATING_ACTION_BUTTON, props);

export const DateTimePickerInput = (props) =>
  useComponent(DATE_TIME_PICKER_INPUT, props);

export const IconButton = (props) => useComponent(ICON_BUTTON, props);

export const DataGridWrapper = (props) =>
  useComponent(DATA_GRID_WRAPPER, props);

export const CustomModal = (props) => useComponent(CUSTOM_MODAL, props);

export const ConfirmDeleteDialog = (props) =>
  useComponent(CONFIRM_DELETE_DIALOG, props);

export const ConfirmDialog = (props) => useComponent(CONFIRM_DIALOG, props);

export const ExpandableTextArea = (props) =>
  useComponent(EXPANDABLE_TEXT_AREA, props);

export const ExpandBadge = (props) => useComponent(EXPAND_BADGE, props);

export const PositionedDropdown = (props) =>
  useComponent(POSITIONED_DROPDOWN, props);

export const Tooltip = (props) => useComponent(TOOLTIP, props);

export const AssetSelection = (props) => useComponent(ASSET_SELECTION, props);

export const TableSelect = (props) => useComponent(TABLE_SELECT, props);

export const TextSelect = (props) => useComponent(TEXT_SELECT, props);

export const SkeletonDataGrid = (props) =>
  useComponent(SKELETON_DATA_GRID, props);

export const CustomizedSelect = (props) =>
  useComponent(CUSTOMIZED_SELECT, props);

export const RelevantEntriesCount = (props) =>
  useComponent(RELEVANT_ENTRIES_COUNT, props);

export const NavigationItem = (props) => useComponent(NAVIGATION_ITEM, props);

export const NavigationLink = (props) => useComponent(NAVIGATION_LINK, props);

export const UserAndGroupSelection = (props) =>
  useComponent(USER_AND_GROUP_SELECTION, props);

export const RefreshButton = (props) => useComponent(REFRESH_BUTTON, props);

export const UserGuidToName = (props) => useComponent(USER_GUID_TO_NAME, props);

export const DynamicIcon = (props) => useComponent(DYNAMIC_ICON, props);

export const PictureGallery = (props) => useComponent(PICTURE_GALLERY, props);

export const ConditionalOverlay = (props) =>
  useComponent(CONDITIONAL_OVERLAY, props);

export const InfoPopover = (props) => useComponent(INFO_POPOVER, props);

export const Chip = (props) => useComponent(CHIP, props);

export const NumberInput = (props) => useComponent(NUMBER_INPUT, props);

export const ChangePasswordDialog = (props) =>
  useComponent(CHANGE_PASSWORD_DIALOG, props);

export const TimeFilter = (props) => useComponent(TIME_FILTER, props);

export const DataGridFabs = (props) => useComponent(DATA_GRID_FABS, props);

export const EntryLinks = (props) => useComponent(ENTRY_LINKS, props);

export const BaseEntryLink = (props) => useComponent(BASE_ENTRY_LINK, props);

export const TreeList = (props) => useComponent(TREE_LIST, props);
