import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "basics/dynamicBaseQuery";
import { metaApi } from "basics/services/metaApi";
import { createUuidAlphanumericSortEntityAdapter } from "../utils/utils";
import { createSelector } from "@reduxjs/toolkit";
import i18n from "i18next";

const shiftGroupAdapter = createUuidAlphanumericSortEntityAdapter();
const shiftGroupInitialState = shiftGroupAdapter.getInitialState();

export const shiftGroupSelectors = shiftGroupAdapter.getSelectors();

const shiftModelAdapter = createUuidAlphanumericSortEntityAdapter();
const shiftModelInitialState = shiftModelAdapter.getInitialState();
export const shiftModelSelectors = shiftModelAdapter.getSelectors();

const timeIntervalAdapter = createUuidAlphanumericSortEntityAdapter();
const timeIntervalInitialState = timeIntervalAdapter.getInitialState();
export const timeIntervalSelectors = timeIntervalAdapter.getSelectors();

export const shiftApi = createApi({
  reducerPath: "shiftApi",
  baseQuery: dynamicBaseQuery,
  tagTypes: [
    "ShiftGroups",
    "ShiftGroup",
    "ShiftModels",
    "ShiftModel",
    "TimeIntervals",
    "TimeInterval",
  ],
  endpoints: (builder) => ({
    getShiftUris: builder.query({
      query: () => ({
        endpoint: metaApi.endpoints.getBaseUris,
        path: "shifts.href",
      }),
      serializeQueryArgs: () => "ShiftUris",
    }),

    /**
     * Shift Groups
     */

    getShiftGroups: builder.query({
      query: () => ({
        endpoint: shiftApi.endpoints.getShiftUris,
        path: "_links.shift-groups.get.href",
        token: "access",
      }),
      providesTags: ["ShiftGroups"],
      transformResponse: (baseQueryReturnValue) =>
        shiftGroupAdapter.setAll(
          shiftGroupInitialState,
          baseQueryReturnValue.shift_groups,
        ),
    }),
    getShiftGroup: builder.query({
      query: ({ uuid }) => ({
        endpoint: shiftApi.endpoints.getShiftUris,
        path: "_links.shift-groups.get.href",
        urlSuffix: uuid,
        token: "access",
      }),
      providesTags: (result) =>
        result
          ? [{ type: "ShiftGroup", id: shiftGroupAdapter.selectId(result) }]
          : ["ShiftGroup"],
    }),
    addShiftGroup: builder.mutation({
      query: ({ body }) => ({
        endpoint: shiftApi.endpoints.getShiftUris,
        path: "_links.shift-groups.add.href",
        token: "access",
        body: body,
        method: "POST",
      }),
      invalidatesTags: ["ShiftGroups"],
    }),
    deleteShiftGroup: builder.mutation({
      query: ({ shiftGroup }) => ({
        url: shiftGroup._links.self.href,
        token: "access",
        method: "DELETE",
      }),
      invalidatesTags: ["ShiftGroups"],
    }),
    editShiftGroup: builder.mutation({
      query: ({ shiftGroup, body }) => ({
        url: shiftGroup._links.self.href,
        token: "access",
        body: body,
        method: "PUT",
      }),
      invalidatesTags: (result, error, arg) => [
        "ShiftGroups",
        {
          type: "ShiftGroup",
          id: shiftGroupAdapter.selectId(arg.shiftGroup),
        },
      ],
    }),

    /**
     * Shift Models
     */

    getShiftModels: builder.query({
      query: () => ({
        endpoint: shiftApi.endpoints.getShiftUris,
        path: "_links.shift-models.get.href",
        token: "access",
      }),
      providesTags: ["ShiftModels"],
      transformResponse: (baseQueryReturnValue) =>
        shiftModelAdapter.setAll(
          shiftModelInitialState,
          baseQueryReturnValue.shift_models,
        ),
    }),
    getShiftModel: builder.query({
      query: ({ uuid }) => ({
        endpoint: shiftApi.endpoints.getShiftUris,
        path: "_links.shift-models.get.href",
        urlSuffix: uuid,
        token: "access",
      }),
      providesTags: (result) =>
        result
          ? [{ type: "ShiftModel", id: shiftModelAdapter.selectId(result) }]
          : ["ShiftModel"],
    }),
    addShiftModel: builder.mutation({
      query: ({ body }) => ({
        endpoint: shiftApi.endpoints.getShiftUris,
        path: "_links.shift-models.add.href",
        token: "access",
        body: body,
        method: "POST",
      }),
      invalidatesTags: ["ShiftModels"],
    }),
    deleteShiftModel: builder.mutation({
      query: ({ shiftModel }) => ({
        url: shiftModel._links.self.href,
        token: "access",
        method: "DELETE",
      }),
      invalidatesTags: ["ShiftModels"],
    }),
    editShiftModel: builder.mutation({
      query: ({ shiftModel, body }) => ({
        url: shiftModel._links.self.href,
        token: "access",
        body: body,
        method: "PUT",
      }),
      invalidatesTags: (result, error, arg) => [
        "ShiftModels",
        {
          type: "ShiftModel",
          id: shiftModelAdapter.selectId(arg.shiftModel),
        },
      ],
    }),

    /**
     * TimeIntervals
     */

    getTimeIntervals: builder.query({
      query: () => ({
        endpoint: shiftApi.endpoints.getShiftUris,
        path: "_links.time-intervals.get.href",
        token: "access",
      }),
      providesTags: ["TimeIntervals"],
      transformResponse: (baseQueryReturnValue) =>
        shiftModelAdapter.setAll(
          timeIntervalInitialState,
          baseQueryReturnValue.time_intervals,
        ),
    }),
    getTimeInterval: builder.query({
      query: ({ uuid }) => ({
        endpoint: shiftApi.endpoints.getShiftUris,
        path: "_links.time-intervals.get.href",
        urlSuffix: uuid,
        token: "access",
      }),
      providesTags: (result) =>
        result
          ? [{ type: "TimeInterval", id: timeIntervalAdapter.selectId(result) }]
          : ["TimeInterval"],
    }),
    addTimeInterval: builder.mutation({
      query: ({ body }) => ({
        endpoint: shiftApi.endpoints.getShiftUris,
        path: "_links.time-intervals.add.href",
        token: "access",
        body: body,
        method: "POST",
      }),
      invalidatesTags: ["TimeIntervals"],
    }),
    deleteTimeInterval: builder.mutation({
      query: ({ timeInterval }) => ({
        url: timeInterval._links.self.href,
        token: "access",
        method: "DELETE",
      }),
      invalidatesTags: ["TimeIntervals"],
    }),
    editTimeInterval: builder.mutation({
      query: ({ timeInterval, body }) => ({
        url: timeInterval._links.self.href,
        token: "access",
        body: body,
        method: "PUT",
      }),
      invalidatesTags: (result, error, arg) => [
        "TimeIntervals",
        {
          type: "TimeInterval",
          id: timeIntervalAdapter.selectId(arg.timeInterval),
        },
      ],
    }),
  }),
});

export const selectTimeIntervalIdsSortedByStart = createSelector(
  [timeIntervalSelectors.selectEntities],
  (entities) =>
    Object.values(entities)
      .sort((a, b) =>
        a.start.localeCompare(b.start, i18n.resolvedLanguage, {
          numeric: true,
        }),
      )
      .map(timeIntervalAdapter.selectId),
);

export const {
  useGetShiftUrisQuery,
  useGetShiftGroupsQuery,
  useGetShiftGroupQuery,
  useAddShiftGroupMutation,
  useDeleteShiftGroupMutation,
  useEditShiftGroupMutation,

  useGetShiftModelsQuery,
  useGetShiftModelQuery,
  useAddShiftModelMutation,
  useDeleteShiftModelMutation,
  useEditShiftModelMutation,

  useGetTimeIntervalsQuery,
  useGetTimeIntervalQuery,
  useAddTimeIntervalMutation,
  useDeleteTimeIntervalMutation,
  useEditTimeIntervalMutation,
} = shiftApi;
