import { CustomOperation, FilterBuilder } from "devextreme-react/data-grid";
import React from "react";
import FilterBuilderAssetSelect from "./FilterBuilderAssetSelect";
import i18n from "i18next";

const getFilter = (filterValue, field) => {
  const filter = [];
  filterValue.forEach((value, index) => {
    if (index > 0) filter.push("or");
    filter.push([field.dataField, "=", value]);
  });
  return filter;
};

// The DataGrid only accepts DevExtreme components, so we can't wrap it in a component but must use a classic
// function instead.
export default function getFilterBuilder() {
  return (
    <FilterBuilder>
      <CustomOperation
        name={"like"}
        caption={"Like"}
        dataTypes={["string", "assets"]}
        icon={"percent"}
        hasValue
        calculateFilterExpression={(filterValue, field) => [
          field.dataField,
          "like",
          filterValue,
        ]}
      />
      <CustomOperation
        name={"oneOf"}
        caption={i18n.t("is-any-of", { ns: "basics" })}
        icon={"selectall"}
        dataTypes={["assets"]}
        calculateFilterExpression={getFilter}
        editorComponent={FilterBuilderAssetSelect}
      />
      <CustomOperation
        name={"noneOf"}
        caption={i18n.t("is-none-of", { ns: "basics" })}
        icon={"unselectall"}
        dataTypes={["assets"]}
        calculateFilterExpression={(filterValue, field) => {
          const filter = getFilter(filterValue, field);
          return ["!", filter];
        }}
        editorComponent={FilterBuilderAssetSelect}
      />
    </FilterBuilder>
  );
}
