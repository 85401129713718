import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/css";

function DevextremeIcon(props) {
  return (
    <i
      className={
        "dx-icon-" +
        props.icon +
        " " +
        css`
          font-size: 20px !important;
          padding-left: 2px;
        `
      }
      id={props.id}
    />
  );
}

export default DevextremeIcon;

DevextremeIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  id: PropTypes.string,
};
