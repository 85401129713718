import i18n from "i18next";
import { format } from "date-fns";

const dateFormatter = (date) => {
  return format(
    date,
    i18n.t("date-format.pickerDateFormatString", { ns: "common" }),
  );
};

export default dateFormatter;
