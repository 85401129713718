import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { css } from "@emotion/css";
import { useTranslation } from "react-i18next";

function LayoutPopoverSpeedDial(props) {
  const { t } = useTranslation("common");
  const fab = css`
    background-color: transparent !important;
    color: ${props.active ? "fff" : "rgb(73, 80, 87)"} !important;
    box-shadow: none !important;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    z-index: 1004;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  `;

  const actions = css`
    position: absolute;
    padding-bottom: 73px !important;

    & button {
      &:hover {
        background-color: var(--bs-primary);
        color: white;
      }
    }
  `;

  const [open, setOpen] = useState(false);

  return (
    <SpeedDial
      ariaLabel={"Layout Speed Dial"}
      open={open}
      className={css`
        position: absolute;
        transform: translateY(-105%);
        right: 16px;
      `}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      icon={<SpeedDialIcon openIcon={<CloseIcon />} icon={<SettingsIcon />} />}
      FabProps={{ size: "small", className: fab }}
      classes={{ actions: actions }}
    >
      <SpeedDialAction
        icon={<DeleteIcon />}
        title={t("delete")}
        onClick={props.onDelete}
      />
      <SpeedDialAction
        icon={<EditIcon />}
        title={t("rename")}
        onClick={props.onChangeLayoutName}
      />
      {props.showSave && (
        <SpeedDialAction
          icon={<SaveIcon />}
          title={t("save")}
          onClick={props.onChangeLayoutData}
        />
      )}
    </SpeedDial>
  );
}

LayoutPopoverSpeedDial.propTypes = {
  onDelete: PropTypes.func,
  showSave: PropTypes.bool,
  onChangeLayoutName: PropTypes.func,
  onChangeLayoutData: PropTypes.func,
  active: PropTypes.bool,
};

export default LayoutPopoverSpeedDial;
