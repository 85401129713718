import i18n from "i18next";

export default function formatNumber(value, useGrouping = true) {
  return (
    value?.toLocaleString(i18n.language, {
      maximumSignificantDigits: window.maximumSignificantDigits,
      roundingMode: "trunc",
      useGrouping: useGrouping,
    }) || ""
  );
}
