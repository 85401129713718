import store from "config/store";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import DataGridDataColumnDefinitionModel from "public_basics/models/DataGridDataColumnDefinitionModel";
import DataTypes from "public_basics/constants/DataTypes";
import LinksModel from "public_basics/models/LinksModel";
import AssetHeaderCell from "../components/EntryMaskEntryList/AssetHeaderCell";
import { css } from "@emotion/css";
import _ from "lodash";
import useSelectedEntryMaskConfig from "./useSelectedEntryMaskConfig";
import { uuidRegex } from "basics/utils/utils";
import useResolveAssetName from "basics/customHooks/useResolveAssetName";
import { sub } from "date-fns";
import { entryMaskSocketConnector } from "entrymask/services/entryMaskApi";

// TODO: Remove replace existing models with plain objects
//  Issue: https://gitlab.imes-solutions.com/plant-historian/web/client/-/issues/1018
export default function useEntryMaskDataGrid(
  manualEntryMaskId,
  liveUpdate = false,
) {
  const ref = useRef(null);
  const { t } = useTranslation(["common", "entry-mask"]);

  const {
    currentData: selectedEntryMaskConfig,
    selectedEntryMaskId,
    selectedEntryMaskUUID,
  } = useSelectedEntryMaskConfig(manualEntryMaskId);
  const resolveAssetName = useResolveAssetName();
  const refreshData = useCallback(() => {
    if (ref.current?._instance) ref.current._instance.refresh();
  }, []);

  useEffect(() => {
    if (liveUpdate) {
      const listener = () => {
        refreshData();
      };

      entryMaskSocketConnector.getSocket().then((socket) => {
        const state = store.getState();
        const token = state.auth.access_token;
        socket.emit("join_room", selectedEntryMaskUUID, token);
        void entryMaskSocketConnector.on("changed", listener);
      });

      return () => {
        entryMaskSocketConnector.socket?.emit(
          "leave_room",
          selectedEntryMaskUUID,
        );
        entryMaskSocketConnector.off("changed", listener);
      };
    }
  }, [refreshData, selectedEntryMaskUUID, liveUpdate]);

  const fields = useMemo(
    () => _.cloneDeep(selectedEntryMaskConfig?.fields),
    [selectedEntryMaskConfig?.fields],
  );

  const measurementDateCaption =
    selectedEntryMaskConfig?.measurement_date_caption ||
    t("measurement-date", { ns: "common" });
  const assetAssignment = selectedEntryMaskConfig?.asset_assignment;
  const fixedColumnDefinitions = useMemo(() => {
    const cols = [];

    cols.push(
      new DataGridDataColumnDefinitionModel(
        -1,
        "fixed01",
        measurementDateCaption,
        DataTypes.type.DateTime,
      ),
    );
    if (assetAssignment) {
      const assetCol = new DataGridDataColumnDefinitionModel(
        -6,
        "assets",
        t("assignment", { ns: "common" }),
        DataTypes.type.Assets,
      );
      assetCol.width = 300;
      assetCol.allowSorting = false;
      assetCol.allowHeaderFiltering = false;
      assetCol.allowGrouping = false;
      assetCol.headerCellComponent = AssetHeaderCell;
      assetCol.customizeText = (props) => {
        if (props.valueText.match(uuidRegex))
          return resolveAssetName(props.value);
        return props.value;
      };
      assetCol.additionalClassName = css`
        .dx-datagrid-text-content {
          width: 100%;
          display: flex !important;
          justify-content: space-between;
        }
      `;
      cols.push(assetCol);
    }
    cols.push(
      new DataGridDataColumnDefinitionModel(
        -5,
        "fixed05",
        t("col-caption.insert-date", { ns: "entry-mask" }),
        DataTypes.type.DateTime,
      ),
    );
    cols.push(
      new DataGridDataColumnDefinitionModel(
        -2,
        "fixed02",
        t("col-caption.update-date", { ns: "entry-mask" }),
        DataTypes.type.DateTime,
      ),
    );
    cols.push(
      new DataGridDataColumnDefinitionModel(
        -3,
        "fixed03",
        t("col-caption.insert-user", { ns: "entry-mask" }),
        DataTypes.type.Text,
      ),
    );
    cols.push(
      new DataGridDataColumnDefinitionModel(
        -4,
        "fixed04",
        t("col-caption.update-user", { ns: "entry-mask" }),
        DataTypes.type.Text,
      ),
    );

    return cols;
  }, [t, measurementDateCaption, assetAssignment, resolveAssetName]);

  const dynamicDataColumnDefinitions = useMemo(() => {
    const sortedFields = fields?.sort((a, b) =>
      a.order_number > b.order_number
        ? 1
        : a.order_number < b.order_number
          ? -1
          : 0,
    );
    return sortedFields?.map((dynColumn) => {
      return new DataGridDataColumnDefinitionModel(
        dynColumn.id,
        dynColumn.id.toString(),
        dynColumn.name,
        dynColumn.data_type,
        dynColumn.fixed_value_list,
      );
    });
  }, [fields]);

  const [initialStartDate, initialEndDate] = useMemo(() => {
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);
    endDate.setMilliseconds(0);
    endDate.setSeconds(0);
    const duration = {};
    duration[selectedEntryMaskConfig?.default_time_unit?.toLowerCase()] =
      selectedEntryMaskConfig?.default_time_span;
    return [sub(endDate, duration), endDate];
  }, [
    selectedEntryMaskConfig?.default_time_unit,
    selectedEntryMaskConfig?.default_time_span,
  ]);

  const dataColumnDefinitions = useMemo(
    () => fixedColumnDefinitions.concat(dynamicDataColumnDefinitions),
    [fixedColumnDefinitions, dynamicDataColumnDefinitions],
  );

  const links = useMemo(
    () =>
      new LinksModel(
        selectedEntryMaskConfig?._links.entries.groups.href,
        selectedEntryMaskConfig?._links.entries.get.href,
        selectedEntryMaskConfig?._links.entries.filter_values.href,
      ),
    [selectedEntryMaskConfig?._links],
  );

  return useMemo(() => {
    return {
      ref,
      dataColumnDefinitions,
      links,
      refreshData,
      selectedEntryMaskId,
      selectedEntryMaskUUID,
      initialStartDate,
      initialEndDate,
      selectedEntryMaskConfig,
    };
  }, [
    ref,
    dataColumnDefinitions,
    links,
    refreshData,
    selectedEntryMaskId,
    selectedEntryMaskUUID,
    selectedEntryMaskConfig,
    initialStartDate,
    initialEndDate,
  ]);
}
