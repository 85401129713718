import { createSlice } from "@reduxjs/toolkit";
import { isValid } from "date-fns";

const initialDateFrom = new Date();
initialDateFrom.setDate(initialDateFrom.getDate() - 1);

const initialState = {
  dateFrom: initialDateFrom,
  dateTo: new Date(),
  liveUpdateEnabled: true,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDateFrom: (state, action) => {
      state.dateFrom = action.payload;
    },
    setDateTo: (state, action) => {
      state.dateTo = action.payload;
    },
    setLiveUpdateEnabled: (state, action) => {
      state.liveUpdateEnabled = action.payload;
      if (state.liveUpdateEnabled) {
        state.dateTo = new Date();
      }
    },
  },
  selectors: {
    selectDateFrom: (state) => state.dateFrom,
    selectDateTo: (state) => state.dateTo,
    selectLiveUpdateEnabled: (state) => state.liveUpdateEnabled,
    selectDashboardFilter: (state) =>
      !state.liveUpdateEnabled
        ? isValid(state.dateTo) &&
          isValid(state.dateFrom) &&
          JSON.stringify([
            ["-2", "<=", state.dateTo.toISOString()],
            "and",
            ["-2", ">=", state.dateFrom.toISOString()],
          ])
        : isValid(state.dateFrom) &&
          JSON.stringify([["-2", ">=", state.dateFrom.toISOString()]]),
  },
});

export const { setDateFrom, setDateTo, setLiveUpdateEnabled } =
  dashboardSlice.actions;
export const {
  selectDateFrom,
  selectDateTo,
  selectLiveUpdateEnabled,
  selectDashboardFilter,
} = dashboardSlice.selectors;
export default dashboardSlice;
