import LoadOptionsModel from "public_basics/models/LoadOptionsModel";
import SelectorFilterBuilder from "public_basics/models/SelectorFilterBuilder";

export default class BookEntryListLoadOptionsModel extends LoadOptionsModel {
  isLoadingAll;
  searchTerm;
  searchComments;
  dateFrom;
  dateTo;
  fieldsDefinition;
  timeSpanMode;

  initLoadOptions(loadOptions) {
    super.initLoadOptions(loadOptions);
    this.isLoadingAll = loadOptions.isLoadingAll;

    // Filter [ ShowAlways or DateRange and DatagridFilter ]
    const selectorFilterBuilder = new SelectorFilterBuilder();

    selectorFilterBuilder.dateFrom = this.dateFrom;
    selectorFilterBuilder.dateTo = this.dateTo;
    selectorFilterBuilder.setFilter(loadOptions.filter);
    if (this.fieldsDefinition)
      selectorFilterBuilder.showAlwaysFields = this.__getShowAlwaysFields(
        this.fieldsDefinition,
      );
    selectorFilterBuilder.createFilter();
    this.filter = selectorFilterBuilder.getFilter();
  }

  __getShowAlwaysFields(fieldsDefinition) {
    const showAlwaysFields = fieldsDefinition.map(
      this.__getFixedValueItemsWithShowAlways,
    );
    return showAlwaysFields.filter((value) => {
      return value;
    });
  }

  __getFixedValueItemsWithShowAlways = (fieldDefinition) => {
    if (fieldDefinition.fixedValueList) {
      const fixedValueItemsWithShowAlways =
        fieldDefinition.fixedValueList.filter(this.__isShowAlways);
      if (fixedValueItemsWithShowAlways.length !== 0) {
        return fixedValueItemsWithShowAlways;
      }
    }
    return null;
  };

  __isShowAlways(fixedValue) {
    return fixedValue.showAlways;
  }

  setTextSearchModel(searchTerm, searchComments) {
    if (searchTerm && searchTerm !== "") {
      this.searchTerm = searchTerm;
      this.searchComments = searchComments;
    } else {
      this.searchTerm = undefined;
      this.searchComments = undefined;
    }
  }

  getLoadOptions() {
    const loadOptions = super.getLoadOptions();
    loadOptions.is_loading_all = this.isLoadingAll;
    loadOptions.search_term = this.searchTerm;
    loadOptions.search_comments = this.searchComments;
    return loadOptions;
  }
}
