import { lazy } from "react";

const LoginPage = lazy(() => import("basics/components/LoginPage/LoginPage"));
const ChangePasswordPage = lazy(
  () => import("basics/components/ChangePassword/ChangePasswordPage"),
);
const SSOAuthenticationPage = lazy(
  () => import("basics/components/LoginPage/SSOAuthenticationPage"),
);

export const BASICS = "BASICS";
export const route = { path: "/login", element: <LoginPage /> };
export const changePasswordRoute = {
  path: "/change-password",
  element: <ChangePasswordPage />,
};
export const ssoAuthenticationRoute = {
  path: "/login/authenticate",
  element: <SSOAuthenticationPage />,
};
