import RowModel from "./RowModel";

export default class EntryItemsModel {
  data;
  totalCount;
  offset;

  constructor(response, offset) {
    this.offset = offset ? offset : 0;
    this.totalCount = this.__getTotalCount(response);
    this.data = this.__getData(response, offset);
  }

  __getTotalCount(response) {
    if (!response) return 0;
    return response.data.total_row_count;
  }

  __getData(response) {
    if (!response) return [];
    return response.data.data.map((entry, index) => {
      const row = this.__createRow(entry);
      row.rowNumber = index + 1 + this.offset;
      return row;
    });
  }

  __createRow(entry) {
    return new RowModel(entry.id);
  }

  getItems() {
    return {
      data: this.data,
      totalCount: this.totalCount,
    };
  }

  loadAdditionalData() {}

  setAdditionalData() {}
}
