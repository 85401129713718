import { OverlayTrigger, Popover } from "react-bootstrap";
import React from "react";
import styles from "./CustomPopover.module.scss";
import { PopupWindowBlocker } from "../PopupWindowBlocker/PopupWindowBlocker";
import PropTypes from "prop-types";
import clsx from "clsx";

export default function CustomPopover(props) {
  // We are using an OverlayTrigger to be able to specify children as ref.
  // The OverlayTrigger itself is not doing anything.
  // So setting it to "hover" mode won't work.
  return (
    <>
      <OverlayTrigger
        show={props.show}
        popperConfig={{
          modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
        }}
        placement="bottom"
        overlay={
          <Popover
            id="CustomPopover"
            className={clsx(styles.customPopover, props.className)}
          >
            {props.popoverContent}
          </Popover>
        }
      >
        {props.children}
      </OverlayTrigger>
      {props.show && <PopupWindowBlocker onClick={props.onHide} />}
    </>
  );
}

CustomPopover.propTyps = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      content: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
      ]).isRequired,
    }),
  ),
  onHide: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onSelect: PropTypes.func,
  defaultActiveKey: PropTypes.string,
  show: PropTypes.bool,
  className: PropTypes.string,
  popoverContent: PropTypes.element,
};

CustomPopover.defaultProps = {
  items: [],
};
