import { Form } from "react-bootstrap";
import React from "react";
import TreeListExpandButton from "./TreeListExpandButton";
import styled from "@emotion/styled";
import SelectStates from "../../constants/SelectStates";
import PropTypes from "prop-types";

const AlignedDiv = styled.div`
  padding-left: calc(12px + ${(props) => props.depth}em) !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  &:hover {
    background-color: var(--bs-primary-hover);
  }
`;
export default function TreeListOption(props) {
  if (
    props.data.parent &&
    !props.expandedItems.includes(props.data.parent) &&
    !props.forceExpanded
  )
    return null;
  return (
    <AlignedDiv className={props.className} depth={props.data.depth}>
      <TreeListExpandButton
        hasChildren={props.data.hasChildren}
        open={
          props.expandedItems.includes(props.data.value) || props.forceExpanded
        }
        onToggleExpanded={props.onToggleExpanded}
      />
      <Form.Check
        label={props.data.label}
        data-cy={"AssetCheckbox-" + props.data.label}
        ref={(ref) =>
          ref &&
          !props.disableIndeterminate &&
          (ref.indeterminate = props.data.state === SelectStates.indeterminate)
        }
        onChange={
          props.data.state !== SelectStates.indeterminate ||
          props.disableIndeterminate
            ? props.onClick
            : (event) => (event.target.indeterminate = true)
        }
        onClick={(event) => event.stopPropagation()}
        checked={props.data.state === SelectStates.selected}
        disabled={props.disabled}
        inline
      />
    </AlignedDiv>
  );
}

TreeListOption.propTypes = {
  className: PropTypes.string,
  expandedItems: PropTypes.arrayOf(PropTypes.string),
  onToggleExpanded: PropTypes.func,
  disabled: PropTypes.bool,
  disableIndeterminate: PropTypes.bool,
  data: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    depth: PropTypes.number,
    parent: PropTypes.string,
    state: PropTypes.string,
  }),
  onClick: PropTypes.func,
  forceExpanded: PropTypes.bool,
};
