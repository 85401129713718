import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";
import { css } from "@emotion/css";

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  text-transform: none;
  color: var(--bs-${(props) => props.variant || "primary"});
  border-radius: 50%;
  transition: background-color 0.15s ease-in-out;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  display: flex;

  ${(props) =>
    props.disabled
      ? "opacity: 0.5;"
      : {
          "&:hover": {
            backgroundColor: `var(--bs-${props.variant || "primary"}-hover)`,
          },
        }}
`;

const IconButton = ({ isLoading, ...props }) => {
  if (isLoading)
    return (
      <StyledButton {...props} disabled>
        <Spinner
          variant={props.variant}
          className={css`
            position: absolute;
          `}
        />
        {props.children}
      </StyledButton>
    );

  return <StyledButton {...props} />;
};

IconButton.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["light", "primary", "danger"]),
};

export default IconButton;
