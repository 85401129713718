import { lazy } from "react";

const PolicyManagement = lazy(() => import("./PolicyManagement"));

const policyManagementPath = "policy-management";

const policyManagementRoute = {
  path: policyManagementPath,
  element: <PolicyManagement />,
};

export { policyManagementPath, policyManagementRoute };
