import React from "react";
import { useSelector } from "react-redux";
import ModalButtons from "./components/ModalButtons";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { css } from "@emotion/css";
import ModalButtonModel from "public_basics/models/ModalButtonModel";
import { useSnackbar } from "notistack";
import { selectIsDialogFullscreen } from "basics/slices/userSettingsSlice";
import CustomModalHeader from "./components/CustomModalHeader";

export default function CustomModal({
  customButtons,
  enableFullscreen,
  className,
  bodyClassName,
  onClose,
  open,
  addSettings,
  additionalSettings,
  title,
  titleIcon,
  children,
  modalButtons,
  onSave,
  isValid,
  saveButtonText,
  closeButtonText,
  closeButtonDataCy,
  saveButtonDataCy,
  saveOptions,
  disabled,
  ...rest
}) {
  const { closeSnackbar } = useSnackbar();
  const isFullscreen =
    useSelector(selectIsDialogFullscreen) && enableFullscreen;

  const extendedClassName =
    css`
      padding-left: 0 !important;
    ` + (className ? " " + className : "");
  const extendedBodyClassName =
    css`
      word-wrap: anywhere;
    ` + (bodyClassName ? " " + bodyClassName : "");

  const extendedOnClose = () => {
    closeSnackbar();
    onClose();
  };

  return (
    <Modal
      show={open}
      onHide={extendedOnClose}
      animation={false}
      centered
      fullscreen={!!isFullscreen}
      className={extendedClassName}
      dialogClassName="modal-fullscreen-sm-down"
      {...rest}
    >
      <CustomModalHeader
        onClose={extendedOnClose}
        enableFullscreen={enableFullscreen}
        isFullscreen={isFullscreen}
        addSettings={addSettings}
        additionalSettings={additionalSettings}
        title={title}
        titleIcon={titleIcon}
      />
      <Modal.Body className={extendedBodyClassName}>{children}</Modal.Body>
      {modalButtons ? (
        modalButtons
      ) : (
        <ModalButtons
          onSave={
            onSave
              ? () => {
                  closeSnackbar();
                  onSave();
                }
              : null
          }
          onClose={extendedOnClose}
          isValid={isValid}
          customButtons={customButtons}
          saveButtonText={saveButtonText}
          closeButtonText={closeButtonText}
          closeButtonDataCy={closeButtonDataCy}
          saveButtonDataCy={saveButtonDataCy}
          saveOptions={saveOptions}
          disabled={disabled}
        />
      )}
    </Modal>
  );
}

CustomModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  enableFullscreen: PropTypes.bool,
  onSave: PropTypes.func,
  isValid: PropTypes.bool,
  saveButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  closeButtonDataCy: PropTypes.string,
  saveButtonDataCy: PropTypes.string,
  customButtons: PropTypes.arrayOf(PropTypes.element),
  className: PropTypes.string,
  backdrop: PropTypes.oneOf(["static", false, true]),
  size: PropTypes.oneOf(["sm", "lg", "xl"]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onKeyDown: PropTypes.func,
  modalButtons: PropTypes.element,
  saveOptions: PropTypes.arrayOf(PropTypes.instanceOf(ModalButtonModel)),
  bodyClassName: PropTypes.string,
  disabled: PropTypes.bool,
  addSettings: PropTypes.bool,
};
