import { createSelector } from "@reduxjs/toolkit";
import { CustomizedSelect } from "public_basics/components";
import { reformatUtcDateString } from "basics/utils/dateTimeUtils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { tasksSelectors, useGetTaskInfosQuery } from "../services/tasksApi";

export default function TaskSelect(props) {
  const { value, onChange, disabled, validation, isClearable, uuidsToFilter } =
    props;

  const selectFilteredTaskInfos = useMemo(() => {
    return createSelector(
      [
        (res) => tasksSelectors.selectAll(res),
        (res, uuidsToFilter) => uuidsToFilter,
      ],
      (data, uuidsToFilter) =>
        data.filter((task) => !uuidsToFilter.includes(task.guid)),
    );
  }, []);

  const { options } = useGetTaskInfosQuery(undefined, {
    selectFromResult: (res) => ({
      ...res,
      options: res.data && selectFilteredTaskInfos(res.data, uuidsToFilter),
    }),
  });
  const { t } = useTranslation(["tasks", "common"]);

  return (
    <CustomizedSelect
      options={options}
      value={value}
      onChange={onChange}
      disabled={disabled}
      placeholder={t("select-a-task")}
      validation={validation}
      isClearable={isClearable}
      getOptionValue={(task) => task.guid}
      getOptionLabel={(task) =>
        task?.title &&
        task.title +
          ", " +
          reformatUtcDateString(
            task.insert_date,
            t("date-format.pickerTimeFormatString", { ns: "common" }),
          )
      }
    />
  );
}

TaskSelect.defaultProps = {
  uuidsToFilter: [],
};

TaskSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  validation: PropTypes.oneOfType([
    PropTypes.shape({ isValid: PropTypes.bool }),
    PropTypes.shape({ isInvalid: PropTypes.bool }),
  ]),
  isClearable: PropTypes.bool,
  uuidsToFilter: PropTypes.arrayOf(PropTypes.string),
};
