import { Form } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import parseNumber from "public_basics/parseNumber";
import i18n from "i18next";
import formatNumberInput from "./formatNumberInput";
import formatNumber from "public_basics/formatNumber";

export default function NumberInput(props) {
  const cursorPosition = useRef(0);
  const [value, setValue] = useState(
    formatNumberInput(props.value, props.useGrouping, props.value) || "",
  );

  useEffect(() => {
    if (props.disabled)
      setValue(
        formatNumberInput(props.value, props.useGrouping, props.value) || "",
      );
  }, [props.value, props.disabled, props.useGrouping]);

  const onChange = (event) => {
    const parsedInput = parseNumber(event.target.value);
    const formattedInput = formatNumberInput(
      event.target.value,
      props.useGrouping,
      parsedInput,
    );
    /* We should not go bigger than the max safe integer. Otherwise, all numbers will be the same.
           Numbers longer than 16 chars will also be rounded.
           16 digits is the max digit count.
        */
    if (!formattedInput) {
      props.onChange(null);
      setValue("");
    } else if (formattedInput === "+" || formattedInput === "-") {
      setValue(formattedInput);
      cursorPosition.current = 1;
    } else if (
      parsedInput < Number.MAX_SAFE_INTEGER &&
      parsedInput.toString().length <= 16
    ) {
      // figure out where to place the cursor when a delimiter was added
      const { format } = new Intl.NumberFormat(i18n.language);
      const [, groupSign] = /^1(.)000$/.exec(format(1000));
      const hasInputChanged =
        formattedInput.length !== event.target.value.length;
      const hasCursorPositionChanged =
        event.target.selectionStart !== cursorPosition.current;
      const isDeleteKey =
        event.nativeEvent.inputType === "deleteContentBackward";

      if (
        !hasInputChanged ||
        (props.value[event.target.selectionStart] === groupSign &&
          hasCursorPositionChanged) ||
        isDeleteKey
      ) {
        cursorPosition.current = event.target.selectionStart;
      } else {
        cursorPosition.current =
          event.target.selectionStart +
          (formattedInput.length - event.target.value.length);
      }
      props.onChange(parsedInput);
      setValue(formattedInput);
    }
  };

  const { useGrouping, ...forwardedProps } = props;

  return (
    <Form.Control
      {...forwardedProps}
      ref={(ref) =>
        ref &&
        ref.setSelectionRange(cursorPosition.current, cursorPosition.current)
      }
      type="text"
      onBlur={() => setValue(formatNumber(props.value))}
      onChange={onChange}
      value={value}
    />
  );
}
