import store from "config/store";
import _ from "lodash";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import SnackbarAlertColour from "public_basics/constants/SnackbarAlertColour";
import { io } from "socket.io-client";
import i18n from "i18next";
import { v4 } from "uuid";

const initSocket = async (
  endpoint,
  namespace,
  { errorMessage, successMessage } = {},
) => {
  const promise = store.dispatch(endpoint.initiate());
  const { data } = await promise;
  promise.unsubscribe();

  const { pathname, origin } = new URL(data._links.socket.href);
  const state = store.getState();
  const token = state.auth.access_token;

  const socket = io(origin + namespace, { path: pathname, auth: token });

  const errorKey = v4();
  const successKey = v4();

  const connectListener = () => {
    if (successMessage) {
      enqueueSnackbar({
        persist: false,
        variant: SnackbarAlertColour.SUCCESS,
        message: i18n.t(successMessage.message, { ns: successMessage.ns }),
        key: successKey,
      });
      closeSnackbar(errorKey);
      socket.once("connect_error", connectErrorListener);
    }
  };
  const connectErrorListener = () => {
    if (errorMessage) {
      enqueueSnackbar({
        persist: true,
        variant: SnackbarAlertColour.WARNING,
        message: i18n.t(errorMessage.message, { ns: successMessage.ns }),
        key: errorKey,
      });
      closeSnackbar(successMessage);
      socket.once("connect", connectListener);
    }
  };

  socket.once("connect_error", connectErrorListener);

  return socket;
};

const eventsToIgnore = ["$connect", "$connect_error"];

export function createSocketConnector(...args) {
  return {
    async getSocket() {
      if (!this.socket) {
        if (!this._initializing) {
          this._initializing = initSocket(this.endpoint, ...args);
        }

        this.socket = await this._initializing;
        this._initializing = null;
      }
      return this.socket;
    },
    async on(event, listener) {
      (await this.getSocket()).on(event, listener);
    },
    off(event, listener) {
      if (!this.socket) return;

      this.socket.off(event, listener);

      const activeListeners = _.difference(
        Object.keys(this.socket._callbacks),
        eventsToIgnore,
      );

      if (_.isEmpty(activeListeners)) {
        this.socket.disconnect();
        this.socket = null;
      }
    },
    _initializing: null,
    socket: null,
    endpoint: null,
  };
}
