import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reportsCurrentlyLoading: [],
  latestLoadOptions: {},
  reportModalEntry: null,
};

// Created to manually handle loading state of potentially multiple
// "concurrent" mutations since a single Hook does not keep track of
// individual requests
const entryMaskReportSlice = createSlice({
  name: "entryMaskReports",
  initialState: initialState,
  reducers: {
    addLoadingReport(state, action) {
      if (
        !state.reportsCurrentlyLoading.find(
          (report) =>
            report.report.name === action.payload.report.name &&
            report.type === action.payload.type &&
            report.bookId === action.payload.bookId,
        )
      )
        state.reportsCurrentlyLoading.push(action.payload);
    },
    removeLoadingReport(state, action) {
      state.reportsCurrentlyLoading = state.reportsCurrentlyLoading.filter(
        (report) =>
          report.report.name !== action.payload.report.name ||
          report.type !== action.payload.type ||
          report.bookId !== action.payload.bookId,
      );
    },
    setLatestLoadOptions(state, action) {
      state.latestLoadOptions = action.payload;
    },
    setReportModalEntry(state, action) {
      state.reportModalEntry = action.payload;
    },
  },
  selectors: {
    selectReportsCurrentlyLoading: (state) => state.reportsCurrentlyLoading,
    selectLatestLoadOptions: (state) => state.latestLoadOptions,
    selectReportModalEntry: (state) => state.reportModalEntry,
  },
});

export const {
  addLoadingReport,
  removeLoadingReport,
  setLatestLoadOptions,
  setReportModalEntry,
} = entryMaskReportSlice.actions;
export const {
  selectReportsCurrentlyLoading,
  selectLatestLoadOptions,
  selectReportModalEntry,
} = entryMaskReportSlice.selectors;
export default entryMaskReportSlice;
