import { setDefaultOptions } from "date-fns";
import { loadMessages, locale } from "devextreme/localization";
import i18n from "i18next";

const getDxLocale = (language) => {
  switch (language) {
    case "de":
    case "de-DE":
      return import("devextreme/localization/messages/de.json");
    case "en":
    case "en-GB":
    case "en-US":
    default:
      return import("devextreme/localization/messages/en.json");
  }
};

const getDateLocale = (language) => {
  switch (language) {
    case "de":
    case "de-DE":
      return import("date-fns/locale/de");
    case "en":
    case "en-GB":
    case "en-US":
    default:
      return import("date-fns/locale/en-US");
  }
};

const setAppLanguage = (language) => {
  locale(language);
  getDxLocale(language).then((dx) => {
    loadMessages(dx);
  });
  getDateLocale(language).then((dateFns) => {
    setDefaultOptions({ locale: dateFns.default });
    // calling this after changing the date-fns locale allows LocalizationProvider to update itself
    // and use the new locale
    i18n.changeLanguage(language);
  });
};

export default setAppLanguage;
