import SkeletonEntryMaskList from "entrymask/components/EntryMaskList/components/SkeletonEntryMaskList";
import SkeletonEntryMaskEntryList from "entrymask/components/SkeletonEntryMaskEntryList";
import React from "react";
import Skeleton from "react-loading-skeleton";

function EntryMaskSkeleton() {
  return (
    <div className="d-flex overflow-hidden">
      <div className="d-flex flex-column flex-grow-1">
        <div className="mt-3 mb-2">
          <Skeleton height={40} />
        </div>
        <div className="list-group overflow-auto">
          <SkeletonEntryMaskList />
        </div>
      </div>
      <div className="w-75 d-none d-md-block ps-3">
        <SkeletonEntryMaskEntryList />
      </div>
    </div>
  );
}

export default EntryMaskSkeleton;
