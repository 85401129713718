export default class FilterItemsModel {
  __items;

  constructor(response) {
    this.__items = this.__createFilterItems(response);
  }

  __createFilterItems(response) {
    const items = [];
    if (response && response.data.filter_values != null) {
      response.data.filter_values.forEach((filterValue) => {
        const filterItem = { key: filterValue };
        items.push(filterItem);
      });
    }
    return items;
  }

  getItems() {
    return this.__items;
  }
}
