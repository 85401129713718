export default class LoadOptionsModel {
  limit;
  offset;
  filter;
  groupingSpecs;
  sortingSpecs;
  fieldId;

  initLoadOptions(loadOptions) {
    this.limit = loadOptions.take;
    this.offset = loadOptions.skip;
    if (loadOptions.group)
      this.setGroupingSpecs(this.__parseSpecs(loadOptions.group));
    if (loadOptions.sort)
      this.setSortingSpecs(this.__parseSpecs(loadOptions.sort));
    else this.setSortingSpecs([]);
    if (loadOptions.filter) this.filter = this.__getSpecs(loadOptions.filter);
    if (loadOptions.dataField) this.fieldId = loadOptions.dataField;
  }

  setGroupingSpecs(groupingSpecs) {
    this.groupingSpecs = this.__getSpecs(groupingSpecs);
  }

  setSortingSpecs(sortingSpecs) {
    this.sortingSpecs = this.__getSpecs(sortingSpecs);
  }

  __getSpecs(specs) {
    if (this.__isArray(specs)) {
      return JSON.stringify(specs);
    }
  }

  __isArray(array) {
    if (array && array.length !== 0) {
      return true;
    }
  }

  __parseSpecs(specs) {
    if (!specs) {
      return null;
    }
    return specs.map((spec) => {
      return {
        id: parseInt(spec.selector),
        asc: !spec.desc,
      };
    });
  }

  getLoadOptions() {
    return {
      limit: this.limit,
      offset: this.offset,
      filter: this.filter,
      grouping_specs: this.groupingSpecs,
      sorting_specs: this.sortingSpecs,
      field_id: this.fieldId,
    };
  }
}
