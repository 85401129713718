import { useEffect } from "react";
import useLocationDisplayString from "basics/customHooks/useLocationDisplayString";

function TitleUpdater() {
  const locationDisplay = useLocationDisplayString(true);

  useEffect(() => {
    document.title = locationDisplay;
  }, [locationDisplay]);
  return null;
}

export default TitleUpdater;
