import { Col, Row } from "react-bootstrap";
import CustomizedSelect from "../Selects/CustomizedSelect";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function DateFieldSelect(props) {
  const { t } = useTranslation(["common", "basics"]);

  return (
    <Row data-cy={props.dataCyPrefix + "DateFieldSelector"}>
      <Col>{t("apply-to-date", { ns: "basics" })}</Col>
      <Col>
        <CustomizedSelect
          isClearable={false}
          options={props.dateFieldOptions}
          value={props.dateField}
          onChange={props.onSetDateField}
        />
      </Col>
    </Row>
  );
}

DateFieldSelect.propTypes = {
  dateFieldOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.any, label: PropTypes.string }),
  ),
  dateField: PropTypes.any,
  onSetDateField: PropTypes.func.isRequired,
  dataCyPrefix: PropTypes.string,
};
