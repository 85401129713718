import SnackbarAlertColour from "public_basics/constants/SnackbarAlertColour";
import { transformResponseCriteria } from "basics/components/ChangePassword/passwordCriteriaFunction";
import i18n from "i18next";
import {
  ShiftGroupValidationTypes,
  ShiftModelValidationTypes,
  TimeIntervalValidationTypes,
} from "basics/constants/ShiftOperationValidationTypes";
import { getUserFromUUID } from "basics/utils/utils";
import { formatISODate } from "basics/utils/dateTimeUtils";
import _ from "lodash";

const transformShiftValidationError = (params) => {
  let validationError;
  let extraParams = {};
  switch (params.validation_type) {
    case TimeIntervalValidationTypes.NAME_VALIDATION:
    case ShiftGroupValidationTypes.NAME_VALIDATION:
      validationError = "$t(error.validation.name)";
      break;
    case ShiftGroupValidationTypes.USER_UUIDS_VALIDATION:
      validationError = "$t(error.validation.shift-group-duplicate-user)";
      extraParams.users = params.user_uuids.map(
        (uuid) => getUserFromUUID(uuid)?.username ?? uuid,
      );
      extraParams.count = extraParams.users.length;
      break;
    case ShiftModelValidationTypes.RUNTIME_VALIDATION:
      validationError = "$t(error.validation.shift-model-validity)";
      break;
    default:
      validationError = "$t(error.validation.default)";
  }
  return {
    validationError: validationError,
    ...params,
    ...extraParams,
  };
};

export const ERROR_MAP = {
  //region BaseErrors
  5: {
    message: "load-picture",
    ns: "global-exceptions",
  },
  7: {
    message: "delete-picture",
    ns: "global-exceptions",
  },
  54: {
    message: "get-dashboard-entries-error",
    ns: "basics",
  },
  36: {
    message: "not-found",
    ns: "global-exceptions",
  },
  37: {
    message: "load-groups-error",
    ns: "basics",
  },
  38: {
    message: "load-filter-values-error",
    ns: "basics",
  },
  68: {
    message: "upload-file",
    ns: "global-exceptions",
  },
  69: {
    message: "load-file",
    ns: "global-exceptions",
  },
  70: {
    message: "invalid-file-type",
    ns: "global-exceptions",
  },
  71: {
    message: "file-too-big",
    ns: "global-exceptions",
  },
  73: {
    message: "no-entries-for-report-error",
    ns: "basics",
  },
  74: {
    message: "can-not-connect-to-collector-error",
    ns: "basics",
  },
  75: {
    message: "collector-connection-closed-error",
    ns: "basics",
  },
  167: {
    message: "invalid-uuid-error",
    ns: "global-exceptions",
  },
  //endregion
  //region EntryMaskErrors
  3: {
    message: "load-book",
    ns: "entry-mask-error",
  },
  8: {
    message: "load-books",
    ns: "entry-mask-error",
  },
  10: {
    message: "add-entry",
    ns: "entry-mask-error",
  },
  12: {
    message: "edit-entry",
    ns: "entry-mask-error",
  },
  13: {
    message: "load-book",
    ns: "entry-mask-error",
  },
  14: {
    message: "base-validation",
    ns: "entry-mask-error",
    transformParams: (params) => {
      return {
        validationError: `$t(${_.kebabCase(params.validation_type)})`,
        ...params,
      };
    },
    severity: (params) => {
      if (params.validation_type === "UpdateValidation")
        return SnackbarAlertColour.WARNING;
      return SnackbarAlertColour.ERROR;
    },
  },
  18: {
    message: "save-entry",
    ns: "entry-mask-error",
  },
  19: {
    message: "empty-field",
    ns: "entry-mask-error",
  },
  20: {
    message: "max-text-modules",
    ns: "entry-mask-error",
  },
  21: {
    message: "load-text-modules",
    ns: "entry-mask-error",
  },
  22: {
    message: "create-text-module",
    ns: "entry-mask-error",
  },
  23: {
    message: "edit-text-module",
    ns: "entry-mask-error",
  },
  28: {
    message: "load-data-source",
    ns: "entry-mask-error",
  },
  50: {
    message: "get-entry-by-id",
    ns: "entry-mask-error",
  },
  51: {
    message: "load-book-groups",
    ns: "entry-mask-error",
  },
  52: {
    message: "save-entry-history",
    ns: "entry-mask-error",
  },
  53: {
    message: "get-entry-history",
    ns: "entry-mask-error",
  },
  55: {
    message: "invalid-field-for-split",
    ns: "entry-mask-error",
  },
  56: {
    message: "date-not-in-range-for-split",
    ns: "entry-mask-error",
    transformParams: (params) => {
      return {
        formattedFromDate: formatISODate(
          params.from_field_date,
          i18n.t("date-format.pickerDateTimeFormatString", { ns: "common" }),
        ),
        formattedToDate: formatISODate(
          params.to_field_date,
          i18n.t("date-format.pickerDateTimeFormatString", { ns: "common" }),
        ),
      };
    },
  },
  57: {
    message: "field-not-date-time-format-for-split",
    ns: "entry-mask-error",
  },
  76: {
    message: "update-book-configuration",
    ns: "entry-mask-error",
  },
  77: {
    message: "group-validation-error",
    ns: "entry-mask-error",
  },
  78: {
    message: "create-new-book-group",
    ns: "entry-mask-error",
  },
  79: {
    message: "get-icons",
    ns: "entry-mask-error",
  },
  80: {
    message: "delete-book-group",
    ns: "entry-mask-error",
  },
  81: {
    message: "create-icon",
    ns: "entry-mask-error",
  },
  82: {
    message: "delete-icon",
    ns: "entry-mask-error",
  },
  83: {
    message: "edit-book-group",
    ns: "entry-mask-error",
  },
  84: {
    message: "get-book-group-assignment",
    ns: "entry-mask-error",
  },
  85: {
    message: "add-book-group-assignment",
    ns: "entry-mask-error",
  },
  86: {
    message: "book-group-assignment-exists",
    ns: "entry-mask-error",
  },
  87: {
    message: "book-not-assigned-to-group",
    ns: "entry-mask-error",
  },
  88: {
    message: "remove-book-from-group",
    ns: "entry-mask-error",
  },
  89: {
    message: "edit-book-config",
    ns: "entry-mask-error",
  },
  90: {
    message: "could-not-load-book-columns",
    ns: "entry-mask-error",
  },
  91: {
    message: "icon-in-use",
    ns: "entry-mask-error",
  },
  145: {
    message: "get-entry-history",
    ns: "entry-mask-error",
  },
  160: {
    message: "add-entry-asset",
    ns: "entry-mask-error",
  },
  161: {
    message: "delete-entry-asset",
    ns: "entry-mask-error",
  },
  184: {
    message: "get-entry-info-error",
    ns: "entry-mask-error",
  },
  197: {
    message: "delete-book",
    ns: "entry-mask-error",
  },
  //endregion
  //region LayoutsErrors
  39: {
    message: "layout-already-exists",
    ns: "entry-mask-error",
  },
  40: {
    message: "layout-not-shared",
    ns: "entry-mask-error",
  },
  41: {
    message: "get-available-layouts-for-scope",
    ns: "entry-mask-error",
  },
  42: {
    message: "save-new-user-layout",
    ns: "entry-mask-error",
  },
  43: {
    message: "save-new-enterprise-layout",
    ns: "entry-mask-error",
  },
  44: {
    message: "get-layout",
    ns: "entry-mask-error",
  },
  45: {
    message: "delete-layout",
    ns: "entry-mask-error",
  },
  46: {
    message: "change-layout-name",
    ns: "entry-mask-error",
  },
  47: {
    message: "change-layout-data",
    ns: "entry-mask-error",
  },
  48: {
    message: "search-for-user-layout",
    ns: "entry-mask-error",
  },
  49: {
    message: "search-for-enterprise-layout",
    ns: "entry-mask-error",
  },
  //endregion
  //region EntryLinksErrors
  186: {
    message: "get-link-error",
    ns: "basics",
  },
  187: {
    message: "add-link-error",
    ns: "basics",
  },
  188: {
    message: "delete-link-error",
    ns: "basics",
  },
  189: {
    message: "check-if-link-exists-error",
    ns: "basics",
  },
  190: {
    message: "linked-with-itself-error",
    ns: "basics",
  },
  191: {
    message: "link-exists-error",
    ns: "basics",
  },
  //endregion
  //region AssetErrors
  92: {
    message: "validation-error",
    ns: "basics",
  },
  93: {
    message: "get-active-assets-error",
    ns: "basics",
  },
  94: {
    message: "delete-asset-error",
    ns: "basics",
  },
  96: {
    message: "add-asset-error",
    ns: "basics",
  },
  100: {
    message: "get-assets-error",
    ns: "basics",
  },
  101: {
    message: "edit-asset-type-error",
    ns: "basics",
  },
  103: {
    message: "error-restore",
    ns: "basics",
  },
  195: {
    message: "error-restore-not-deleted",
    ns: "basics",
  },
  196: {
    message: "error-enterprise-delete",
    ns: "basics",
  },
  //endregion
  //region TaskErrors
  58: {
    message: "invalid-task-state-transition-service-error",
    ns: "tasks-error",
    transformParams: (error_params) => {
      return {
        initialState: i18n.t(error_params.initial_state, { ns: "tasks" }),
        newState: i18n.t(error_params.new_state, { ns: "tasks" }),
      };
    },
  },
  59: {
    message: "load-tasks-error",
    ns: "tasks-error",
  },
  60: {
    message: "load-tasks-error",
    ns: "tasks-error",
  },
  61: {
    message: "load-task-error",
    ns: "tasks-error",
  },
  62: {
    message: "set-task-state-error",
    ns: "tasks-error",
  },
  63: {
    message: "task-base-validation-error",
    ns: "tasks-error",
    transformParams: (params) => {
      return {
        validationError: `$t(task-${_.kebabCase(
          params.validation_type,
        )}-error)`,
        ...params,
      };
    },
  },
  64: {
    message: "add-task-error",
    ns: "tasks-error",
  },
  65: {
    message: "edit-task-error",
    ns: "tasks-error",
  },
  102: {
    message: "edit-asset-assignment-error",
    ns: "tasks-error",
  },
  132: {
    message: "edit-asset-assignment-error",
    ns: "tasks-error",
  },
  105: {
    message: "add-task-comment-error",
    ns: "tasks-error",
  },
  106: {
    message: "task-comment-validation-error",
    ns: "tasks-error",
  },
  107: {
    message: "mark-task-as-read-error",
    ns: "tasks-error",
  },
  108: {
    message: "get-number-of-unread-tasks-error",
    ns: "tasks-error",
  },
  111: {
    message: "take-over-task-wrong-state-error",
    ns: "tasks-error",
  },
  112: {
    message: "take-over-task-error",
    ns: "tasks-error",
  },
  115: {
    message: "edit-user-assignment-error",
    ns: "tasks-error",
  },
  133: {
    message: "edit-user-assignment-error",
    ns: "tasks-error",
  },
  116: {
    message: "get-task-order-error",
    ns: "tasks-error",
  },
  117: {
    message: "reorder-error",
    ns: "tasks-error",
  },
  118: {
    message: "reorder-error",
    ns: "tasks-error",
  },
  119: {
    message: "reorder-error",
    ns: "tasks-error",
  },
  120: {
    message: "reorder-error",
    ns: "tasks-error",
  },
  121: {
    message: "reorder-error",
    ns: "tasks-error",
  },
  122: {
    message: "cant-reorder-aborted-or-completed-task-error",
    ns: "tasks-error",
  },
  123: {
    message: "cant-reorder-to-exact-same-position-error",
    ns: "tasks-error",
  },
  124: {
    message: "edit-aborted-or-completed-task-error",
    ns: "tasks-error",
  },
  125: {
    message: "get-tasks-info-error",
    ns: "tasks-error",
  },
  126: {
    message: "reorder-error",
    ns: "tasks-error",
  },
  127: {
    message: "recurring-task-validation-error",
    ns: "tasks-error",
    transformParams: (params) => {
      return {
        validationError: `$t(${_.kebabCase(params.validation_type)}-error)`,
        ...params,
      };
    },
  },
  128: {
    message: "load-recurring-tasks-error",
    ns: "tasks-error",
  },
  129: {
    message: "load-recurring-task-error",
    ns: "tasks-error",
  },
  130: {
    message: "add-recurring-task-error",
    ns: "tasks-error",
  },
  131: {
    message: "edit-recurring-task-error",
    ns: "tasks-error",
  },
  157: {
    message: "reorder-error",
    ns: "tasks-error",
  },
  158: {
    message: "reorder-error",
    ns: "tasks-error",
  },
  //endregion
  //region AuthenticationErrors
  30: {
    message: "invalid-login",
    ns: "basics",
  },
  31: {
    message: "insufficient-privilege",
    ns: "global-exceptions",
  },
  32: {
    message: "invalid-access-token-error",
    ns: "basics",
  },
  33: {
    message: "session-expired",
    ns: "global-exceptions",
    severity: SnackbarAlertColour.WARNING,
  },
  34: {
    message: "logout-error",
    ns: "basics",
  },
  35: {
    message: "validate-session-error",
    ns: "basics",
  },
  99: {
    message: "get-users-error",
    ns: "basics",
  },
  113: {
    message: "get-user-groups",
    ns: "basics",
  },
  114: {
    message: "get-user-group",
    ns: "basics",
  },
  139: {
    message: "password-expired",
    ns: "basics",
  },
  140: {
    message: "passwords-do-not-match",
    ns: "basics",
  },
  141: {
    message: "failed-password-criteria",
    ns: "basics",
    transformParams: transformResponseCriteria,
  },
  142: {
    message: "cannot-change-password-for-ldap",
    ns: "basics",
  },
  143: {
    message: "invalid-renew-credentials",
    ns: "basics",
  },
  144: {
    message: "new-password-must-be-different",
    ns: "basics",
  },
  162: {
    message: "add-api-token-error",
    ns: "user-profile",
  },
  163: {
    message: "get-api-token-error",
    ns: "user-profile",
  },
  164: {
    message: "get-api-tokens-error",
    ns: "user-profile",
  },
  166: {
    message: "delete-api-token-error",
    ns: "user-profile",
  },
  192: {
    message: "sso-error",
    ns: "basics",
  },
  193: {
    message: "sso-error",
    ns: "basics",
  },
  194: {
    message: "sso-error",
    ns: "basics",
  },
  //endregion
  //region Shift(Group, Model, TimeIntervals) Errors
  169: {
    message: "error.validation.base",
    ns: "shift-operation",
    transformParams: transformShiftValidationError,
  },
  170: {
    message: "error.validation.base",
    ns: "shift-operation",
    transformParams: transformShiftValidationError,
  },
  171: {
    message: "error.validation.base",
    ns: "shift-operation",
    transformParams: transformShiftValidationError,
  },
  172: {
    message: "error.add-shift-group",
    ns: "shift-operation",
  },
  173: {
    message: "error.add-shift-model",
    ns: "shift-operation",
  },
  174: {
    message: "error.add-time-interval",
    ns: "shift-operation",
  },
  175: {
    message: "error.edit-shift-group",
    ns: "shift-operation",
  },
  176: {
    message: "error.edit-shift-model",
    ns: "shift-operation",
  },
  177: {
    message: "error.edit-time-interval",
    ns: "shift-operation",
  },
  178: {
    message: "error.get-shift-group",
    ns: "shift-operation",
  },
  179: {
    message: "error.get-shift-model",
    ns: "shift-operation",
  },
  180: {
    message: "error.get-time-interval",
    ns: "shift-operation",
  },
  181: {
    message: "error.delete-shift-group",
    ns: "shift-operation",
  },
  182: {
    message: "error.delete-shift-model",
    ns: "shift-operation",
  },
  183: {
    message: "error.delete-time-interval",
    ns: "shift-operation",
  },
  //endregion
};
