import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const currentLayoutAdapter = createEntityAdapter();

const initialState = currentLayoutAdapter.getInitialState();

export const currentLayoutSelectors = currentLayoutAdapter.getSelectors();

const currentLayoutSlice = createSlice({
  name: "currentLayout",
  initialState: initialState,
  reducers: {
    setCurrentLayout(state, action) {
      return currentLayoutAdapter.setOne(state, action.payload);
    },
  },
  selectors: {
    selectCurrentLayout: (state, scope) =>
      currentLayoutSelectors.selectById(state, scope?.guid)?.layout,
  },
});

export const { setCurrentLayout } = currentLayoutSlice.actions;
export const { selectCurrentLayout } = currentLayoutSlice.selectors;
export default currentLayoutSlice;
