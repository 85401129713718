import FilterArray from "./FilterArray";

export default class SelectorFilterBuilder {
  dateFrom;
  dateTo;
  filter;
  showAlways;
  showAlwaysFields;

  setFilter(filter) {
    this.filter = this.__getArray(filter);
  }

  createFilter() {
    const filter = new FilterArray();
    if (this.dateTo && this.dateFrom) {
      const dateFilter = [
        ["-1", "<=", this.dateTo],
        "and",
        ["-1", ">=", this.dateFrom],
      ];
      filter.push(dateFilter, true);
    }
    const showAlwaysFilterExpressions = this.__createShowAlwaysFilter();
    if (showAlwaysFilterExpressions != null) {
      filter.push(showAlwaysFilterExpressions, true);
    }
    const combinedFilter = new FilterArray();
    combinedFilter.push(filter.filter);
    if (this.filter) combinedFilter.push(this.filter);
    this.filter = combinedFilter.filter;
  }

  __createShowAlwaysFilter() {
    if (this.showAlwaysFields == null) {
      return;
    }
    const getFilterExpressionsValueFromFixedValueItem = (value) => {
      return value.map((value) => {
        return [String(value.fieldId), "=", value.value];
      });
    };
    const getFilterExpressionFromFieldDefinition = (value) => {
      const fixedValueFilterExpressions =
        getFilterExpressionsValueFromFixedValueItem(value);
      const fixedValueFilter = new FilterArray();
      fixedValueFilterExpressions.forEach((value) => {
        fixedValueFilter.push(value, true);
      });
      return fixedValueFilter.filter;
    };
    const fieldDefinitionFilterExpressions = this.showAlwaysFields.map(
      getFilterExpressionFromFieldDefinition,
    );
    const fieldDefinitionFilterArray = new FilterArray();
    fieldDefinitionFilterExpressions.forEach((value) => {
      fieldDefinitionFilterArray.push(value, true);
    });
    return fieldDefinitionFilterArray.filter;
  }

  __getArray(array) {
    if (array && array.length !== 0) {
      return array;
    }
  }

  getFilter() {
    return JSON.stringify(this.filter);
  }
}
