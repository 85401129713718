import { endOfDay, startOfHour, addHours } from "date-fns";
import _ from "lodash";
import { parseUtcDate, parseUtcDateNullable } from "basics/utils/dateTimeUtils";
import TaskPriority from "shiftbook/modules/tasks/constants/TaskPriority";
import ScheduleFrequencyUnit from "shiftbook/modules/tasks/constants/ScheduleFrequencyUnit";

const attributesToCopy = [
  "assignees",
  "title",
  "description",
  "assets",
  "assignees",
  "priority",
  "recurring_task_start",
  "recurring_task_end",
  "endDateSelectable",
  "schedule",
];

const attributesToSubmit = [
  "title",
  "description",
  "recurring_task_start_date",
  "recurring_task_end_date",
  "schedule",
  "assets",
  "files_to_attach",
  "files_to_delete",
  "assignees",
  "priority",
];

export const transformRecurringTask = (obj) => ({
  ...obj,
  assignees: obj.assignees.map((assignee) => assignee.toLowerCase()),
  recurring_task_start: parseUtcDate(obj.recurring_task_start),
  recurring_task_end: parseUtcDateNullable(obj.recurring_task_end),
  endDateSelectable: !!obj.recurring_task_end,
});

export const createCleanRecurringTaskCopy = (obj, copyAttachments) => ({
  ..._.pick(obj, attributesToCopy),
  ...(copyAttachments
    ? {
        pictures: obj.pictures,
        files: obj.files.map((file) => ({
          ...file,
          toAttach: true,
        })),
      }
    : {
        pictures: [],
        files: [],
      }),
});

// If we send the value "23:59:59.999", MSSQL will round it up to the full hour
// To avoid that, we're setting the milliseconds to 0
const endOfDayWithoutMs = (date) => {
  const _date = endOfDay(date);
  _date.setMilliseconds(0);
  return _date;
};

export const recurringTaskToSubmitState = (obj) => {
  let recurring_task_end_date = null;
  if (obj.endDateSelectable) {
    recurring_task_end_date =
      obj.recurring_task_end ??
      endOfDayWithoutMs(new Date(obj.recurring_task_start));
  }

  return _.pick(
    {
      ...obj,
      recurring_task_start_date: obj.recurring_task_start,
      recurring_task_end_date,
    },
    attributesToSubmit,
  );
};

const validateSchedule = (schedule) => {
  return (
    schedule?.interval.value > 0 &&
    schedule?.duration.value >= 0 &&
    schedule?.creation_offset.value >= 0
  );
};

export const validateRecurringTask = (obj) => {
  return (
    !!obj &&
    !!obj.title &&
    !!obj.description &&
    obj.assets.length > 0 &&
    !!obj.recurring_task_start &&
    validateSchedule(obj.schedule) &&
    (!obj.endDateSelectable ||
      (!!obj.recurring_task_end &&
        obj.recurring_task_end > obj.recurring_task_start))
  );
};

export const getEmptyRecurringTask = () => ({
  title: "",
  assignees: [],
  assets: [],
  pictures: [],
  files: [],
  priority: TaskPriority.medium,
  recurring_task_start: startOfHour(addHours(new Date(), 1)),
  recurring_task_end: null,
  endDateSelectable: false,
  schedule: {
    interval: {
      value: 1,
      unit: ScheduleFrequencyUnit.DAYS,
    },
    duration: {
      value: 1,
      unit: ScheduleFrequencyUnit.DAYS,
    },
    creation_offset: {
      value: 0,
      unit: ScheduleFrequencyUnit.DAYS,
    },
  },
});
