import App from "App";
import i18n, { initi18n } from "config/i18nextInstance";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import "index.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import setAppLanguage from "config/setAppLanguage";
import { Provider } from "react-redux";
import store from "config/store";
import {
  savePwaInstallPrompt,
  setNewClientVersionAvailable,
} from "basics/slices/pwaSlice";
import { loadModule } from "@imes/module-host";
import { authApi } from "basics/services/authApi";
import { refreshEndpoint } from "basics/dynamicBaseQuery";
import { ENTRYMASK } from "public_entrymask/info";
import { TASKS } from "public_shiftbook/public_tasks/info";

//TODO remove once application doesn't break without
// Issue: https://gitlab.imes-solutions.com/plant-historian/web/client/-/issues/987
store.dispatch(loadModule(ENTRYMASK));
store.dispatch(loadModule(TASKS));

refreshEndpoint.endpoint = authApi.endpoints.refreshToken;

initi18n().then(() => {
  setAppLanguage(i18n.resolvedLanguage);

  const root = ReactDOM.createRoot(document.getElementById("root"));

  root.render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>,
  );
});

window.addEventListener("beforeinstallprompt", (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  setTimeout(() => {
    store.dispatch(savePwaInstallPrompt(e));
  }, 1000);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
  onUpdate: function (registration) {
    const waitingServiceWorker = registration.waiting;
    waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    store.dispatch(setNewClientVersionAvailable(true));
  },
});
