import React from "react";
import PropTypes from "prop-types";
import { TextSelect } from "public_basics/components";
import { useGetEntryMasksQuery } from "entrymask/services/entryMaskApi";

export default function TargetEntryMaskSelector(props) {
  const { data: entryMasks } = useGetEntryMasksQuery({
    meta: { visibility: "all_books" },
  });
  const values = entryMasks?.map((mask) => ({ id: mask.id, value: mask.name }));

  const validate = (value) => {
    return !props.mandatory || value != null;
  };

  const onChange = (value) => {
    if (value) {
      const result = { value: value, isValid: validate(value.value) };
      props.onChange(result);
    } else {
      const result = { value: null, isValid: false };
      props.onChange(result);
    }
  };

  const getValidationProperty = () => {
    if (!props.mandatory && !props.value) {
      return {};
    }
    return props.value != null ? { isValid: true } : { isInvalid: true };
  };

  return (
    <TextSelect
      id={props.name}
      name={props.name}
      onChange={onChange}
      value={props.value}
      values={values}
      isValid={getValidationProperty()}
      isClearable={false}
    />
  );
}

TargetEntryMaskSelector.propTypes = {
  value: PropTypes.string,
  isValid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  mandatory: PropTypes.bool,
};
