import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "basics/dynamicBaseQuery";
import { metaApi } from "basics/services/metaApi";

export const entryLinksApi = createApi({
  reducerPath: "entryLinksApi",
  baseQuery: dynamicBaseQuery,
  tagTypes: ["EntryLinks"],
  endpoints: (builder) => ({
    getEntryLinksUris: builder.query({
      query: () => ({
        endpoint: metaApi.endpoints.getBaseUris,
        path: "links.href",
      }),
    }),
    getEntryLinks: builder.query({
      query: ({ entry }) => ({
        token: "access",
        url: entry._links.links.get.href,
      }),
      providesTags: ["EntryLinks"],
    }),
    getLinkedEntryInfo: builder.query({
      query: ({ link }) => ({
        url: link._links.info.href,
        token: "access",
      }),
      providesTags: ["EntryLinks"],
    }),
    addLinkedEntry: builder.mutation({
      query: ({ entry, body }) => ({
        url: entry._links.links.add.href,
        token: "access",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["EntryLinks"],
    }),
    removeLinkedEntry: builder.mutation({
      query: ({ link }) => ({
        url: link._links.delete.href,
        method: "DELETE",
        token: "access",
      }),
      invalidatesTags: ["EntryLinks"],
    }),
  }),
});

export const {
  useGetEntryLinksQuery,
  useGetLinkedEntryInfoQuery,
  useAddLinkedEntryMutation,
  useRemoveLinkedEntryMutation,
} = entryLinksApi;
