import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Container, Form, Spinner, Stack } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { ImesLogo } from "public_basics/img/Images";
import shadowed from "public_basics/shadowed";
import PasswordCriteriaInformation from "./PasswordCriteriaInformation";
import PropTypes from "prop-types";
import PasswordInput from "./PasswordInput";
import {
  useChangePasswordMutation,
  useGetPasswordCriteriaQuery,
} from "../../services/authApi";
import { checkPasswordCriteria } from "./passwordCriteriaFunction";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../slices/authSlice";

export default function ChangePasswordForm(props) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const currentUser = useSelector(selectCurrentUser);

  const { data: passwordCriteria, isLoading } = useGetPasswordCriteriaQuery();
  const [sendChangePassword, { isLoading: changePasswordLoading }] =
    useChangePasswordMutation();

  const { t } = useTranslation("basics");

  const checkedCriteria = checkPasswordCriteria(newPassword, passwordCriteria);

  const onChangePassword = () => {
    sendChangePassword({
      body: {
        username: passwordCriteria?.username || currentUser.username,
        old_password: oldPassword,
        new_password: newPassword,
        new_password_copy: passwordConfirmation,
      },
    })
      .unwrap()
      .then(() => props.onSuccess && props.onSuccess())
      .catch(() => {});
  };

  const matchesCriteria = () => {
    if (newPassword === oldPassword) {
      return false;
    }
    return !Object.keys(checkedCriteria).some(
      (criteria) => passwordCriteria[criteria] && !checkedCriteria[criteria],
    );
  };

  const newPasswordValid = !isLoading && matchesCriteria();
  const isConfirmationPasswordValid =
    newPasswordValid && newPassword === passwordConfirmation;

  return (
    <Container>
      <div className="py-4 text-center" data-cy={"ChangePasswordFormHeader"}>
        <Image
          src={ImesLogo}
          alt="Logo"
          className={shadowed + " mb-4"}
          height={100}
        />
        <h3>{window.APPLICATION_NAME}</h3>
        <Trans
          i18nKey="change-password-for-username"
          ns="basics"
          values={{
            username: passwordCriteria?.username || currentUser.username,
          }}
          components={{ bold: <span className="fw-semibold" /> }}
        />
      </div>

      <Stack gap={2} className="mb-3">
        <PasswordInput
          label={t("old-password")}
          value={oldPassword}
          onChange={setOldPassword}
          data-cy={"OldPassword"}
        />
        <PasswordInput
          label={t("new-password")}
          value={newPassword}
          onChange={setNewPassword}
          isInvalid={!newPasswordValid}
          isValid={newPasswordValid}
          data-cy={"NewPassword"}
        >
          {oldPassword.trim() && oldPassword === newPassword && (
            <Form.Control.Feedback type="invalid">
              {t("new-password-must-be-different")}
            </Form.Control.Feedback>
          )}
        </PasswordInput>
        <PasswordInput
          label={t("password-confirmation")}
          value={passwordConfirmation}
          onChange={setPasswordConfirmation}
          isInvalid={!isConfirmationPasswordValid}
          isValid={isConfirmationPasswordValid}
          data-cy={"NewPasswordConfirmation"}
        >
          {newPassword.trim() && newPassword !== passwordConfirmation && (
            <Form.Control.Feedback type="invalid">
              {t("passwords-not-identical")}
            </Form.Control.Feedback>
          )}
        </PasswordInput>

        <PasswordCriteriaInformation checkedCriteria={checkedCriteria} />
      </Stack>

      <Stack gap={2}>
        <Button
          onClick={onChangePassword}
          data-cy={"ChangePasswordButton"}
          disabled={!isConfirmationPasswordValid || isLoading}
        >
          {changePasswordLoading && <Spinner className={"ms-2"} size="sm" />}
          {t("change-password")}
        </Button>
        <Button onClick={props.onCancel} variant={"outline-secondary"}>
          {t("back")}
        </Button>
      </Stack>
    </Container>
  );
}

ChangePasswordForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
};
