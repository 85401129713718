const ShiftGroupValidationTypes = {
  NAME_VALIDATION: "SHIFT_GROUP_NAME_VALIDATION",
  USER_UUIDS_VALIDATION: "SHIFT_GROUP_USER_UUIDS_VALIDATION",
};

const ShiftModelValidationTypes = {
  NAME_VALIDATION: "SHIFT_MODEL_NAME_VALIDATION",
  RUNTIME_VALIDATION: "SHIFT_MODEL_RUNTIME_VALIDATION",
};

const TimeIntervalValidationTypes = {
  NAME_VALIDATION: "TIME_INTERVAL_NAME_VALIDATION",
};

Object.freeze(ShiftGroupValidationTypes);
Object.freeze(ShiftModelValidationTypes);
Object.freeze(TimeIntervalValidationTypes);

export {
  ShiftGroupValidationTypes,
  ShiftModelValidationTypes,
  TimeIntervalValidationTypes,
};
