import { createSlice, isAllOf, isAnyOf, isFulfilled } from "@reduxjs/toolkit";
import { tasksApi } from "shiftbook/modules/tasks/services/tasksApi";
import { isAnyOfEndpoints } from "basics/utils/utils";

const initialState = {
  updateTaskList: false,
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState: initialState,
  reducers: {
    taskListUpdated: (state) => {
      state.updateTaskList = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        tasksApi.util.invalidateTags,
        isAllOf(
          isFulfilled,
          isAnyOfEndpoints(
            tasksApi.endpoints.addTask,
            tasksApi.endpoints.editTask,
            tasksApi.endpoints.changeTask,
            tasksApi.endpoints.setTaskRead,
            tasksApi.endpoints.reorderTask,
          ),
        ),
      ),
      (state) => {
        state.updateTaskList = true;
      },
    );
  },
  selectors: {
    selectUpdateTaskList: (state) => state.updateTaskList,
  },
});

export const { taskListUpdated } = tasksSlice.actions;
export const { selectUpdateTaskList } = tasksSlice.selectors;

export default tasksSlice;
