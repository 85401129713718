import { useComponent } from "@imes/module-host";

export const TASKS_COMPONENT = "TASKS_COMPONENT";
export const TASKS_NAV_ITEM = "TASKS_NAV_ITEM";
export const TASKS_QUICK_NAV_ITEM = "TASKS_QUICK_NAV_ITEM";
export const RECURRING_TASK_ADMINISTRATION = "RECURRING_TASK_ADMINISTRATION";
export const TASKS_SKELETON = "TASKS_SKELETON";
export const TASK_SELECT = "TASK_SELECT";
export const TASK_ENTRY_LINK = "TASK_ENTRY_LINK";

export const Tasks = () => useComponent(TASKS_COMPONENT);
export const TasksNavItem = () => useComponent(TASKS_NAV_ITEM);
export const TasksQuickNavItem = () => useComponent(TASKS_QUICK_NAV_ITEM);
export const RecurringTaskAdministration = () =>
  useComponent(RECURRING_TASK_ADMINISTRATION);
export const TasksSkeleton = () => useComponent(TASKS_SKELETON);
export const TaskSelect = (props) => useComponent(TASK_SELECT, props);
export const TaskEntryLink = (props) => useComponent(TASK_ENTRY_LINK, props);
