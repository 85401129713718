import { OverlayTrigger, Tooltip as BootstrapTooltip } from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";

export default function Tooltip(props) {
  const renderTooltip = (tooltipProps) => {
    return <BootstrapTooltip {...tooltipProps}>{props.text}</BootstrapTooltip>;
  };

  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement={"bottom"}
      overlay={renderTooltip}
    >
      {props.children}
    </OverlayTrigger>
  );
}

Tooltip.propTypes = {
  text: PropTypes.string,
  children: PropTypes.element,
};
