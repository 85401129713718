import React from "react";
import PropTypes from "prop-types";
import { Badge } from "react-bootstrap";
import { css } from "@emotion/css";

export default function RelevantEntriesCount(props) {
  if (props.relevantEntriesCount)
    return (
      <Badge
        pill
        bg={null}
        className={
          "ms-2 mb-1 text-black-50 " +
          css`
            background-color: var(--bs-imes-yellow);
          `
        }
      >
        {props.relevantEntriesCount}
      </Badge>
    );
  return null;
}

RelevantEntriesCount.propTypes = {
  relevantEntriesCount: PropTypes.number,
};
