import { css } from "@emotion/css";
import CustomModal from "../CustomModal/CustomModal";
import { useTranslation } from "react-i18next";
import CustomizedSelect from "../Selects/CustomizedSelect";
import { Form } from "react-bootstrap";
import { useMemo, useState } from "react";
import { TaskSelect } from "public_shiftbook/public_tasks/components";
import { EntryMaskEntrySelect } from "public_entrymask/components";
import EntryTypes from "public_basics/constants/EntryTypes";
import PropTypes from "prop-types";
import SnackbarAlertColour from "public_basics/constants/SnackbarAlertColour";
import { useAddLinkedEntryMutation } from "../../services/entryLinksApi";
import { useSnackbar } from "notistack";

const linkableEntities = [
  {
    name: EntryTypes.entryMasksEntry,
    component: EntryMaskEntrySelect,
    entry_name: { name: "entry-mask" },
  },
  {
    name: EntryTypes.task,
    component: TaskSelect,
    entry_name: { name: "task" },
  },
];

function DynamicEntrySelect(props) {
  const { component: Component, ...rest } = props;

  if (!Component) return null;

  return <Component {...rest} />;
}

export default function AddLinkDialog(props) {
  const { t } = useTranslation(["basics", "common"]);
  const [type, setType] = useState(null);
  const [entry, setEntry] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [addLink] = useAddLinkedEntryMutation();

  const options = useMemo(
    () =>
      linkableEntities.map((entity) => ({
        value: entity.name,
        label: t(entity.name),
      })),
    [t],
  );

  const handleSave = () => {
    addLink({
      entry: props.entry,
      body: {
        first_entity_type: props.entryType,
        first_entity_uuid: props.entry.uuid,
        second_entity_type: type.value,
        second_entity_uuid: entry.guid,
      },
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar(t("link-success"), {
          variant: SnackbarAlertColour.SUCCESS,
        });
        setEntry(null);
        props.onClose();
      })
      .catch(() => null);
  };
  const entity = linkableEntities.find((entity) => entity.name === type?.value);
  return (
    <CustomModal
      data-cy={"link-modal"}
      onClose={props.onClose}
      open={props.open}
      saveButtonText={t("add", { ns: "common" })}
      onSave={handleSave}
      title={t("add-linked-entry")}
      size={"lg"}
      backdropClassName={css`
        z-index: 1055 !important;
      `}
      isValid={!!entry}
    >
      <Form.Group data-cy={"link-type-container"} className={"pb-2"}>
        <Form.Label>{t("type")}</Form.Label>
        <CustomizedSelect options={options} onChange={setType} value={type} />
      </Form.Group>
      {type && (
        <Form.Group data-cy={"entry-select"}>
          <Form.Label>
            {t(entity?.entry_name.name, { ns: "basics" })}
          </Form.Label>
          <DynamicEntrySelect
            component={entity?.component}
            value={entry}
            onChange={setEntry}
            uuidsToFilter={props.linkedEntryUuids}
          />
        </Form.Group>
      )}
    </CustomModal>
  );
}

AddLinkDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  entry: PropTypes.object,
  entryType: PropTypes.oneOf(Object.values(EntryTypes)).isRequired,
  linkedEntryUuids: PropTypes.arrayOf(PropTypes.string),
};
