import { useEffect, useMemo, useState } from "react";
import SelectStates from "basics/constants/SelectStates";

export default function useTreeSelect(
  unFormattedOptions,
  onChange,
  value,
  disabled,
  disableIndeterminate,
  singleItemSelect = true,
) {
  if (value === null) value = [];
  const [hasChanged, setHasChanged] = useState(false);
  // When only one option is given this hook selects this option automatically
  useEffect(() => {
    const getSingleItem = (unFormattedOptions) => {
      if (unFormattedOptions.length === 1)
        if (unFormattedOptions[0].items.length > 0)
          return getSingleItem(unFormattedOptions[0].items);
        else return unFormattedOptions[0].guid;
      return null;
    };

    if (value.length > 0 || !onChange || disabled) return;
    if (singleItemSelect && !hasChanged) {
      const singleItem = getSingleItem(unFormattedOptions);
      if (singleItem) {
        setHasChanged(true);
        onChange([singleItem]);
      }
    }
  }, [
    unFormattedOptions,
    onChange,
    value,
    disabled,
    singleItemSelect,
    hasChanged,
  ]);

  const isParentSelected = (optionGuid, values) => {
    const option = options.find((option) => option.value === optionGuid);
    if (!option || !option.parent) return false;
    if (values.includes(option.parent)) return true;
    return isParentSelected(option.parent, values);
  };
  const onChangeSelected = (values) => {
    if (disableIndeterminate) onChange(values);
    else onChange(values.filter((value) => !isParentSelected(value, values)));
  };

  const options = useMemo(() => {
    const getState = (optionGuid, parentSelected) => {
      if (parentSelected && !disableIndeterminate)
        return SelectStates.indeterminate;
      if (value.includes(optionGuid)) return SelectStates.selected;
      return SelectStates.notSelected;
    };

    const getDisplayItems = (
      displayItems,
      unFormattedOptions,
      depth = 0,
      parent = null,
      parentSelected = false,
    ) => {
      unFormattedOptions.forEach((option) => {
        const displayItem = {
          label: option.name,
          value: option.guid,
          depth: depth,
          parent: parent,
          state: getState(option.guid, parentSelected),
          hasChildren: option.items.length > 0,
        };
        displayItems.push(displayItem);
        if (displayItem.hasChildren) {
          getDisplayItems(
            displayItems,
            option.items,
            depth + 1,
            option.guid,
            value.includes(option.guid) || parentSelected,
          );
        }
      });
      return displayItems;
    };

    return getDisplayItems([], unFormattedOptions);
  }, [unFormattedOptions, disableIndeterminate, value]);

  const formattedValue = value.map((optionGuid) => {
    const option = options.find((item) => item.value === optionGuid);
    return {
      value: optionGuid,
      label: option ? option.label : optionGuid,
      depth: option ? option.depth : null,
      parent: option ? option.parent : null,
      state: option ? option.state : null,
    };
  });

  const [expanded, setExpanded] = useState([]);

  // This hook expands the tree when nested options are selected
  useEffect(() => {
    const expandParents = (currentValue, parents) => {
      const currentOption = options.find(
        (option) => option.value === currentValue,
      );
      if (!currentOption) return;
      const parent = options.find(
        (option) => option.value === currentOption.parent,
      );
      if (parent) {
        parents.push(parent.value);
        expandParents(parent.value, parents);
      }
    };

    if (!value || value.length === 0) return;

    const parents = [];
    value.forEach((item) => {
      expandParents(item, parents);
    });

    setExpanded(parents);
  }, [value, setExpanded, options]);

  const findChildren = (parent) => {
    const children = [];
    for (const option of options)
      if (option.parent === parent) {
        children.push(option.value);
        children.push(...findChildren(option.value));
      }
    return children;
  };

  const onToggleExpanded = (parent) => {
    if (expanded.includes(parent)) {
      const elementsToRemove = findChildren(parent);
      elementsToRemove.push(parent);
      setExpanded(
        expanded.filter((element) => !elementsToRemove.includes(element)),
      );
    } else setExpanded([...expanded, parent]);
  };

  const onClickOption = (option) => {
    if (value.includes(option.value))
      onChangeSelected(value.filter((item) => item !== option.value));
    else onChangeSelected([...value, option.value]);
  };

  return {
    options,
    onChangeSelected,
    formattedValue,
    expanded,
    onToggleExpanded,
    onClickOption,
  };
}
