import React from "react";
import ChangePasswordForm from "./ChangePasswordForm";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

export default function ChangePasswordDialog(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onCancel}
      centered
      dialogClassName="modal-fullscreen-sm-down"
    >
      <Modal.Body>
        <ChangePasswordForm
          onCancel={props.onCancel}
          onSuccess={props.onSuccess}
        />
      </Modal.Body>
    </Modal>
  );
}

ChangePasswordDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
