import React from "react";
import styled from "@emotion/styled";

const AlignBottomDiv = styled("div")`
  margin-top: auto;
  margin-bottom: 0;
`;

export default function CustomPopoverFooter(props) {
  return (
    <AlignBottomDiv className={"p-2"}>
      <hr className={"mt-0"} />
      {props.children}
    </AlignBottomDiv>
  );
}
