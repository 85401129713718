import { css } from "@emotion/css";
import { Badge, Button } from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";

export default function ExpandBadge(props) {
  const { t } = useTranslation("basics");

  return (
    <Badge
      pill
      as={Button}
      bg={null}
      onClick={() => props.onSetExpanded(!props.expanded)}
      variant={"outline-primary"}
      className={css`
        position: absolute !important;
        display: flex !important;
        left: 50%;
        transform: translate(0, -3em);
        color: var(--bs-primary) !important;

        &:hover,
        &:active {
          color: var(--bs-light) !important;
        }
      `}
    >
      {props.expanded ? (
        <ChevronUp
          className={css`
            background-color: transparent !important;
          `}
        />
      ) : (
        <ChevronDown
          className={css`
            background-color: transparent !important;
          `}
        />
      )}
      <span
        className={css`
          padding-top: 2px;
        `}
      >
        {props.expanded ? t("less") : t("more")}
      </span>
    </Badge>
  );
}

ExpandBadge.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onSetExpanded: PropTypes.func.isRequired,
};
