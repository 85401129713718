import DynamicIcon from "basics/components/DynamicIcon";
import EntryMaskGroupType from "entrymask/constants/EntryMaskGroupType";
import { NavigationItem, NavigationLink } from "public_basics/components";
import { ENTRYMASK, icon, path } from "public_entrymask/info";
import React, { useState } from "react";
import { ViewList } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  useGetEntryMaskGroupsQuery,
  useGetEntryMasksQuery,
} from "../services/entryMaskApi";
import useSelectedEntryMaskId from "../customHooks/useSelectedEntryMaskId";
import EntryMaskGroupPopover from "./EntryMaskGroupPopover";

export default function NavItem() {
  const { t } = useTranslation("basics");
  const navigate = useNavigate();
  const [groupPopoverSettings, setGroupPopoverSettings] = useState();

  const { data: groups } = useGetEntryMaskGroupsQuery({
    meta: { visibility: "groups" },
  });

  const entryMaskGroups = groups?.filter(
    (group) => group.type === EntryMaskGroupType.pinned,
  );

  const { data: entryMasks } = useGetEntryMasksQuery({
    meta: { visibility: "all_books" },
  });

  const selectedEntryMaskId = useSelectedEntryMaskId(
    "entry-masks/:entryMaskId/*",
  );

  const getElementPosition = (element) => {
    const elementClientRect = element.getBoundingClientRect();
    return {
      top: elementClientRect.bottom,
      left: elementClientRect.x,
    };
  };
  const onSelectedBookChanged = (book) => {
    navigate(`${path}/${book.id}`);
  };
  const onSelectOverview = () => {
    navigate(path);
  };
  return (
    <>
      <EntryMaskGroupPopover
        settings={groupPopoverSettings}
        onClose={() => setGroupPopoverSettings(null)}
      />
      <NavigationItem
        name={t(ENTRYMASK)}
        icon={icon}
        to={path}
        length={entryMaskGroups?.length + 1}
      >
        <NavigationLink
          name={t("overview", { ns: "common" })}
          icon={ViewList}
          onClick={onSelectOverview}
          to={path}
          end
        />
        {entryMaskGroups?.map((group) => {
          const groupEntryMasks = entryMasks?.filter((book) =>
            book.group_ids?.includes(group.id),
          );
          const onClick = (element) => {
            if (groupEntryMasks.length === 1) {
              onSelectedBookChanged(groupEntryMasks[0]);
            } else {
              setGroupPopoverSettings({
                entryMasks: groupEntryMasks,
                position: getElementPosition(element.target),
              });
            }
          };
          let relevantEntriesCount = 0;
          groupEntryMasks?.forEach((book) => {
            if (book.relevant_entries_count)
              relevantEntriesCount += book.relevant_entries_count;
          });
          return (
            <NavigationLink
              name={t(group.name)}
              icon={() => DynamicIcon({ parts: group.icon.icon })}
              badge={relevantEntriesCount}
              onClick={onClick}
              isActive={
                !!groupEntryMasks?.find(
                  (book) => book.id === selectedEntryMaskId,
                )
              }
              key={group.id}
            />
          );
        })}
      </NavigationItem>
    </>
  );
}
