import { SkeletonDataGrid } from "public_basics/components";
import React from "react";
import Skeleton from "react-loading-skeleton";

function TasksSkeleton() {
  return (
    <div className="d-flex flex-column h-100">
      <div className="ms-3 mt-3 mb-2 d-flex">
        <Skeleton width={180} height={24} className="me-2" />
        <Skeleton width={200} height={24} />
      </div>
      <SkeletonDataGrid columns={10} rows={10} className="flex-fill" />
    </div>
  );
}

export default TasksSkeleton;
