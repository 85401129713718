import React, { useEffect, useState } from "react";
import AnimatedRefreshIcon from "./AnimatedRefreshIcon";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Tooltip from "basics/components/Tooltip";

export default function RefreshButton({
  onRefresh,
  button,
  isLoading,
  iconSize,
  text,
  disabled,
  ...rest
}) {
  const [inAnimation, setInAnimation] = useState(false);
  const { t } = useTranslation("common");

  const onClick = () => {
    onRefresh();
    setInAnimation(true);
  };
  useEffect(() => {
    if (isLoading) setInAnimation(true);
  }, [isLoading]);

  return (
    <Tooltip text={t("refresh-tooltip")}>
      {React.cloneElement(button || <Button />, {
        ...rest,
        onClick: onClick,
        disabled: disabled || inAnimation,
        children: (
          <>
            <AnimatedRefreshIcon
              isLoading={!!isLoading}
              inAnimation={inAnimation}
              size={iconSize}
              onAnimationStop={() => setInAnimation(false)}
            />
            {text && <span>{text}</span>}
          </>
        ),
      })}
    </Tooltip>
  );
}
