import useSyncedRef from "public_basics/useSyncedRef";
import React, { forwardRef, memo, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import DataGrid, {
  Column,
  ColumnChooser,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  LoadPanel,
  Paging,
  RowDragging,
  Scrolling,
  Selection,
  Sorting,
  StateStoring,
} from "devextreme-react/data-grid";
import { Image } from "react-bootstrap";
import DataTypes from "public_basics/constants/DataTypes";
import DataGridColumnsDataTypes from "public_basics/constants/DataGridColumnsDataTypes";
import moment from "moment";
import dateFormatter from "./FormattersAndParsers/DateFormatter";
import dateParser from "./FormattersAndParsers/DateParser";
import timeFormatter from "./FormattersAndParsers/TimeFormatter";
import timeParser from "./FormattersAndParsers/TimeParser";
import dateTimeFormatter from "./FormattersAndParsers/DateTimeFormatter";
import dateTimeParser from "./FormattersAndParsers/DateTimeParser";
import FilterItemsModel from "public_basics/models/FilterItemsModel";
import CustomStore from "devextreme/data/custom_store";
import _ from "lodash";
import LoadOperations from "public_basics/constants/LoadOperations";
import GroupItemsModel from "public_basics/models/GroupItemsModel";
import EntryItemsModel from "public_basics/models/EntryItemsModel";
import DataSourceTypes from "public_basics/constants/DataSourceTypes";
import { useDispatch } from "react-redux";
import SnackbarAlertColour from "public_basics/constants/SnackbarAlertColour";
import DataGridDataColumnDefinitionModel from "public_basics/models/DataGridDataColumnDefinitionModel";
import LoadOptionsModel from "public_basics/models/LoadOptionsModel";
import LinksModel from "public_basics/models/LinksModel";
import "./DataGridWrapper.scss";
import { css } from "@emotion/css";
import { useTranslation } from "react-i18next";
import parseNumber from "public_basics/parseNumber";
import formatNumber from "public_basics/formatNumber";
import clsx from "clsx";
import getFilterBuilder from "./getFilterBuilder";
import { useQueryGridData } from "./dataGridApi";
import { useSnackbar } from "notistack";

const filterBuilderPopupPosition = {
  of: window,
  at: "top",
  my: "top",
  offset: { y: 10 },
};

const getElementPosition = (element) => {
  const elementClientRect = element.getBoundingClientRect();
  return {
    top: elementClientRect.bottom,
    left: elementClientRect.x,
  };
};
const DataGridWrapper = forwardRef(function DataGridWrapper(props, ref) {
  const {
    id,
    dataSource,
    onExporting,
    showBorders,
    noDataText,
    remoteOperations,
    columnResizingMode,
    dataColumnDefinitions,
    columnButtonDefinitions,
    lineNumberColumnEnabled,
    sortingSpecs,
    summary,
    requestLoadOptions,
    links,
    onLoadFinished,
    filterItemsModel,
    groupItemsModel,
    entryItemsModel,
    currentLayout,
    currentLayoutData,
    onSetCurrentLayoutData,
    onSetLayoutPopoverPosition,
    dataSourceType,
    stateStoring,
    onOpenSingleEntry,
    filterRow,
    headerFilter,
    filterPanel,
    grouping,
    sortingMode,
    className,
    onSetLatestLoadOptions,
    onRowPrepared,
    onCellPrepared,
    defaultFilter,
    onReorder,
    toolbar,
    selectionProps,
    onSelectionChanged,
    onSetCurrentLayout,
    initialFilter,
    setLoading,
  } = props;
  const loadingBlocked = useRef(false);
  const previousLayout = useRef(null);
  const openSingleEntry = useRef(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["basics", "tasks"]);
  const { enqueueSnackbar } = useSnackbar();

  const dataGridRef = useSyncedRef(ref);

  const queryGridData = useQueryGridData();

  const getLineNumberColumn = () => {
    return (
      <Column
        key="row_number"
        dataField="rowNumber"
        caption="#"
        fixed={true}
        fixedPosition="left"
        allowReordering={false}
        allowFiltering={false}
        allowSorting={false}
        dataType="number"
      />
    );
  };

  const getDataColumns = () => {
    if (dataColumnDefinitions) {
      const columns = [];

      dataColumnDefinitions.forEach((columnDefinition) => {
        const column = getDataColumn(columnDefinition);
        columns.push(column);
      });

      return columns;
    }
  };

  const getDataColumn = (columnDefinition) => {
    let cellRender = columnDefinition.cellRender;
    let columnDataType = DataGridColumnsDataTypes.string;
    let format = null;
    let customHeaderFilter = undefined;
    let editorOptions = null;
    let calculateFilterExpression = null;
    let groupIndex = columnDefinition.groupIndex;
    let additionalClassName = clsx(
      css`
        max-width: 300px;
      `,
      columnDefinition.additionalClassName,
    );
    let type = null;
    let showInColumnChooser = true;
    let filterOperations = undefined;

    const uuidColFilterOperations = [
      "contains",
      "notcontains",
      "startswith",
      "endswith",
      "=",
      "<>",
      "isblank",
      "isnotblank",
      "anyof",
      "noneof",
    ];

    switch (columnDefinition.dataType) {
      case DataTypes.type.Status:
        if (!cellRender) {
          cellRender = renderStatusFieldCell;
        }
        customHeaderFilter = customHeaderFilterTranslationFormatter;
        break;
      case DataTypes.type.Priority:
        customHeaderFilter = customHeaderFilterTranslationFormatter;
        break;
      case DataTypes.type.DateTime:
        format = {
          formatter: dateTimeFormatter,
          parser: dateTimeParser,
        };
        columnDataType = DataGridColumnsDataTypes.datetime;
        customHeaderFilter = customHeaderFilterFormatter;
        calculateFilterExpression = calculateFilterExpressionForDateTimeColumns;
        break;
      case DataTypes.type.DateCalculation:
        format = {
          formatter: dateTimeFormatter,
          parser: dateTimeParser,
        };
        columnDataType = DataGridColumnsDataTypes.datetime;
        customHeaderFilter = customHeaderFilterFormatter;
        calculateFilterExpression = calculateFilterExpressionForDateTimeColumns;
        break;
      case DataTypes.type.Date:
        format = {
          formatter: dateFormatter,
          parser: dateParser,
        };
        columnDataType = DataGridColumnsDataTypes.date;
        customHeaderFilter = customHeaderFilterFormatter;
        calculateFilterExpression = calculateFilterExpressionForDateTimeColumns;
        break;
      case DataTypes.type.Time:
        format = {
          formatter: timeFormatter,
          parser: timeParser,
        };
        columnDataType = DataGridColumnsDataTypes.datetime;
        customHeaderFilter = customHeaderFilterFormatter;
        // Show only the clock in the datetime picker of time columns.
        editorOptions = { calendarOptions: { visible: false } };
        calculateFilterExpression = calculateFilterExpressionForDateTimeColumns;
        break;
      case DataTypes.type.Float:
        columnDataType = DataGridColumnsDataTypes.number;
        format = {
          formatter: formatNumber,
          parser: parseNumber,
        };
        break;
      case DataTypes.type.Calculation:
        columnDataType = DataGridColumnsDataTypes.number;
        format = {
          formatter: formatNumber,
          parser: parseNumber,
        };
        break;
      case DataTypes.type.Bit:
        columnDataType = DataGridColumnsDataTypes.boolean;
        break;
      case DataTypes.type.File:
        columnDataType = DataGridColumnsDataTypes.buttons;
        cellRender = (row) => {
          const files = row.data[columnDefinition.id];
          return (
            <div className={"d-flex justify-content-center"}>
              {DataTypes.parse(columnDefinition.dataType).render(
                dispatch,
                files,
              )}
            </div>
          );
        };
        additionalClassName +=
          " " +
          css`
            .dx-datagrid-rowsview & {
              padding: 0 !important;
            }
          `;
        //type = "buttons";
        break;
      case DataTypes.type.RecurringTaskIndicator:
        columnDataType = DataGridColumnsDataTypes.boolean;
        showInColumnChooser = false;
        cellRender = (row) => {
          return DataTypes.RecurringTaskIndicator.render(
            dispatch,
            row.data[columnDefinition.id],
          );
        };
        break;
      case DataTypes.type.Assets:
        columnDataType = DataGridColumnsDataTypes.assets;
        filterOperations = [
          "contains",
          "notcontains",
          "startswith",
          "endswith",
          "=",
          "<>",
          "isblank",
          "isnotblank",
          "like",
          "oneOf",
          "noneOf",
        ];
        cellRender = (row) =>
          DataTypes.Assets.render(row.data[columnDefinition.id], true);
        additionalClassName +=
          " " +
          css`
            &:not([role="columnheader"]) {
              overflow: visible !important;
              padding: 0 !important;
            }
          `;
        break;
      case DataTypes.type.Assignees:
        columnDataType = DataGridColumnsDataTypes.assignees;
        filterOperations = uuidColFilterOperations;
        cellRender = (row) =>
          DataTypes.Assignees.render(row.data[columnDefinition.id], true);
        additionalClassName +=
          " " +
          css`
            &:not([role="columnheader"]) {
              overflow: visible !important;
              padding: 0 !important;
            }
          `;
        customHeaderFilter = customFormatter; // temporary solution https://gitlab.imes-solutions.com/plant-historian/web/client/-/issues/1085
        break;
      case DataTypes.type.User:
        columnDataType = DataGridColumnsDataTypes.user;
        cellRender = (row) =>
          DataTypes.User.render(row.data[columnDefinition.id]);
        filterOperations = uuidColFilterOperations;
        customHeaderFilter = customFormatter; // temporary solution https://gitlab.imes-solutions.com/plant-historian/web/client/-/issues/1085
        break;
      default:
        break;
    }

    if (dataSourceType === DataSourceTypes.object) {
      customHeaderFilter = undefined;
      calculateFilterExpression = undefined;
    }

    const index = sortingSpecs
      ? sortingSpecs.findIndex((x) => x.id === columnDefinition.id)
      : -1;
    if (index >= 0) {
      const sortingSpec = sortingSpecs[index];
      return (
        <Column
          key={columnDefinition.key}
          dataField={columnDefinition.id.toString()}
          caption={columnDefinition.caption}
          cellRender={cellRender}
          dataType={columnDataType}
          format={format}
          editorOptions={editorOptions}
          groupIndex={groupIndex}
          sortIndex={index}
          width={columnDefinition.width}
          allowSorting={columnDefinition.allowSorting}
          allowFiltering={columnDefinition.allowFiltering}
          allowGrouping={columnDefinition.allowGrouping}
          allowHeaderFiltering={columnDefinition.allowHeaderFiltering}
          sortOrder={sortingSpec.asc ? "asc" : "desc"}
          cssClass={additionalClassName}
          showInColumnChooser={showInColumnChooser}
          headerCellComponent={columnDefinition.headerCellComponent}
          customizeText={columnDefinition.customizeText}
          filterOperations={filterOperations}
          {...(calculateFilterExpression
            ? { calculateFilterExpression: calculateFilterExpression }
            : {})}
          allowExporting={columnDefinition.allowExporting}
          visible={columnDefinition.visible}
        >
          <HeaderFilter dataSource={customHeaderFilter} />
        </Column>
      );
    } else {
      return (
        <Column
          key={columnDefinition.key}
          dataField={columnDefinition.id.toString()}
          caption={columnDefinition.caption}
          cellRender={cellRender}
          dataType={columnDataType}
          format={format}
          editorOptions={editorOptions}
          groupIndex={groupIndex}
          cssClass={additionalClassName}
          type={type}
          fixed={false}
          width={columnDefinition.width}
          showInColumnChooser={showInColumnChooser}
          allowSorting={columnDefinition.allowSorting}
          allowFiltering={columnDefinition.allowFiltering}
          allowGrouping={columnDefinition.allowGrouping}
          allowHeaderFiltering={columnDefinition.allowHeaderFiltering}
          headerCellComponent={columnDefinition.headerCellComponent}
          customizeText={columnDefinition.customizeText}
          filterOperations={filterOperations}
          {...(calculateFilterExpression
            ? { calculateFilterExpression: calculateFilterExpression }
            : {})}
          allowExporting={columnDefinition.allowExporting}
          visible={columnDefinition.visible}
        >
          <HeaderFilter dataSource={customHeaderFilter} />
        </Column>
      );
    }
  };

  const renderStatusFieldCell = (data) => {
    const columnDefinition = dataColumnDefinitions.find(
      (x) => x.id.toString() === data.column.dataField,
    );
    if (columnDefinition) {
      const fixedValue = columnDefinition.fixedValueList.find(
        (x) => x.value === data.value,
      );
      if (fixedValue) {
        return (
          <>
            <Image
              className="me-1"
              src={"data:image;base64," + fixedValue.valueImage}
              alt=""
              rounded
            />
            {data.value}
          </>
        );
      }
    }
    return <>{data.value}</>;
  };

  const calculateFilterExpressionForDateTimeColumns = function (
    filterValue,
    selectedFilterOperation,
    target,
  ) {
    const currentDataField = this.dataField;
    let fieldDataType =
      getDataTypeOfCurrentHeaderFilterDataField(currentDataField);

    // The following usage of the terms "headerFilter" and "filterBuilder" by DevExpress is wrong
    // or at least confusing. Please read the following comments for better understanding!!!

    // Gets called when the header filter is used
    // or when the "is any of"/"is none of" operators are used in the filter builder.
    if (target === "headerFilter" && selectedFilterOperation === "=") {
      return getFilterExpressionFromHeaderFilterValue(
        filterValue,
        fieldDataType,
        currentDataField,
      );
    }
    // Gets called when the datebox button in the filter row was clicked
    // or when operators other then "is any of"/"is none of" are used in the filter builder.
    else if (target === "filterBuilder") {
      if (selectedFilterOperation !== "between") {
        return getFilterExpressionFromFilterRowValue(
          filterValue,
          selectedFilterOperation,
          fieldDataType,
          currentDataField,
        );
      } else if (filterValue[0] && filterValue[1]) {
        let from = getFilterExpressionFromFilterRowValue(
          filterValue[0],
          ">=",
          fieldDataType,
          currentDataField,
        );
        let to = getFilterExpressionFromFilterRowValue(
          filterValue[1],
          "<=",
          fieldDataType,
          currentDataField,
        );
        return [from, "and", to];
      }
    }

    // Invokes the default filtering behavior
    return this.defaultCalculateFilterExpression.apply(this, arguments);
  };

  const correctDateByOne = (date, addOne, unit) => {
    let filterMoment = moment(date);
    if (addOne) {
      filterMoment.add(1, unit);
    }
    return filterMoment;
  };

  const getDateTimeStringForFilterExpression = (filterValue, addOne) => {
    // If addOne is true, then one second is added to the datetime generated from filterValue.

    let filterMoment;

    if (filterValue instanceof Date) {
      filterMoment = correctDateByOne(filterValue, addOne, "second");
    } else if (Array.isArray(filterValue)) {
      // This gets called when the filteroperator gets changed from 'between' to another operator.
      // The filtervalue is then an array, that contains the from and to date.
      filterMoment = correctDateByOne(filterValue[0], addOne, "second");
    } else {
      const splitFilterValue = filterValue.split("/");

      const year = parseInt(splitFilterValue[0]);
      const month =
        splitFilterValue.length >= 2 ? parseInt(splitFilterValue[1]) : 1;
      const day =
        splitFilterValue.length >= 3 ? parseInt(splitFilterValue[2]) : 1;
      const hour =
        splitFilterValue.length >= 4 ? parseInt(splitFilterValue[3]) : 0;
      const minute =
        splitFilterValue.length >= 5 ? parseInt(splitFilterValue[4]) : 0;
      const second =
        splitFilterValue.length >= 6 ? parseInt(splitFilterValue[5]) : 0;

      filterMoment = moment(
        new Date(year, month - 1, day, hour, minute, second),
      );

      if (addOne) {
        switch (splitFilterValue.length) {
          case 1:
            filterMoment.add(1, "year");
            break;
          case 2:
            filterMoment.add(1, "month");
            break;
          case 3:
            filterMoment.add(1, "day");
            break;
          case 4:
            filterMoment.add(1, "hour");
            break;
          case 5:
            filterMoment.add(1, "minute");
            break;
          default:
            filterMoment.add(1, "second");
        }
      }
    }

    return filterMoment.format("YYYY-MM-DD HH:mm:ss");
  };

  const getDateStringForFilterExpression = (filterValue, addOne) => {
    // If addOne is true, then one day is added to the date generated from filterValue.

    let filterMoment;

    if (filterValue instanceof Date) {
      filterMoment = correctDateByOne(filterValue, addOne, "day");
    } else if (Array.isArray(filterValue)) {
      // This gets called when the filteroperator gets changed from 'between' to another operator.
      // The filtervalue is then an array, that contains the from and to date.
      filterMoment = correctDateByOne(filterValue[0], addOne, "day");
    } else {
      const splitFilterValue = filterValue.split("/");

      const year = parseInt(splitFilterValue[0]);
      const month =
        splitFilterValue.length >= 2 ? parseInt(splitFilterValue[1]) : 1;
      const day =
        splitFilterValue.length >= 3 ? parseInt(splitFilterValue[2]) : 1;

      filterMoment = moment(new Date(year, month - 1, day));

      if (addOne) {
        switch (splitFilterValue.length) {
          case 1:
            filterMoment.add(1, "year");
            break;
          case 2:
            filterMoment.add(1, "month");
            break;
          default:
            filterMoment.add(1, "day");
        }
      }
    }

    return filterMoment.format("YYYY-MM-DD");
  };

  const getTimeStringForFilterExpression = (filterValue, addOne) => {
    // If addOne is true, then one second is added to the time generated from filterValue.

    let filterMoment;

    if (filterValue instanceof Date) {
      filterMoment = correctDateByOne(filterValue, addOne, "second");
    } else if (Array.isArray(filterValue)) {
      // This gets called when the filteroperator gets changed from 'between' to another operator.
      // The filtervalue is then an array, that contains the from and to date.
      filterMoment = correctDateByOne(filterValue[0], addOne, "second");
    } else {
      const splitFilterValue = filterValue.split("/");

      const hour = parseInt(splitFilterValue[0]);
      const minute =
        splitFilterValue.length >= 2 ? parseInt(splitFilterValue[1]) : 0;
      const second =
        splitFilterValue.length >= 3 ? parseInt(splitFilterValue[2]) : 0;

      filterMoment = moment(new Date(0, -1, 0, hour, minute, second));

      if (addOne) {
        switch (splitFilterValue.length) {
          case 1:
            filterMoment.add(1, "hour");
            break;
          case 2:
            filterMoment.add(1, "minute");
            break;
          default:
            filterMoment.add(1, "second");
        }
      }
    }

    return filterMoment.format("HH:mm:ss");
  };

  const getDataTypeOfCurrentHeaderFilterDataField = (dataField) => {
    let fieldDataType = DataTypes.type.DateTime;
    const fieldIndex = dataColumnDefinitions.findIndex(
      (x) => x.id.toString() === dataField,
    );
    if (fieldIndex >= 0) {
      fieldDataType = dataColumnDefinitions[fieldIndex].dataType;
    }
    return fieldDataType;
  };

  const getFilterExpressionFromHeaderFilterValue = (
    filterValue,
    fieldDataType,
    dataField,
  ) => {
    let fromDateString = "";
    let toDateString = "";
    switch (fieldDataType) {
      case DataTypes.type.DateTime:
        fromDateString = getDateTimeStringForFilterExpression(
          filterValue,
          false,
        );
        toDateString = getDateTimeStringForFilterExpression(filterValue, true);
        break;
      case DataTypes.type.Date:
        fromDateString = getDateStringForFilterExpression(filterValue, false);
        toDateString = getDateStringForFilterExpression(filterValue, true);
        break;
      case DataTypes.type.Time:
        fromDateString = getTimeStringForFilterExpression(filterValue, false);
        toDateString = getTimeStringForFilterExpression(filterValue, true);
        break;
      default:
    }
    return [
      [dataField, ">=", fromDateString],
      "and",
      [dataField, "<", toDateString],
    ];
  };

  const getFilterExpressionFromFilterRowValue = (
    filterValue,
    selectedFilterOperation,
    fieldDataType,
    dataField,
  ) => {
    let dateString = "";
    if (filterValue == null) {
      // filterValue is null when the operators 'is blank' or 'is not blank' were selected.
      dateString = null;
    } else {
      switch (fieldDataType) {
        case DataTypes.type.DateTime:
          dateString = getDateTimeStringForFilterExpression(filterValue, false);
          break;
        case DataTypes.type.Date:
          dateString = getDateStringForFilterExpression(filterValue, false);
          break;
        case DataTypes.type.Time:
          dateString = getTimeStringForFilterExpression(filterValue, false);
          break;
        default:
      }
    }
    return [dataField, selectedFilterOperation, dateString];
  };

  const customStore = useMemo(() => {
    return new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (loadingBlocked.current) {
          return new Promise((resolve) => {
            resolve({
              data: [],
              groupCount: 0,
              totalCount: 0,
            });
          });
        }

        const initializedRequestLoadOptions = _.cloneDeep(requestLoadOptions);

        initializedRequestLoadOptions.initLoadOptions(loadOptions);

        if (loadOptions.group && !("dataField" in loadOptions)) {
          return new Promise(async (resolve) => {
            const response = await queryGridData(
              links.getGroups,
              initializedRequestLoadOptions.getLoadOptions(),
            );

            const groupItems = new groupItemsModel(response);

            resolve(groupItems.getItems());

            onLoadFinished(LoadOperations.groups);
          });
        }

        // If the loadOptions contain dataField, then the load method was called, to get the filter values for
        // the header filter or for the filter operations "is any of" or "is none of".
        // If the loadOptions don't contain dataField, then for example a reload of the entries,
        // a sorting or scrolling operation is happening.
        // For scrolling the loadOptions contain skip and take. For exports the loadOptions contain
        // isLoadingAll instead.
        if (!("dataField" in loadOptions)) {
          return new Promise(async (resolve) => {
            setLoading && setLoading(true);
            const response = await queryGridData(
              links.getEntries,
              initializedRequestLoadOptions.getLoadOptions(),
            );
            setLoading && setLoading(false);

            const entryItems = new entryItemsModel(response, loadOptions.skip);

            resolve(entryItems.getItems());

            onLoadFinished(LoadOperations.entries);

            onSetLatestLoadOptions && onSetLatestLoadOptions(loadOptions);

            // When a search filter was applied via URL and only one entry was found,
            // then automatically open this entry for edit.
            if (openSingleEntry.current) {
              if (entryItems.data.length === 1) {
                onOpenSingleEntry(entryItems.data[0].id);
              }
              openSingleEntry.current = false;
            }
          });
        } else {
          initializedRequestLoadOptions.field_id = loadOptions.dataField;
          return new Promise(async (resolve) => {
            const response = await queryGridData(
              links.getFilterValues,
              initializedRequestLoadOptions.getLoadOptions(),
            );

            const filterItems = new filterItemsModel(response);

            resolve(filterItems.getItems());

            onLoadFinished(LoadOperations.filterValues);
          });
        }
      },
      totalCount: () => {
        return 0;
      },
    });
  }, [
    entryItemsModel,
    filterItemsModel,
    groupItemsModel,
    links,
    onLoadFinished,
    onOpenSingleEntry,
    onSetLatestLoadOptions,
    requestLoadOptions,
    queryGridData,
    setLoading,
  ]);

  const customHeaderFilterTranslationFormatter = (options) => {
    options.dataSource.postProcess = function (results) {
      return results.map((result) => {
        result.text = t(result.text, { ns: "tasks" });
        return result;
      });
    };
  };

  // temporary solution https://gitlab.imes-solutions.com/plant-historian/web/client/-/issues/1085
  // is needed so that the "contains" filter is activated instead of the "=" filter
  const customFormatter = (options) => {
    options.dataSource.postProcess = function (results) {
      return results.map((result) => {
        return result;
      });
    };
  };

  const customHeaderFilterFormatter = (options) => {
    options.dataSource.postProcess = function (results) {
      const formattedResult = results.map((result) => {
        return { key: result.value.key, items: result.value.items };
      });
      return createGroupedFilterItemsForDateTime(
        formattedResult ? formattedResult : [],
        "",
      );
    };
  };

  // Creates filter item objects (for date, time or datetime columns) that the datagrid can handle.
  // Those filter items contain the key and the string, that is displayed by the datagrid.
  const createGroupedFilterItemsForDateTime = (filterValues, parentValue) => {
    const filterItems = [];
    if (filterValues != null) {
      filterValues.forEach((filterValue) => {
        const filterItem = {};
        filterItem.value =
          parentValue === ""
            ? String(filterValue.key).padStart(2, "0")
            : parentValue + "/" + String(filterValue.key).padStart(2, "0");
        filterItem.text = String(filterValue.key);
        if (filterValue.items != null) {
          filterItem.items = createGroupedFilterItemsForDateTime(
            filterValue.items,
            filterItem.value,
          );
        }
        filterItems.push(filterItem);
      });
    }
    return [...filterItems];
  };

  const enqueueProcessFilterErrorSnackbar = () => {
    enqueueSnackbar(t("processing-filter-error"), {
      variant: SnackbarAlertColour.ERROR,
      persist: true,
    });
  };

  const loadDataGridState = () => {
    // When the URL contains a filter, apply this filter to the datagrid.
    if (initialFilter?.current) {
      try {
        openSingleEntry.current = true;
        const filterValue = JSON.parse(initialFilter.current);
        initialFilter.current = undefined;
        if (!checkIfFieldsStillExist(filterValue)) {
          enqueueProcessFilterErrorSnackbar();
          return null;
        }
        return { filterValue: filterValue };
      } catch (e) {
        enqueueProcessFilterErrorSnackbar();
      }
    }

    if (!currentLayout || currentLayout.default) {
      previousLayout.current = currentLayout;
      return defaultFilter ? { filterValue: defaultFilter } : { columns: [] };
    }

    if (
      currentLayout &&
      !currentLayout.default &&
      currentLayout !== previousLayout.current
    ) {
      loadingBlocked.current = true;
      previousLayout.current = currentLayout;
      return new Promise(async (resolve) => {
        const response = await queryGridData(currentLayout._links.get.href);
        loadingBlocked.current = false;

        if (!response.error && response.data.layout) {
          if (!checkIfFieldsStillExist(response.data.layout)) {
            response.data.filter_value = [];
            enqueueProcessFilterErrorSnackbar();
            onSetCurrentLayout(null);
          }
          onSetCurrentLayoutData(response.data.layout);
          resolve(response.data.layout);
        }
        resolve(currentLayoutData);
      });
    }
  };

  const checkIfFieldsStillExist = (filter) => {
    if (!filter) return true;
    for (let i = 0; i + 1 < filter.length; i += 2) {
      if (Array.isArray(filter[i])) {
        if (!checkIfFieldsStillExist(filter[i])) return false;
      } else {
        if (
          !dataColumnDefinitions.find(
            (column) => column.id === parseInt(filter[i]),
          )
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const toolbarItems = useMemo(() => {
    const items = (toolbar ?? []).concat([
      { name: "exportButton", cssClass: "no-border-datagrid-button" },
      { name: "columnChooserButton", cssClass: "no-border-datagrid-button" },
      { name: "groupPanel", cssClass: "no-border-datagrid-button" },
    ]);
    if (stateStoring && onSetLayoutPopoverPosition) {
      items.push({
        location: "after",
        widget: "dxButton",
        cssClass: "no-border-datagrid-button",
        options: {
          icon: "tableproperties",
          hint: t("layouts"),
          text: t("layouts"),
          onClick: (event) =>
            onSetLayoutPopoverPosition(getElementPosition(event.event.target)),
        },
      });
    }
    return { items: items };
  }, [onSetLayoutPopoverPosition, stateStoring, t, toolbar]);

  /**
   * Since React 17, react-select will immediately close when used in the DataGrid since the focus change
   * closes the react-select dropdown. Setting e.cancel of the dataGrid event to true prevents this.
   */
  const onFocusedCellChanging = (e) => {
    e.cancel = true;
  };

  return (
    <>
      <DataGrid
        ref={dataGridRef}
        className={
          "table-responsive flex-row overflow-auto data-grid-border noMaxWidth data-grid-background " +
          (className ? className : "")
        }
        key={i18n.resolvedLanguage}
        dataSource={
          dataSourceType === DataSourceTypes.remote ? customStore : dataSource
        }
        showBorders={showBorders}
        columnAutoWidth={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        noDataText={noDataText}
        remoteOperations={remoteOperations}
        columnResizingMode={columnResizingMode}
        onExporting={onExporting}
        toolbar={toolbarItems}
        onRowPrepared={onRowPrepared}
        onCellPrepared={onCellPrepared}
        onFocusedCellChanging={onFocusedCellChanging}
        renderAsync={true}
        onSelectionChanged={onSelectionChanged}
        id={id || "DataGrid"}
        hoverStateEnabled={!!selectionProps}
      >
        {stateStoring && (
          <StateStoring
            enabled={true}
            type={"custom"}
            customLoad={loadDataGridState}
            customSave={onSetCurrentLayoutData}
            savingTimeout={500}
          />
        )}
        <Export enabled={true} />

        <ColumnChooser enabled={true} mode={"select"} />

        <FilterRow visible={filterRow !== undefined ? filterRow : true} />
        <FilterPanel visible={filterPanel !== undefined ? filterPanel : true} />
        <FilterBuilderPopup position={filterBuilderPopupPosition} />
        {getFilterBuilder()}
        <HeaderFilter
          visible={headerFilter !== undefined ? headerFilter : true}
        />

        <Grouping autoExpandAll={false} />
        <GroupPanel visible={grouping !== undefined ? grouping : true} />

        <Sorting mode={sortingMode ?? "multiple"} />
        <Scrolling
          mode="virtual"
          rowRenderingMode="virtual"
          showScrollbar="always"
        />
        {selectionProps && <Selection {...selectionProps} />}
        <Paging defaultPageSize={100} />

        <LoadPanel enabled={true} />

        {onReorder && (
          <RowDragging
            allowReordering={true}
            onReorder={onReorder}
            dragDirection="vertical"
            boundary=".dx-datagrid-rowsview"
          />
        )}
        {columnButtonDefinitions && (
          <Column
            key="buttons_column"
            allowReordering={false}
            type="buttons"
            fixed={true}
            fixedPosition="left"
            showInColumnChooser={false}
            allowResizing={true}
            // setting minWidth leads to the datagrid automatically calculating the minimal required width for the
            // column. String values lead to buggy behaviour (in version 23.2.4) and 0 is ignored, so we set it to 1.
            minWidth={1}
            cellRender={(props) => (
              <div
                className="d-flex justify-content-center gap-1"
                data-cy="DataGridActions"
              >
                {columnButtonDefinitions(props)}
              </div>
            )}
          />
        )}
        {lineNumberColumnEnabled && getLineNumberColumn()}
        {getDataColumns()}

        {summary}
      </DataGrid>
    </>
  );
});

DataGridWrapper.propTypes = {
  dataColumnDefinitions: PropTypes.arrayOf(
    PropTypes.instanceOf(DataGridDataColumnDefinitionModel),
  ),
  sortingSpecs: PropTypes.arrayOf(PropTypes.object),
  dataSource: PropTypes.arrayOf(PropTypes.object),
  showBorders: PropTypes.bool,
  noDataText: PropTypes.string,
  remoteOperations: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  columnResizingMode: PropTypes.string,
  columnButtonDefinitions: PropTypes.func,
  lineNumberColumnEnabled: PropTypes.bool,
  summary: PropTypes.element,
  requestLoadOptions: PropTypes.instanceOf(LoadOptionsModel),
  links: PropTypes.instanceOf(LinksModel),
  filterItemsModel: PropTypes.func,
  groupItemsModel: PropTypes.func,
  entryItemsModel: PropTypes.func,
  currentLayout: PropTypes.object,
  currentLayoutData: PropTypes.object,
  dataSourceType: PropTypes.string.isRequired,
  stateStoring: PropTypes.bool,
  onOpenSingleEntry: PropTypes.func,
  onLoadFinished: PropTypes.func,
  onCellPrepared: PropTypes.func,
  onSetCurrentLayoutData: PropTypes.func,
  onSetLayoutPopoverPosition: PropTypes.func,
  onExporting: PropTypes.func,
  filterRow: PropTypes.bool,
  filterPanel: PropTypes.bool,
  headerFilter: PropTypes.bool,
  grouping: PropTypes.bool,
  sortingMode: PropTypes.oneOf(["single", "multiple", "none"]),
  onSetLatestLoadOptions: PropTypes.func,
  onRowPrepared: PropTypes.func,
  defaultFilter: PropTypes.array,
  selectionProps: PropTypes.object,
  onSelectionChanged: PropTypes.func,
};

DataGridWrapper.defaultProps = {
  filterItemsModel: FilterItemsModel,
  onLoadFinished: () => {},
  groupItemsModel: GroupItemsModel,
  entryItemsModel: EntryItemsModel,
  stateStoring: false,
  onOpenSingleEntry: () => {},
};

// noinspection JSCheckFunctionSignatures
export default memo(DataGridWrapper);
