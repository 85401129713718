import { useComponent } from "@imes/module-host";

export const ENTRYMASK_COMPONENT = "ENTRYMASK_COMPONENT";
export const ENTRYMASK_NAV_ITEM = "ENTRYMASK_NAV_ITEM";
export const ENTRYMASK_ADMINISTRATION = "ENTRYMASK_ADMINISTRATION";
export const ENTRYMASK_SKELETON = "ENTRYMASK_SKELETON";
export const EntryMask = () => useComponent(ENTRYMASK_COMPONENT);
export const EntryMaskNavItem = (props) =>
  useComponent(ENTRYMASK_NAV_ITEM, props);
export const EntryMaskAdministration = () =>
  useComponent(ENTRYMASK_ADMINISTRATION);
export const EntryMaskSkeleton = () => useComponent(ENTRYMASK_SKELETON);

export const ENTRYMASK_GROUP_POPOVER = "ENTRYMASK_GROUP_POPOVER";
export const EMBEDDED_ENTRY_MASK_ENTRY_LIST = "EMBEDDED_ENTRY_MASK_ENTRY_LIST";
export const ENTRYMASK_ENTRYLINK = "ENTRYMASK_ENTRYLINK";
export const ENTRYMASK_ENTRY_SELECT = "ENTRYMASK_ENTRY_SELECT";
export const EntryMaskGroupPopover = (props) =>
  useComponent(ENTRYMASK_GROUP_POPOVER, props);
export const EmbeddedEntryMaskEntryList = (props) =>
  useComponent(EMBEDDED_ENTRY_MASK_ENTRY_LIST, props);
export const EntryMaskEntryLink = (props) =>
  useComponent(ENTRYMASK_ENTRYLINK, props);
export const EntryMaskEntrySelect = (props) =>
  useComponent(ENTRYMASK_ENTRY_SELECT, props);
