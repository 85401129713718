import React from "react";
import PropTypes from "prop-types";
import CustomizedSelect from "./CustomizedSelect";

export default function TextSelect(props) {
  const { onChange, isValid, disabled } = props;
  const value = props.value ? { label: props.value, value: props.value } : null;
  const values = props.values ? props.values : [];

  return (
    <CustomizedSelect
      value={value}
      options={values.map((option) => {
        return { value: option.value, label: option.value, id: option.id };
      })}
      onChange={(option) => onChange(option ? option : null)}
      disabled={disabled}
      validation={isValid}
      isClearable={props.isClearable}
    />
  );
}

TextSelect.propTypes = {
  isValid: PropTypes.shape({
    isValid: PropTypes.bool,
    isInvalid: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  values: PropTypes.array,
  isClearable: PropTypes.bool,
};
