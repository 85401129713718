import { assetApi } from "basics/services/assetApi";
import { useCallback } from "react";

export default function useResolveAssetName() {
  const { data: assets } = assetApi.endpoints.getAssets.useQueryState();
  const resolveAssetName = useCallback(
    (assetUUID, currentAssets = assets) => {
      if (!currentAssets) return assetUUID;
      for (const asset of currentAssets) {
        if (assetUUID === asset.guid) {
          return asset.name;
        } else if (asset.items) {
          const asset_name = resolveAssetName(assetUUID, asset.items);
          if (asset_name) {
            return asset_name;
          }
        }
      }
    },
    [assets],
  );
  return resolveAssetName;
}
