import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "basics/dynamicBaseQuery";
import { metaApi } from "basics/services/metaApi";
import { createSocketConnector } from "basics/createSocketConnector";
import { transformTask } from "shiftbook/modules/tasks/utils/taskUtils";

const tasksSocketConnector = createSocketConnector("/tasks", {
  errorMessage: {
    message: "tasks-socket-connect-error",
    ns: "tasks",
  },
  successMessage: {
    message: "tasks-socket-reconnect-success",
    ns: "tasks",
  },
});

const tasksAdapter = createEntityAdapter({ selectId: (task) => task.guid });
const initialState = tasksAdapter.getInitialState();

export const tasksApi = createApi({
  reducerPath: "tasksApi",
  baseQuery: dynamicBaseQuery,
  tagTypes: ["Tasks", "Task", "UnreadTaskCount"],
  endpoints: (build) => ({
    getTasksUris: build.query({
      query: () => ({
        path: "tasks.href",
        endpoint: metaApi.endpoints.getBaseUris,
      }),
    }),
    getTasks: build.query({
      query: ({ params }) => ({
        path: "_links.tasks.get.href",
        endpoint: tasksApi.endpoints.getTasksUris,
        params: params,
        token: "access",
      }),
      providesTags: ["Tasks"],
      transformResponse: (baseQueryReturnValue) =>
        tasksAdapter.setAll(initialState, baseQueryReturnValue.tasks),
      onCacheEntryAdded: async (arg, api) => {
        const listener = () =>
          api.dispatch(tasksApi.util.invalidateTags(["Tasks"]));
        try {
          await api.cacheDataLoaded;
          await tasksSocketConnector.on("changed", listener);
        } catch {}
        await api.cacheEntryRemoved;
        tasksSocketConnector.off("changed", listener);
      },
    }),
    getTask: build.query({
      query: ({ guid }) => ({
        path: "_links.tasks.get.href",
        endpoint: tasksApi.endpoints.getTasksUris,
        urlSuffix: guid,
        token: "access",
      }),
      providesTags: (result) =>
        result
          ? [{ type: "Task", id: tasksAdapter.selectId(result) }]
          : ["Task"],
      keepUnusedDataFor: 0,
      // onCacheEntryAdded: async (arg, api) => {
      //   const listener = () =>
      //     // todo: Local changes should not be overwritten without interaction with the user.
      //     //  https://gitlab.imes-solutions.com/plant-historian/web/client/-/issues/27
      //     api.dispatch(tasksApi.util.invalidateTags(["Task"]));
      //   try {
      //     await api.cacheDataLoaded;
      //     await tasksSocketConnector.on("changed", listener);
      //   } catch {}
      //   await api.cacheEntryRemoved;
      //   tasksSocketConnector.off("changed", listener);
      // },
    }),
    addTask: build.mutation({
      query: ({ body }) => ({
        path: "_links.tasks.add.href",
        endpoint: tasksApi.endpoints.getTasksUris,
        body: body,
        method: "POST",
        token: "access",
      }),
      invalidatesTags: ["Tasks"],
    }),
    editTask: build.mutation({
      query: ({ task, body }) => ({
        url: task._links.edit.href,
        body: body,
        method: "PUT",
        token: "access",
      }),
      invalidatesTags: (result, error, arg) => [
        "Tasks",
        { type: "Task", id: tasksAdapter.selectId(arg.task) },
      ],
    }),
    changeTask: build.mutation({
      query: ({ task, body, meta }) => ({
        url: task._links[meta.transition].href,
        body: body,
        method: "POST",
        token: "access",
      }),
      invalidatesTags: (result, error, arg) => [
        "Tasks",
        { type: "Task", id: arg.task.guid },
      ],
    }),
    getUnreadTaskCount: build.query({
      query: () => ({
        path: "_links.tasks.get_unread_tasks_count.href",
        endpoint: tasksApi.endpoints.getTasksUris,
        token: "access",
      }),
      providesTags: ["UnreadTaskCount"],
      onCacheEntryAdded: async (arg, api) => {
        const listener = () =>
          api.dispatch(tasksApi.util.invalidateTags(["UnreadTaskCount"]));
        try {
          await api.cacheDataLoaded;
          await tasksSocketConnector.on("changed", listener);
        } catch {}
        await api.cacheEntryRemoved;
        tasksSocketConnector.off("changed", listener);
      },
    }),
    getTaskInfos: build.query({
      query: () => ({
        path: "_links.tasks.info.href",
        endpoint: tasksApi.endpoints.getTasksUris,
        token: "access",
      }),
      transformResponse: (baseQueryReturnValue) =>
        tasksAdapter.setAll(initialState, baseQueryReturnValue.tasks_info),
      providesTags: ["Tasks"],
      keepUnusedDataFor: 0,
    }),
    addTaskComment: build.mutation({
      query: ({ task, body }) => ({
        url: task._links.comment.href,
        body: body,
        method: "POST",
        token: "access",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Task", id: arg.guid }],
    }),
    addTaskCommentReply: build.mutation({
      query: ({ comment, body }) => ({
        url: comment._links.reply.href,
        body: body,
        method: "POST",
        token: "access",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Task", id: arg.meta?.task?.guid },
      ],
    }),
    setTaskRead: build.mutation({
      query: ({ task }) => ({
        url: task._links.mark_as_read.href,
        method: "POST",
        token: "access",
      }),
      onQueryStarted: async (arg, api) => {
        try {
          await api.queryFulfilled;

          if (!arg.task.was_read) {
            api.dispatch(
              tasksApi.util.updateQueryData(
                "getTask",
                { guid: arg.task.guid },
                (draft) => {
                  draft.was_read = true;
                },
              ),
            );

            api.dispatch(
              tasksApi.util.updateQueryData(
                "getUnreadTaskCount",
                undefined,
                (draft) => draft - 1,
              ),
            );
          }
        } catch {}
      },
    }),
    reorderTask: build.mutation({
      query: ({ task, body }) => ({
        url: task._links.reorder.href,
        body: body,
        method: "POST",
        token: "access",
      }),
      invalidatesTags: ["Tasks", "Task"],
    }),
    getSettings: build.query({
      query: () => ({
        path: "_links.settings.href",
        endpoint: tasksApi.endpoints.getTasksUris,
        token: "access",
      }),
    }),
  }),
});

tasksSocketConnector.endpoint = tasksApi.endpoints.getTasksUris;

export const {
  useGetTasksUrisQuery,
  useGetTasksQuery,
  useGetTaskQuery,
  useGetUnreadTaskCountQuery,
  useGetTaskInfosQuery,
  useAddTaskCommentMutation,
  useAddTaskCommentReplyMutation,
  useAddTaskMutation,
  useEditTaskMutation,
  useSetTaskReadMutation,
  useChangeTaskMutation,
  useReorderTaskMutation,
  useGetSettingsQuery,
} = tasksApi;

export const tasksSelectors = tasksAdapter.getSelectors();

export const selectUploadTaskFilesUrlFromResult = (res) =>
  res._links.media.upload_files.task.href;
export const selectUploadRecurringTaskFilesUrlFromResult = (res) =>
  res._links.media.upload_files.recurring_task.href;

export const selectTransformedTaskFromResult = createSelector(
  [(res) => res],
  transformTask,
);
