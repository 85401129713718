import store from "config/store";
import { authApi } from "basics/services/authApi";
import _ from "lodash";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { alphanumericSortingCompare } from "public_basics/alphanumericSort";

// Utility functions for converting the keys of (nested) objects from snake_case to camelCase.
// Adapted from https://matthiashager.com/converting-snake-case-to-camel-case-object-keys-with-javascript
const toCamel = (s) => {
  return s.replace(/(?<!^)([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
};

// TODO: Currently used to avoid having to rename all fields in the AddEntryDialog and its children
//  The AddEntryDialogContainer will pass in converted objects to the AddEntryDialog using this function
//  Consider renaming all attributes and removing this helper again.
//  Issue: https://gitlab.imes-solutions.com/plant-historian/web/client/-/issues/1016
export const keysToCamel = function (o) {
  if (_.isArray(o)) return o.map(keysToCamel);

  if (_.isPlainObject(o))
    return _.mapValues(
      _.mapKeys(o, (value, key) => toCamel(key)),
      keysToCamel,
    );

  return o;
};

// rtk query matching utility
export const isAnyOfEndpoints =
  (...endpoints) =>
  (action) => {
    const endpointName = action?.meta?.arg?.endpointName;
    return (
      !!endpointName &&
      endpoints.some((endpoint) => endpoint.name === endpointName)
    );
  };

export const getUserFromUUID = (uuid) => {
  const state = store.getState();
  const { data: users } = authApi.endpoints.getUsers.select()(state);
  return users.find((user) => user.guid.toLowerCase() === uuid);
};

export const createUuidAlphanumericSortEntityAdapter = () =>
  createEntityAdapter({
    selectId: (entity) => entity.uuid,
    sortComparer: alphanumericSortingCompare,
  });

export const uuidRegex = new RegExp(
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g,
);
