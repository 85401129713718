import { OverlayTrigger, Popover } from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";

function ConditionalOverlay(props) {
  const {
    overlayActive,
    overlayDescription,
    overlayPlacement = "left",
    showDelay = 250,
    hideDelay = 500,
    trigger = ["hover", "focus", "click"],
  } = props;

  const descriptionPopover = (
    <Popover id={"popover-basic"} data-cy={"FieldDescriptionPopup"}>
      <Popover.Body>{overlayDescription}</Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger={overlayActive ? trigger : []}
      show={overlayActive ? undefined : false}
      delay={{ show: showDelay, hide: hideDelay }}
      overlay={descriptionPopover}
      placement={overlayPlacement}
    >
      {props.children}
    </OverlayTrigger>
  );
}

ConditionalOverlay.propTypes = {
  overlayActive: PropTypes.bool.isRequired,
  overlayDescription: PropTypes.string,
  overlayPlacement: PropTypes.oneOf([
    "auto-start",
    "auto",
    "auto-end",
    "top-start",
    "top",
    "top-end",
    "right-start",
    "right",
    "right-end",
    "bottom-end",
    "bottom",
    "bottom-start",
    "left-end",
    "left",
    "left-start",
  ]),
  showDelay: PropTypes.number,
  hideDelay: PropTypes.number,
  trigger: PropTypes.arrayOf(PropTypes.string),
};
export default ConditionalOverlay;
