import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "basics/dynamicBaseQuery";
import { metaApi } from "basics/services/metaApi";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getAuthUris: builder.query({
      query: () => ({
        endpoint: metaApi.endpoints.getBaseUris,
        path: "authentication.href",
      }),
      serializeQueryArgs: () => "AuthUris",
    }),
    login: builder.mutation({
      query: ({ body }) => ({
        endpoint: authApi.endpoints.getAuthUris,
        path: "_links.auth.login.href",
        body: body,
        method: "POST",
      }),
      serializeQueryArgs: () => "User",
    }),
    confirmCredentials: builder.mutation({
      query: ({ body }) => ({
        endpoint: authApi.endpoints.getAuthUris,
        path: "_links.auth.login.href",
        body: body,
        method: "POST",
      }),
      transformErrorResponse(errorResponse, meta, arg) {
        if (arg.meta?.skipErrorListener) {
          return;
        }
        return errorResponse;
      },
    }),
    logout: builder.mutation({
      query: () => ({
        endpoint: authApi.endpoints.getAuthUris,
        path: "_links.auth.logout.href",
        method: "POST",
        token: "refresh",
      }),
    }),
    getPasswordCriteria: builder.query({
      query: () => ({
        endpoint: authApi.endpoints.getAuthUris,
        path: "_links.auth.criteria.href",
        token: "access",
      }),
    }),
    changePassword: builder.mutation({
      query: ({ body }) => ({
        endpoint: authApi.endpoints.getAuthUris,
        path: "_links.auth.renew.href",
        body: body,
        method: "POST",
      }),
    }),
    authenticateSSOToken: builder.mutation({
      query: ({ params }) => ({
        endpoint: authApi.endpoints.getAuthUris,
        path: "_links.auth.sso.authenticate.href",
        params: params,
        credentials: "include",
      }),
    }),
    refreshToken: builder.mutation({
      query: () => ({
        endpoint: authApi.endpoints.getAuthUris,
        path: "_links.auth.refresh.href",
        token: "refresh",
        method: "POST",
      }),
    }),
    getUsers: builder.query({
      query: () => ({
        endpoint: authApi.endpoints.getAuthUris,
        path: "_links.users.href",
        token: "access",
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue.users.map((user) => ({
          ...user,
          guid: user.guid.toLowerCase(),
        }));
      },
    }),
    getUserGroups: builder.query({
      query: () => ({
        endpoint: authApi.endpoints.getAuthUris,
        path: "_links.user_groups.href",
        token: "access",
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue.user_groups.map((user_group) => ({
          ...user_group,
          guid: user_group.guid.toLowerCase(),
        }));
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useConfirmCredentialsMutation,
  useGetPasswordCriteriaQuery,
  useChangePasswordMutation,
  useAuthenticateSSOTokenMutation,
  useGetAuthUrisQuery,
  useGetUsersQuery,
  useGetUserGroupsQuery,
} = authApi;
