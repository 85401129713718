import React from "react";
import { Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import PrivilegeRequiredWrapper from "./PrivilegeRequiredWrapper";
import { css } from "@emotion/css";
import Privileges from "public_basics/constants/Privileges";

const dataGridBorder = css`
  border: 1px solid var(--bs-gray-data-grid);
  border-radius: 3px;

  td {
    border: none;
    border-left: 1px solid var(--bs-gray-data-grid);
    border-right: 1px solid var(--bs-gray-data-grid);
  }

  th {
    border-top: none;
    border-right: 1px solid var(--bs-gray-data-grid);
    border-left: 1px solid var(--bs-gray-data-grid);
    border-bottom: 1px solid var(--bs-gray-data-grid);
  }

  tbody {
    border-top: none !important;
  }

  th:first-child {
    border-left: none;
  }

  td:first-child {
    border-left: none;
  }

  tr:first-child {
    border-top: none;
  }

  tr:last-child {
    border-bottom: none;
  }

  td:last-child {
    border-right: none;
  }

  th:last-child {
    border-right: none;
  }
`;

function SkeletonDataGrid(props) {
  function getTableHeader(columns) {
    const headers = [];
    _.times(columns, (index) =>
      headers.push(
        <th key={index}>
          <Skeleton />
        </th>,
      ),
    );
    return headers;
  }

  function getTableData(columns) {
    const data = [];
    _.times(columns, (index) =>
      data.push(
        <td key={index}>
          <Skeleton />
        </td>,
      ),
    );
    return data;
  }

  function getTableRows(columns, rows) {
    const tableRows = [];
    _.times(rows, (index) =>
      tableRows.push(
        <tr key={index}>
          <td width={"65"} className={"text-center"}>
            <div className={"d-flex"}>
              <Skeleton
                circle={true}
                height={20}
                width={20}
                className={"ms-1 me-2"}
              />
              <PrivilegeRequiredWrapper privilege={Privileges.DELETE_ENTRIES}>
                <Skeleton circle={true} height={20} width={20} />
              </PrivilegeRequiredWrapper>
            </div>
          </td>
          {getTableData(columns)}
        </tr>,
      ),
    );
    return tableRows;
  }

  return (
    <section
      className={
        dataGridBorder + (props.className ? " " + props.className : "")
      }
    >
      <Table size="sm">
        <thead>
          <tr>
            <th />
            {getTableHeader(props.columns)}
          </tr>
        </thead>
        <tbody>{getTableRows(props.columns, props.rows)}</tbody>
      </Table>
    </section>
  );
}

export default SkeletonDataGrid;
