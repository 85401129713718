import { Nav } from "react-bootstrap";
import styles from "./TabbedCustomPopover.module.scss";
import TabbedCustomPopoverTabLink from "./TabbedCustomPopoverTabLink";
import React from "react";
import CustomPopoverHeader from "../CustomPopover/CustomPopoverHeader";
import PropTypes from "prop-types";
import itemsPropType from "./itemsPropType";

export default function TabbedCustomPopoverHeader(props) {
  return (
    <CustomPopoverHeader title={props.title} classNames={props.className}>
      <Nav
        bsPrefix={styles.tabbedCustomPopoverHeader}
        data-cy="customPopoverTabs"
      >
        {props.items.map((item) => (
          <TabbedCustomPopoverTabLink
            eventKey={item.key}
            key={item.key}
            title={item.title}
          />
        ))}
      </Nav>
    </CustomPopoverHeader>
  );
}

TabbedCustomPopoverHeader.propTypes = {
  items: itemsPropType,
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
