import { useGetEntryMasksQuery } from "entrymask/services/entryMaskApi";

export default function useGetEntryMaskById(path, id) {
  return useGetEntryMasksQuery(path, {
    selectFromResult: (resp) => ({
      entryMask: resp.data?.find((mask) => mask.id === id),
      ...resp,
    }),
  });
}
