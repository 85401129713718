import { css } from "@emotion/css";
import { PositionedDropdown } from "public_basics/components";
import { ListGroup } from "react-bootstrap";
import EntryMaskListItem from "./EntryMaskList/components/EntryMaskListItem";
import useSelectedEntryMaskId from "../customHooks/useSelectedEntryMaskId";

export default function EntryMaskGroupPopover(props) {
  const selectedEntryMaskId = useSelectedEntryMaskId(
    "entry-masks/:entryMaskId/*",
  );

  const getDropdownItems = (entryMasks) => {
    return entryMasks.map((entryMask) => {
      const isSelected = entryMask.id === selectedEntryMaskId;
      return (
        <EntryMaskListItem
          entryMask={entryMask}
          key={"groupPopoverEntryMaskListItem-" + entryMask.id}
          isSelected={isSelected}
          onClick={props.onClose}
          to={"entry-masks/" + entryMask.id}
          className={
            "dropdown-item " +
            css`
              border: none !important;
            `
          }
        />
      );
    });
  };

  return (
    <PositionedDropdown
      position={props.settings?.position}
      onClose={props.onClose}
    >
      <ListGroup data-cy={"entryMaskGroupPopover"}>
        {props.settings?.entryMasks &&
          getDropdownItems(props.settings?.entryMasks)}
      </ListGroup>
    </PositionedDropdown>
  );
}
