import { v4 } from "uuid";
import React from "react";
import PropTypes from "prop-types";

export default function DynamicIcon(props) {
  if (props.parts) {
    return (
      <svg
        height={props.size}
        width={props.size}
        fill={"currentcolor"}
        viewBox={"0 0 16 16"}
        role={"img"}
        color={props.color}
        className={props.className}
        aria-hidden={"true"}
      >
        {props.parts.map((part) => {
          return <path d={part} key={v4()} />;
        })}
      </svg>
    );
  }
  return null;
}

DynamicIcon.defaultProps = {
  size: 32,
};

DynamicIcon.propTypes = {
  parts: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.number,
  color: PropTypes.string,
};
