import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "basics/dynamicBaseQuery";
import { useDispatch } from "react-redux";

export const dataGridApi = createApi({
  reducerPath: "dataGridApi",
  // Set to 0 to prevent caching of response data
  // Search for "rtk-grid-cache" to find corresponding code in dataGridApi
  keepUnusedDataFor: 0,
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getData: builder.query({
      query: ({ url, params }) => ({
        token: "access",
        url: url,
        params: params,
      }),
    }),
  }),
});

export const useQueryGridData = () => {
  const dispatch = useDispatch();

  return async (url, loadOptions = undefined) => {
    const promise = dispatch(
      dataGridApi.endpoints.getData.initiate({
        url: url,
        params: loadOptions,
      }),
    );
    const response = await promise;

    // Unsubscribe to prevent caching of response data
    // Search for "rtk-grid-cache" to find corresponding code in dataGridApi
    promise.unsubscribe();

    if (response.error) return null;
    return response;
  };
};
