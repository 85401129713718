import React from "react";
import { Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CheckedListEntry from "./CheckedListEntry";
import PropTypes from "prop-types";
import {
  getNotRequired,
  getNumberAdditionalRequiredCharsets,
  getRequired,
} from "./passwordCriteriaFunction";
import { useGetPasswordCriteriaQuery } from "../../services/authApi";

export default function PasswordCriteriaInformation(props) {
  const { checkedCriteria } = props;

  const { data: passwordCriteria, isLoading } = useGetPasswordCriteriaQuery();

  const { t } = useTranslation("basics");

  const requiredPasswordCriteria = getRequired(passwordCriteria);
  const optionalPasswordCriteria = getNotRequired(passwordCriteria);
  const numRequiredAdditionalCharsets =
    getNumberAdditionalRequiredCharsets(passwordCriteria);

  const getRequiredCriteria = () => {
    return requiredPasswordCriteria.map((attribute) => (
      <CheckedListEntry key={attribute} checked={checkedCriteria[attribute]}>
        {t(attribute)}
      </CheckedListEntry>
    ));
  };

  const getAdditionalCharsets = () => {
    return optionalPasswordCriteria.map((attribute) => (
      <CheckedListEntry key={attribute} checked={checkedCriteria[attribute]}>
        {t(attribute)}
      </CheckedListEntry>
    ));
  };
  const additionalList = numRequiredAdditionalCharsets > 0 && (
    <CheckedListEntry checked={checkedCriteria.num_of_unique_charsets}>
      {t("additional-free-criteria", {
        count: numRequiredAdditionalCharsets,
        requiredCharsets: numRequiredAdditionalCharsets,
        availableCharsets: optionalPasswordCriteria.length,
      })}
      <ul className={"ps-4"}>{getAdditionalCharsets()}</ul>
    </CheckedListEntry>
  );

  return (
    <div className="d-flex">
      {isLoading ? (
        <Spinner className={"ms-2"} size="sm" />
      ) : (
        <Form.Text className={"m-0 p-0"} data-cy={"PasswordCriteriaBlock"}>
          {t("password-complexity-header")}
          <div className={"d-flex"}>
            <ul className={"mb-0 ps-4"}>
              <CheckedListEntry
                key={"min_length"}
                checked={checkedCriteria.min_length}
              >
                {t("minimum-password-length", {
                  min_length: passwordCriteria?.min_length,
                })}
              </CheckedListEntry>
              {getRequiredCriteria()}
              {additionalList}
            </ul>
          </div>
        </Form.Text>
      )}
    </div>
  );
}

PasswordCriteriaInformation.propTypes = {
  checkedCriteria: PropTypes.object.isRequired,
};
