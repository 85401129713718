import React, { useState } from "react";
import PropTypes from "prop-types";
import DevextremeIcon from "../../primitives/DevextremeIcon";
import { ListGroupItem } from "react-bootstrap";
import { css } from "@emotion/css";
import {
  DynamicIcon,
  IconButton,
  RelevantEntriesCount,
} from "public_basics/components";
import LayoutPopover from "../../Layouts/LayoutPopover";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentLayout,
  setCurrentLayout,
} from "../../../slices/currentLayoutSlice";
import { Link, useNavigate } from "react-router-dom";

export default function EntryMaskListItem(props) {
  const layoutButtonId = "layout-button";
  const [popoverPosition, setPopoverPosition] = useState();
  const dispatch = useDispatch();
  const currentLayout = useSelector((state) =>
    selectCurrentLayout(state, props.entryMask),
  );
  const navigate = useNavigate();

  const getClassName = (selected) => {
    const baseClassName =
      "user-select-none " +
      css`
        cursor: pointer;
        padding-right: 4rem !important;
        word-wrap: anywhere;
      `;
    if (selected) {
      return baseClassName + " active";
    }
    return baseClassName;
  };

  const getElementPosition = (element) => {
    const elementClientRect = element.getBoundingClientRect();
    return {
      top: elementClientRect.bottom,
      left: elementClientRect.x,
    };
  };

  return (
    <>
      {popoverPosition && (
        <LayoutPopover
          position={popoverPosition}
          onClose={() => setPopoverPosition(null)}
          currentLayout={currentLayout}
          onSetCurrentLayout={(layout) => {
            dispatch(setCurrentLayout({ layout, id: props.entryMask.guid }));
            navigate(props.to);
          }}
          entity={props.entryMask}
        />
      )}
      <ListGroupItem
        data-cy={"bookListEntryMask-" + props.entryMask.name}
        className={
          props.className
            ? props.className + " " + getClassName(props.isSelected)
            : getClassName(props.isSelected)
        }
        as={Link}
        to={props.to}
        action
        onClick={props.onClick}
      >
        <DynamicIcon
          parts={props.entryMask.icon}
          size={24}
          className={"me-2"}
        />
        {props.entryMask.name}
        <RelevantEntriesCount
          relevantEntriesCount={props.entryMask.relevant_entries_count}
        />
        <IconButton
          className={css`
            top: 50%;
            right: 16px;
            position: absolute;
            transform: translateY(-50%);
          `}
          variant={props.isSelected ? "light" : null}
          id={layoutButtonId}
          onClick={(event) => {
            setPopoverPosition(getElementPosition(event.target));
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          <DevextremeIcon icon={"tableproperties"} id={layoutButtonId} />
        </IconButton>
      </ListGroupItem>
    </>
  );
}

EntryMaskListItem.propTypes = {
  entryMask: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};
