import getDecimalSeparator from "public_basics/getDecimalSeparator";
import parseNumber from "public_basics/parseNumber";
import formatNumber from "public_basics/formatNumber";

const formatNumberInput = (
  value,
  useGrouping = true,
  existingParsedValue = null,
) => {
  if (typeof value === "number") value = formatNumber(value, useGrouping);
  if (!value) return value;

  if (value === "-" || value === "+") {
    return value;
  }
  let postfix = "";
  const decimalSign = getDecimalSeparator();
  const parsedValue = existingParsedValue || parseNumber(value);
  if (value.at(-1) === decimalSign) {
    // We have to check if the user tried to enter two decimal signs. Only one is allowed.
    const valueWithoutNewDecimalSign = value.slice(0, value.length - 1);
    if (
      valueWithoutNewDecimalSign.at(-1) === decimalSign ||
      !valueWithoutNewDecimalSign.includes(decimalSign)
    )
      postfix = value.at(-1);
  }
  // All trailing zeros should stay in the input field.
  else if (value.includes(decimalSign) && value.at(-1) === "0") {
    let numberOfTrailingChars = 0;
    for (let i = 1; i <= value.length; i++) {
      if (value.at(-i) === "0" || value.at(-i) === "-") {
        numberOfTrailingChars++;
        continue;
      } else if (value.at(-i) === decimalSign) {
        numberOfTrailingChars++;
        if (
          value.at(0) !== "0" &&
          (value.at(0) !== "-" || value.at(1) !== "0")
        ) {
          break;
        }
        continue;
      }
      break;
    }
    postfix = value.slice(value.length - numberOfTrailingChars, value.length);
    if (parsedValue === 0) {
      return postfix;
    }
  }
  if (postfix && value.length === 1) return postfix;
  if (isNaN(parsedValue)) return "";
  return formatNumber(parsedValue, useGrouping) + postfix;
};

export default formatNumberInput;
