import { useEffect, useState } from "react";
import { throttle } from "lodash";

const useViewPort = () => {
  const [brkPnt, setBrkPnt] = useState(() => {
    return { width: window.innerWidth, height: window.innerHeight };
  });

  useEffect(() => {
    const calcView = throttle(function () {
      setBrkPnt({ width: window.innerWidth, height: window.innerHeight });
    }, 200);
    window.addEventListener("resize", calcView);
    return () => window.removeEventListener("resize", calcView);
  }, []);

  return brkPnt;
};

export default useViewPort;
