import React from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";

export default function TransitionAlert(props) {
  return (
    <Alert variant={props.severity} show={props.show}>
      {props.value}
    </Alert>
  );
}

TransitionAlert.propTypes = {
  show: PropTypes.bool,
  severity: PropTypes.oneOf(["error", "warning", "info", "success"]),
  value: PropTypes.string,
};
