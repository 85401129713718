import { ModuleModel } from "@imes/module-host";
import EntryMaskSkeleton from "entrymask/components/EntryMaskSkeleton";
import Privileges from "public_basics/constants/Privileges";
import {
  EMBEDDED_ENTRY_MASK_ENTRY_LIST,
  ENTRYMASK_ADMINISTRATION,
  ENTRYMASK_COMPONENT,
  ENTRYMASK_ENTRY_SELECT,
  ENTRYMASK_ENTRYLINK,
  ENTRYMASK_GROUP_POPOVER,
  ENTRYMASK_NAV_ITEM,
  ENTRYMASK_SKELETON,
} from "public_entrymask/components";
import { ENTRYMASK } from "public_entrymask/info";
import { lazy } from "react";
import EntryMaskGroupPopover from "./components/EntryMaskGroupPopover";
import EntryMaskEntryLink from "./components/EntryMaskEntryLink";
import NavItem from "./components/NavItem";
import EntryMaskEntrySelect from "./components/EntryMaskEntrySelect";

import { entryMaskApi } from "./services/entryMaskApi";
import { layoutsApi } from "./services/layoutsApi";
import currentLayoutSlice from "./slices/currentLayoutSlice";
import entryMaskReportSlice from "./slices/entrymaskReportSlice";
import copyEntrySlice from "./slices/copyEntrySlice";
import entrymaskViewSlice from "./slices/entrymaskViewSlice";

const EntryMask = lazy(() => import("entrymask/EntryMask"));
const EmbeddedEntryMaskEntryList = lazy(
  () =>
    import(
      "entrymask/components/EntryMaskEntryList/EmbeddedEntryMaskEntryList"
    ),
);
const Administration = lazy(
  () => import("entrymask/components/Administration/Administration"),
);

const entryMaskModule = new ModuleModel(ENTRYMASK);

entryMaskModule.slices = [
  entryMaskApi,
  layoutsApi,
  currentLayoutSlice,
  entryMaskReportSlice,
  copyEntrySlice,
  entrymaskViewSlice,
];
entryMaskModule.middlewares = [entryMaskApi.middleware, layoutsApi.middleware];
entryMaskModule.publicComponents = {
  [ENTRYMASK_COMPONENT]: EntryMask,
  [ENTRYMASK_NAV_ITEM]: NavItem,
  [ENTRYMASK_ADMINISTRATION]: Administration,
  [ENTRYMASK_GROUP_POPOVER]: EntryMaskGroupPopover,
  [EMBEDDED_ENTRY_MASK_ENTRY_LIST]: EmbeddedEntryMaskEntryList,
  [ENTRYMASK_SKELETON]: EntryMaskSkeleton,
  [ENTRYMASK_ENTRYLINK]: EntryMaskEntryLink,
  [ENTRYMASK_ENTRY_SELECT]: EntryMaskEntrySelect,
};

entryMaskModule.requiredPrivileges = [Privileges.BOOK];

export default entryMaskModule;
