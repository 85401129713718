import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { forwardRef } from "react";

const ChipRoot = styled("div")(({ theme, filled, size, clickable }) => ({
  "--bs-bg-opacity": 0.125,
  maxWidth: "100%",
  fontSize: ".9rem",
  display: "inline-flex",
  alignItems: "center",
  borderRadius: "var(--bs-border-radius-pill)",
  height: 32,
  ...(size === "small" && {
    height: 24,
  }),
  ...(clickable && {
    cursor: "pointer",
    transition: "background-color .15s ease-in-out",
    [`&:hover`]: {
      "--bs-bg-opacity": 0.2,
    },
    [`&:active`]: {
      "--bs-bg-opacity": 0.3,
    },
  }),
  ...(filled &&
    theme?.color && {
      backgroundColor: `rgba(var(${theme.color}), var(--bs-bg-opacity))`,
    }),
  // needed for svg to keep its width in case of an overflow
  [`& div`]: {
    display: "flex",
  },
  [`& svg`]: {
    height: 18,
    width: 18,
    ...(size === "small" && {
      height: 16,
      width: 16,
    }),
    ...(theme?.color && {
      color: `rgb(var(${theme.color}))`,
    }),
    marginLeft: 5,
    marginRight: -4,
  },
  [`& div:last-child svg`]: {
    marginLeft: -6,
    marginRight: 5,
  },
}));

const ChipLabel = styled("span")(({ size }) => ({
  paddingLeft: 12,
  paddingRight: 12,
  ...(size === "small" && {
    paddingLeft: 8,
    paddingRight: 8,
  }),
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const Chip = forwardRef(function Chip(props, ref) {
  const { label, startIcon, endIcon, onClick, size, color, filled, ...rest } =
    props;

  return (
    <ChipRoot
      theme={{ color: color }}
      filled={filled}
      size={size}
      clickable={!!onClick}
      ref={ref}
      onClick={onClick}
      title={label}
      {...rest}
    >
      {startIcon && <div>{startIcon}</div>}
      <ChipLabel size={size}>{label}</ChipLabel>
      {endIcon && <div>{endIcon}</div>}
    </ChipRoot>
  );
});

Chip.propTypes = {
  color: PropTypes.string,
  filled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  onClick: PropTypes.func,
};
export default Chip;
