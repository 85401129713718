import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  copyTargetId: null,
  mappedAssignments: null,
  copyComment: false,
};

const copyEntrySlice = createSlice({
  name: "copyEntrySlice",
  initialState: initialState,
  reducers: {
    setCopyTargetId(state, action) {
      state.copyTargetId = action.payload;
    },
    setMappedAssignments(state, action) {
      state.mappedAssignments = action.payload;
    },
    setCopyComment(state, action) {
      state.copyComment = action.payload;
    },
  },
  selectors: {
    selectCopyTargetId: (state) => state.copyTargetId,
    selectMappedAssignments: (state) => state.mappedAssignments,
    selectCopyComment: (state) => state.copyComment,
  },
});

export const { setCopyTargetId, setMappedAssignments, setCopyComment } =
  copyEntrySlice.actions;
export const {
  selectCopyTargetId,
  selectMappedAssignments,
  selectCopyComment,
} = copyEntrySlice.selectors;
export default copyEntrySlice;
