const TaskPositionTypes = {
  ABOVE: { value: 0, label: "above" },
  BELOW: { value: 1, label: "below" },
  FIRST: { value: 2, label: "first" },
  LAST: { value: 3, label: "last" },
};

Object.freeze(TaskPositionTypes);

export default TaskPositionTypes;
