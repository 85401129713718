import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "basics/dynamicBaseQuery";
import { metaApi } from "basics/services/metaApi";

export const assetApi = createApi({
  reducerPath: "assetApi",
  baseQuery: dynamicBaseQuery,
  tagTypes: ["Assets", "ActiveAssets", "AssetTypes"],
  endpoints: (builder) => ({
    getAssetUris: builder.query({
      query: () => ({
        endpoint: metaApi.endpoints.getBaseUris,
        path: "assets.href",
      }),
    }),
    getAssets: builder.query({
      query: () => ({
        path: "_links.assets.get.href",
        token: "access",
        endpoint: assetApi.endpoints.getAssetUris,
      }),
      providesTags: ["Assets", "ActiveAssets"],
    }),
    getAdminAssets: builder.query({
      query: () => ({
        path: "_links.assets.get_all.href",
        token: "access",
        endpoint: assetApi.endpoints.getAssetUris,
      }),
      providesTags: ["Assets"],
    }),
    getAssetTypes: builder.query({
      query: () => ({
        path: "_links.assets.get_types.href",
        token: "access",
        endpoint: assetApi.endpoints.getAssetUris,
      }),
      providesTags: ["AssetTypes"],
    }),
    setAssetTypeActive: builder.mutation({
      query: ({ assetType, body }) => ({
        url: assetType._links.set_active.href,
        token: "access",
        body: body,
        method: "PUT",
      }),
      invalidatesTags: ["AssetTypes", "ActiveAssets"],
    }),
    addAsset: builder.mutation({
      query: ({ body }) => ({
        path: "_links.assets.get.href",
        endpoint: assetApi.endpoints.getAssetUris,
        token: "access",
        body: body,
        method: "POST",
      }),
      invalidatesTags: ["Assets"],
    }),
    editAsset: builder.mutation({
      query: ({ asset, body }) => ({
        url: asset._links.self.href,
        token: "access",
        body: body,
        method: "PUT",
      }),
      invalidatesTags: ["Assets"],
    }),
    markAssetAsDeleted: builder.mutation({
      query: ({ asset }) => ({
        url: asset._links.mark_deleted.href,
        token: "access",
        method: "POST",
      }),
      invalidatesTags: ["Assets"],
    }),
    restoreAsset: builder.mutation({
      query: ({ asset, body }) => ({
        url: asset._links.restore.href,
        token: "access",
        body: body,
        method: "POST",
      }),
      invalidatesTags: ["Assets"],
    }),
  }),
});

export const {
  useGetAssetsQuery,
  useGetAssetTypesQuery,
  useLazyGetAssetTypesQuery,
  useGetAdminAssetsQuery,
  useLazyGetAdminAssetsQuery,
  useAddAssetMutation,
  useMarkAssetAsDeletedMutation,
  useRestoreAssetMutation,
  useEditAssetMutation,
  useSetAssetTypeActiveMutation,
} = assetApi;
