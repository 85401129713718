export default class GroupModel {
  key;
  items;
  count;

  constructor(key, items, count) {
    this.key = key;
    this.items = items;
    this.count = count;
  }
}
