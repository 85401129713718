import React from "react";
import SkeletonEntryMaskListGroup from "./SkeletonEntryMaskListGroup";
import _ from "lodash";

function SkeletonEntryMaskList() {
  function renderSkeletonBooklistNtimes(n) {
    const group = [];
    _.times(n, (index) =>
      group.push(<SkeletonEntryMaskListGroup key={index} children={0} />),
    );
    return group;
  }

  return (
    <section>
      <SkeletonEntryMaskListGroup children={5} />
      {renderSkeletonBooklistNtimes(8)}
    </section>
  );
}

export default SkeletonEntryMaskList;
