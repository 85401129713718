import { Button, Col, Row } from "react-bootstrap";
import { Check, XCircle } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CustomPopoverFooter from "../CustomPopover/CustomPopoverFooter";

export default function PopoverFooter(props) {
  const { t } = useTranslation(["common"]);

  return (
    <CustomPopoverFooter>
      <Row>
        <Col>
          <Button
            variant={"outline-danger"}
            onClick={props.onReset}
            data-cy="timeFilterReset"
          >
            <XCircle className={"me-2"} />
            {t("reset")}
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            disabled={props.disabled}
            onClick={props.onApply}
            data-cy="timeFilterApply"
          >
            <Check className={"me-2"} />
            {t("apply")}
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            onClick={props.onCancel}
            variant={"secondary"}
            data-cy="timeFilterCancel"
          >
            {t("cancel")}
          </Button>
        </Col>
      </Row>
    </CustomPopoverFooter>
  );
}

PopoverFooter.propTypes = {
  onReset: PropTypes.func,
  disabled: PropTypes.bool,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
};
