import { Dropdown } from "react-bootstrap";
import { css } from "@emotion/css";
import React from "react";
import useViewPort from "public_basics/useViewPort";
import { createPortal } from "react-dom";

export default function PositionedDropdown(props) {
  const viewPort = useViewPort();

  const getDropdownStyle = () => {
    if (!props.position) return null;
    const style = {
      position: "absolute",
      top: props.position.top,
    };
    // 272 fix dropDownWidth
    if (props.position.left + 272 > viewPort.width)
      style.left = viewPort.width - 272;
    else style.left = props.position.left;
    // Children * 40px + 28 px (8+8 padTop&Bot 12 Divider)
    const dropDownHeight = props.children.length * 40 + 28;
    if (props.position.top + dropDownHeight > viewPort.height)
      style.top = viewPort.height - dropDownHeight;
    return style;
  };

  if (!props.position) return null;

  return createPortal(
    <>
      <Dropdown style={getDropdownStyle()} show>
        <Dropdown.Menu
          show
          className={css`
            min-width: 17rem !important;
            z-index: ${props.order ? 1002 + props.order : 1002} !important;
          `}
          data-cy={"positionedDropdown"}
        >
          {props.children}
        </Dropdown.Menu>
      </Dropdown>
      <div
        className={css`
          z-index: ${props.order ? 1001 + props.order : 1001};
          position: fixed;
          inset: 0;
          width: 100vw;
          height: 100vh;
        `}
        onClick={props.onClose}
        data-cy={"positionedDropdownBackdrop"}
      />
    </>,
    document.body,
  );
}
