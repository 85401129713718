import { createSelector, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

// TranslatedLocation contains objects of type `{name: string, depth: number}`
const initialState = {
  translatedLocation: [],
};

const cachedSelector = createSelector(
  [(state) => state.translatedLocation],
  (locationObjects) => _.sortBy(locationObjects, "depth").map((el) => el.name),
);

export const currentLocationSlice = createSlice({
  name: "translatedLocation",
  initialState,
  reducers: {
    addLocation: (state, action) => {
      state.translatedLocation = [...state.translatedLocation, action.payload];
    },
    removeLocation: (state, action) => {
      state.translatedLocation = state.translatedLocation.filter(
        (el) => el.name !== action.payload.name,
      );
    },
  },
  selectors: {
    selectSortedLocations: cachedSelector,
  },
});

export const { addLocation, removeLocation } = currentLocationSlice.actions;

export const { selectSortedLocations } = currentLocationSlice.selectors;
