import i18next from "i18next";

export function checkPasswordCriteria(password, criteria = {}) {
  const charsetTests = {
    lower_required: new RegExp(/(?=.*[a-z])/),
    upper_required: new RegExp(/(?=.*[A-Z])/),
    digit_required: new RegExp(/(?=.*[0-9])/),
    special_character_required: new RegExp(/(?=.*[^A-Za-z0-9])/),
  };
  const result = Object.assign(
    ...Object.entries(charsetTests).map(([key, value]) => ({
      [key]: value.test(password),
    })),
  );
  result.num_of_unique_charsets =
    Object.values(result).filter(Boolean).length >=
    criteria.num_of_unique_charsets;
  result.min_length = password.length >= criteria.min_length;
  return result;
}

export function getRequired(criteria = {}) {
  const requirements = [
    "lower_required",
    "upper_required",
    "digit_required",
    "special_character_required",
  ];
  // If all charsets are required, we do not check for the individual ones to prevent
  // rendering "requires x out of x characters from these categories"
  if (criteria.num_of_unique_charsets === 4) {
    return requirements;
  }
  return requirements.filter((value) => criteria[value]);
}

export function getNotRequired(criteria = {}) {
  return [
    "lower_required",
    "upper_required",
    "digit_required",
    "special_character_required",
  ].filter((value) => !criteria[value]);
}

export function getNumberAdditionalRequiredCharsets(criteria = {}) {
  return criteria.num_of_unique_charsets - getRequired(criteria).length;
}

export function transformResponseCriteria(error_params) {
  const faileCriteria = error_params.password_criteria;
  const missedCriteriaObject = {
    min_length: faileCriteria.min_length ?? 0,
    lower_required: faileCriteria.lower_required ?? false,
    upper_required: faileCriteria.upper_required ?? false,
    digit_required: faileCriteria.digit_required ?? false,
    special_character_required:
      faileCriteria.special_character_required ?? false,
    numberUniqueCharsets: faileCriteria.num_of_unique_charsets ?? 0,
  };
  const missedCriteria = getRequired(missedCriteriaObject);
  if (missedCriteriaObject.min_length > 0) {
    missedCriteria.unshift("min_length");
  }
  if (missedCriteriaObject.numberUniqueCharsets > 0) {
    missedCriteria.push("numberUniqueCharsets");
  }
  let failedCriteriaInfo = "";
  for (let i = 0; i < missedCriteria.length; i++) {
    let attribute = missedCriteria[i];
    failedCriteriaInfo += i18next.t(attribute, {
      ns: "basics",
      [attribute]: `${missedCriteriaObject[attribute]}`,
    });
    if (i < missedCriteria.length - 1) {
      failedCriteriaInfo += ", ";
    }
  }
  return { failedCriteria: failedCriteriaInfo, count: missedCriteria.length };
}
