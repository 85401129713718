import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "basics/dynamicBaseQuery";

export const metaApi = createApi({
  reducerPath: "metaApi",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getBaseUris: builder.query({
      query: () => ({ url: window.ServerUri }),
      serializeQueryArgs: () => "BaseUris",
    }),
  }),
});

