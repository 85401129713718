const TimeUnit = {
  minutes: "minutes",
  hours: "hours",
  days: "days",
  weeks: "weeks",
  months: "months",
  years: "years",
};

Object.freeze(TimeUnit);

export default TimeUnit;
