import { useParams, useMatch } from "react-router-dom";

// Used for components that are not rendered in the route of the entry-masks
export default function useSelectedEntryMaskId(match = "*") {
  const { entryMaskId } = useParams();
  const matchResult = useMatch(match);
  if (match !== "*" && matchResult)
    return parseInt(matchResult.params?.entryMaskId) || null;
  if (entryMaskId) return parseInt(entryMaskId) || null;
  return null;
}
