import { EntryMask, EntryMaskSkeleton } from "public_entrymask/components";
import { Suspense } from "react";
import { Journal } from "react-bootstrap-icons";

export const ENTRYMASK = "ENTRYMASK";
export const path = "/entry-masks";
export const route = {
  path: `${path}/*`,
  element: (
    <Suspense fallback={<EntryMaskSkeleton />}>
      <EntryMask />
    </Suspense>
  ),
};
export const icon = Journal;
