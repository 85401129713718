import { Col, Row } from "react-bootstrap";
import TimeUnit from "../../constants/TimeUnit";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomizedSelect from "../Selects/CustomizedSelect";
import PropTypes from "prop-types";
import DateFieldSelect from "./DateFieldSelect";
import { DateTimePicker } from "@imes/react-date-time-picker";
import TimeSpanSelection from "./TimeSpanSelection";

export default function UntilFilter(props) {
  const { t } = useTranslation("common");

  // Defined this in the component to make sure translations work
  const timeSpanOptions = [
    {
      value: TimeUnit.minutes,
      label: t("minute", { count: props.timeSpan }),
    },
    {
      value: TimeUnit.hours,
      label: t("hour", { count: props.timeSpan }),
    },
    {
      value: TimeUnit.days,
      label: t("day", { count: props.timeSpan }),
    },
    {
      value: TimeUnit.weeks,
      label: t("week", { count: props.timeSpan }),
    },
    {
      value: TimeUnit.months,
      label: t("month", { count: props.timeSpan }),
    },
    {
      value: TimeUnit.years,
      label: t("year", { count: props.timeSpan }),
    },
  ];

  return (
    <>
      <TimeSpanSelection
        onTimeSpanChanged={props.onTimeSpanChanged}
        timeSpan={props.timeSpan}
        endDate={props.endDate}
        timeSpanUnit={props.timeSpanUnit}
        onEndDateChanged={props.onEndDateChanged}
      />
      <Row data-cy={"timeSpanUnitSelector"}>
        <Col className={"mt-1"}>
          <CustomizedSelect
            value={timeSpanOptions.find(
              (option) => option.value === props.timeSpanUnit,
            )}
            isClearable={false}
            onChange={(value) => props.onTimeSpanUnitChanged(value.value)}
            options={timeSpanOptions}
          />
        </Col>
      </Row>
      <Row data-cy={"unitsOfTimeToDateToDate"}>
        <Col xs={"2"}>{t("till")}</Col>
        <Col>
          <DateTimePicker
            value={props.endDate}
            onChange={props.onEndDateChanged}
          />
        </Col>
      </Row>
      <DateFieldSelect
        dateFieldOptions={props.dateFieldOptions}
        dateField={props.dateField}
        onSetDateField={props.onSetDateField}
        dataCyPrefix={"unitsOfTimeToDate"}
      />
    </>
  );
}

UntilFilter.propTypes = {
  endDate: PropTypes.instanceOf(Date),
  onEndDateChanged: PropTypes.func,
  dateFieldOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.any, label: PropTypes.string }),
  ),
  dateField: PropTypes.any,
  onSetDateField: PropTypes.func,
  timeSpan: PropTypes.number.isRequired,
  onTimeSpanChanged: PropTypes.func.isRequired,
  onTimeSpanUnitChanged: PropTypes.func.isRequired,
  timeSpanUnit: PropTypes.any,
};
