import { useGetPictureQuery } from "basics/services/mediaApi";
import PropTypes from "prop-types";
import React from "react";

PictureGalleryItem.propTypes = {
  url: PropTypes.string.isRequired,
  authenticated: PropTypes.bool,
  thumbnail: PropTypes.bool,
};

function PictureGalleryItem(props) {
  const { url, authenticated, thumbnail } = props;

  // only fetch image as blob if authentication is needed
  const { data: src, error } = useGetPictureQuery(url, {
    skip: !authenticated,
  });

  if (authenticated && !src) return null;

  const style = error && !thumbnail ? { width: 92 } : null;
  const outerClassName = thumbnail ? "image-gallery-thumbnail-inner" : null;
  const innerClassName = thumbnail
    ? "image-gallery-thumbnail-image"
    : "image-gallery-image";

  return (
    <div className={outerClassName}>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img className={innerClassName} style={style} src={src ?? url} />
    </div>
  );
}

export default PictureGalleryItem;
