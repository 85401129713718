import AuthorizedRoute from "basics/components/AuthorizedRoute";
import { route as administrationRoute } from "public_administration/info";
import {
  changePasswordRoute,
  route as loginRoute,
  ssoAuthenticationRoute,
} from "public_basics/info";
import { route as dashboardRoute } from "public_dashboard/info";
import { route as entryMaskRoute } from "public_entrymask/info";
import { route as shiftbookRoute } from "public_shiftbook/info";
import { route as userProfileRoute } from "public_userProfile/info";
import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

const Layout = lazy(() => import("basics/components/Layout"));

const routes = [
  {
    children: [
      loginRoute,
      changePasswordRoute,
      ssoAuthenticationRoute,
      {
        element: (
          <AuthorizedRoute>
            <Layout />
          </AuthorizedRoute>
        ),
        children: [
          dashboardRoute,
          shiftbookRoute,
          entryMaskRoute,
          administrationRoute,
          userProfileRoute,
          { path: "*", element: <Navigate to={window.initialPage} replace /> },
        ],
      },
    ],
  },
];

export default routes;
