import { ArrowClockwise } from "react-bootstrap-icons";
import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/css";

export default function AnimatedRefreshIcon(props) {
  const animation = css`
    animation: rotation 1s linear infinite;
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  `;
  const onAnimationIteration = () => {
    if (!props.isLoading) {
      props.onAnimationStop();
    }
  };
  return (
    <ArrowClockwise
      className={props.isLoading || props.inAnimation ? animation : null}
      size={props.size}
      onAnimationIteration={onAnimationIteration}
    />
  );
}

AnimatedRefreshIcon.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  inAnimation: PropTypes.bool.isRequired,
  onAnimationStop: PropTypes.func,
  size: PropTypes.number,
};
