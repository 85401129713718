import React, { useState } from "react";
import { Container, Row, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import LayoutSaveOptions from "../../../constants/LayoutSaveOptions";
import { CustomModal } from "public_basics/components";
import { useTranslation } from "react-i18next";
import {
  useAddUserLayoutMutation,
  useAddEnterpriseLayoutMutation,
} from "../../../services/layoutsApi";

export default function AddLayoutDialog(props) {
  const [saveOption, setSaveOption] = useState(LayoutSaveOptions.user);
  const [name, setName] = useState("");
  const { t } = useTranslation(["layouts", "common"]);
  const [addUserLayout] = useAddUserLayoutMutation();
  const [addEnterpriseLayout] = useAddEnterpriseLayoutMutation();

  const closeDialog = () => {
    props.onClose();
  };

  const onSaveLayout = () => {
    const addLayout =
      saveOption === LayoutSaveOptions.enterprise
        ? addEnterpriseLayout
        : addUserLayout;
    addLayout({
      entity: props.entity,
      body: { layout: props.newLayoutData, name },
    });
    setSaveOption(LayoutSaveOptions.user);
    setName("");
    closeDialog();
  };

  return (
    <CustomModal
      onClose={closeDialog}
      title={t("save")}
      open={props.open}
      onSave={onSaveLayout}
    >
      <Container>
        <Row>
          <Form.Control
            value={name}
            onChange={(event) => setName(event.target.value)}
            autoFocus
            id="name"
            label={t("name", { ns: "common" })}
            type="text"
            placeholder={t("name", { ns: "common" })}
          />
        </Row>
        <Form.Group
          className={"pt-4"}
          onChange={(event) => setSaveOption(event.target.value)}
        >
          {t("save-as")}
          <Form.Check
            type={"radio"}
            aria-label="layout-save-option"
            name="save-option"
            label={t("user")}
            id={t("user")}
            data-cy={"user"}
            value={LayoutSaveOptions.user}
            checked={saveOption === LayoutSaveOptions.user}
          />
          <Form.Check
            type={"radio"}
            aria-label="layout-save-option"
            name="save-option"
            label={t("enterprise")}
            id={t("enterprise")}
            data-cy={"enterprise"}
            value={LayoutSaveOptions.enterprise}
            checked={saveOption === LayoutSaveOptions.enterprise}
          />
        </Form.Group>
      </Container>
    </CustomModal>
  );
}

AddLayoutDialog.propTypes = {
  newLayoutData: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  entity: PropTypes.object.isRequired,
};
