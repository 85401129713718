import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { selectIsAuthenticated } from "basics/slices/authSlice";

function AuthorizedRoute(props) {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const location = useLocation();

  const path = location.pathname;
  const from = path + location.search;

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: from }} replace />;
  }

  return props.children;
}

export default AuthorizedRoute;
