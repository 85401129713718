import React from "react";
import PropTypes from "prop-types";
import { PlusLg } from "react-bootstrap-icons";
import { css } from "@emotion/css";
import { useTranslation } from "react-i18next";
import FloatingActionButton from "./FloatingActionButton";
import RefreshButton from "./Refresh/RefreshButton";

export default function DataGridFabs(props) {
  const { t } = useTranslation("common");
  return (
    <>
      <FloatingActionButton
        className={css`
          bottom: ${20 + props.paddingBottom}px;
          right: 20px;
        `}
        title={t("add")}
        onClick={props.onAdd}
        variant={"success"}
        data-cy={"addEntry"}
      >
        <PlusLg size={30} />
      </FloatingActionButton>
      <RefreshButton
        button={<FloatingActionButton />}
        disabled={props.disabled}
        iconSize={30}
        className={css`
          bottom: ${80 + props.paddingBottom}px;
          right: 20px;
        `}
        data-cy={"reloadEntries"}
        onRefresh={props.onRefresh}
        isLoading={props.isLoading}
      />
    </>
  );
}

DataGridFabs.propTypes = {
  onAdd: PropTypes.func,
  onRefresh: PropTypes.func,
  paddingBottom: PropTypes.number,
};

DataGridFabs.defaultProps = {
  paddingBottom: 0,
};
