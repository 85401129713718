import { Button, ButtonGroup, Dropdown, Modal } from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";
import ModalButtonModel from "public_basics/models/ModalButtonModel";
import { useTranslation } from "react-i18next";

export default function ModalButtons(props) {
  const { t } = useTranslation("common");
  const getButtonVariant = () => {
    if (props.isValid === undefined || props.isValid === null) {
      return "primary";
    }
    return props.isValid === true ? "success" : "danger";
  };

  const getSaveDisabled = () => props.isValid === false || props.disabled;

  const getSaveButton = () => {
    if (props.onSave)
      if (props.saveOptions && props.saveOptions.length > 0)
        return (
          <Dropdown as={ButtonGroup}>
            <Button
              variant={getButtonVariant()}
              disabled={getSaveDisabled()}
              onClick={props.onSave}
              align={"end"}
              data-cy={props.saveButtonDataCy || "saveModal"}
            >
              {props.saveButtonText ? props.saveButtonText : t("save")}
            </Button>
            <Dropdown.Toggle
              variant={getButtonVariant()}
              split
              data-cy={"saveOptions"}
            >
              <Dropdown.Menu>
                {props.saveOptions &&
                  props.saveOptions.map((option, index) => (
                    <Dropdown.Item
                      key={index}
                      as="div"
                      role="button"
                      onClick={option.onClick}
                      disabled={option.disabled}
                    >
                      {option.text}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown.Toggle>
          </Dropdown>
        );
      else
        return (
          <Button
            variant={getButtonVariant()}
            disabled={getSaveDisabled()}
            onClick={props.onSave}
            data-cy={props.saveButtonDataCy || "saveModal"}
          >
            {props.saveButtonText ? props.saveButtonText : t("save")}
          </Button>
        );
    return null;
  };

  return (
    <Modal.Footer>
      {props.customButtons}
      {getSaveButton()}
      <Button
        variant="secondary"
        onClick={props.onClose}
        data-cy={props.closeButtonDataCy || "closeModal"}
      >
        {props.closeButtonText ? props.closeButtonText : t("close")}
      </Button>
    </Modal.Footer>
  );
}

ModalButtons.propTypes = {
  isValid: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  closeButtonDataCy: PropTypes.string,
  saveButtonDataCy: PropTypes.string,
  closeButtonText: PropTypes.string,
  saveButtonText: PropTypes.string,
  customButtons: PropTypes.arrayOf(PropTypes.element),
  saveOptions: PropTypes.arrayOf(PropTypes.instanceOf(ModalButtonModel)),
  disabled: PropTypes.bool,
};
