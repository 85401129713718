import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isDialogFullscreen: false,
};

export const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    setIsDialogFullscreen: (state, action) => {
      state.isDialogFullscreen = action.payload;
    },
  },
  selectors: {
    selectIsDialogFullscreen: (state) => state.isDialogFullscreen,
  },
});

export const { setIsDialogFullscreen } = userSettingsSlice.actions;

export const { selectIsDialogFullscreen } = userSettingsSlice.selectors;
