import _ from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

function ShiftbookSkeleton() {
  return (
    <Row className="justify-content-center mt-2">
      {_.times(5, (index) => (
        <Col xs="auto" key={index}>
          <Skeleton height={28} width={150} />
        </Col>
      ))}
    </Row>
  );
}

export default ShiftbookSkeleton;
