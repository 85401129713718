import { Col } from "react-bootstrap";
import { isValid, format } from "date-fns";
import React from "react";
import Chip from "../Chip";
import { CheckCircleFill, CircleFill } from "react-bootstrap-icons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function FilterOptionChip(props) {
  const { text, active, onClick, ...rest } = props;

  return (
    <Chip
      label={text}
      filled
      color={`--bs-${active ? "primary" : "secondary"}-rgb`}
      endIcon={
        active ? (
          <CheckCircleFill data-cy={"filterActive"} />
        ) : (
          <CircleFill color="white" data-cy={"filterDisabled"} />
        )
      }
      onClick={onClick}
      {...rest}
    />
  );
}

export default function CurrentFilterOptionsPreview(props) {
  const { t } = useTranslation("common");

  const getTimeIntervalLabel = () => {
    if (isValid(props.startDate) && isValid(props.endDate)) {
      return `${props.dateField}: 
            ${format(
              props.startDate,
              t("date-format.pickerDateTimeFormatString"),
            )} - 
            ${format(
              props.endDate,
              t("date-format.pickerDateTimeFormatString"),
            )}`;
    }
  };

  return (
    <>
      {isValid(props.startDate) && isValid(props.endDate) && (
        <Col xs="auto" key={"time-range"}>
          <FilterOptionChip
            data-cy={"timeFilterChip"}
            text={getTimeIntervalLabel()}
            active={props.filterActive}
            onClick={() => props.onSetFilterActive(!props.filterActive)}
          />
        </Col>
      )}
      {props.additionalItems
        .filter((item) => item.chip)
        .map((item) => (
          <Col xs="auto" key={item.key}>
            <FilterOptionChip {...item.chip} />
          </Col>
        ))}
    </>
  );
}

CurrentFilterOptionsPreview.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  filterActive: PropTypes.bool,
  onSetFilterActive: PropTypes.func.isRequired,
  additionalItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.element.isRequired,
    }),
  ),
  dateField: PropTypes.string,
};

CurrentFilterOptionsPreview.defaultProps = {
  additionalItems: [],
};
