import React from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import styled from "@emotion/styled";

const StyledImage = styled(Image)`
  align-items: center;
  margin-block: inherit;
  padding-block: inherit;
  white-space: nowrap;
`;

const StyledLabel = styled(Form.Label)`
  word-wrap: break-word;
  margin-left: 5%;
`;

export default function StatusColumnView(props) {
  const { base64Image, value } = props;

  if (base64Image === undefined || base64Image === null) return value;

  return (
    <>
      <StyledImage
        src={"data:image;base64," + base64Image}
        sm={1}
        lg={1}
        alt={value}
      />
      <StyledLabel sm={11} lg={11}>
        {value}
      </StyledLabel>
    </>
  );
}

StatusColumnView.propTypes = {
  base64Image: PropTypes.string,
  value: PropTypes.string,
};
