import PropTypes from "prop-types";
import React from "react";
import { DateTimePicker } from "@imes/react-date-time-picker";
import { Col, InputGroup } from "react-bootstrap";

function DateTimePickerInput(props) {
  const getLabel = (value, labelWidth) => {
    if (value != null) {
      return (
        <InputGroup.Text style={{ width: labelWidth }}>{value}</InputGroup.Text>
      );
    }
  };

  const renderInput = (input) => (
    <InputGroup data-cy={props.label.replace(/\s/g, "")}>
      {getLabel(props.label, props.labelWidth)}
      {input}
    </InputGroup>
  );

  return (
    <Col
      xs={props.xs}
      md={props.md}
      lg={props.lg}
      xl={props.xl}
      className={props.className}
    >
      <DateTimePicker
        minDateTime={props.minDateTime}
        value={props.value}
        onChange={props.onDateChange}
        inputProps={{
          ...(!props.value && { isInvalid: true }),
          ...props.inputProps,
          render: renderInput,
        }}
      />
    </Col>
  );
}

DateTimePickerInput.propTypes = {
  value: PropTypes.any,
  onDateChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  labelWidth: PropTypes.number,
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  minDateTime: PropTypes.instanceOf(Date),
};

export default DateTimePickerInput;
