import { configureModulesStore } from "@imes/module-host";
import plantHistorianBasics from "basics/module";
import modules from "./modules";

const store = configureModulesStore(
  {
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
  },
  [plantHistorianBasics, ...modules],
);

export default store;
