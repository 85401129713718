import routes from "config/routes";
import { closeSnackbar, SnackbarProvider } from "notistack";
import { IconButton } from "public_basics/components";
import React, { Suspense } from "react";
import { XLg } from "react-bootstrap-icons";
import "react-loading-skeleton/dist/skeleton.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TitleUpdater from "basics/components/TitleUpdater";

const router = createBrowserRouter(routes);

function App() {
  return (
    <div className="d-flex flex-column overflow-hidden h-100">
      <SnackbarProvider
        autoHideDuration={window.autoHideDuration}
        maxSnack={window.maxSnackbarsDisplayed}
        preventDuplicate
        action={(key) => (
          <IconButton onClick={() => closeSnackbar(key)} variant={"light"}>
            <XLg />
          </IconButton>
        )}
      >
        <Suspense>
          <RouterProvider router={router} />
        </Suspense>
      </SnackbarProvider>
      <TitleUpdater />
    </div>
  );
}

export default App;
