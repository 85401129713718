import { createSelector } from "@reduxjs/toolkit";
import { sortAssets } from "public_basics/alphanumericSort";

const checkExcludedRecursive = (assets, excluded) => {
  if (excluded.includes(assets.guid)) return true;
  return assets.items
    .map((item) => checkExcludedRecursive(item, excluded))
    .includes(true);
};
const filter = (assets, exclude = []) => {
  const filterAssets = (assets) =>
    assets
      .filter(
        (asset) =>
          !asset.delete_date ||
          (!!exclude && checkExcludedRecursive(asset, exclude)),
      )
      .map((asset) =>
        asset.items ? { ...asset, items: filterAssets(asset.items) } : asset,
      );

  return filterAssets(assets);
};

const emptyArray = [];
const selectAssets = createSelector(
  [
    (res) => res.data,
    (res, args) => args.filterDeleted ?? false,
    (res, args) => args.excluded ?? null,
  ],
  (assets, filterDeleted, exclude) => {
    if (!assets) return emptyArray;
    return sortAssets(filterDeleted ? filter(assets, exclude) : [...assets]);
  },
);

export default selectAssets;
