import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Row, Collapse, Button } from "react-bootstrap";
import styles from "./EntryMaskList.module.scss";

export default function SkeletonEntryMaskListGroup(props) {
  function renderSkeletonSubGroup(childrenCount) {
    const group = [];
    _.times(childrenCount, (index) =>
      group.push(renderSkeletonSubGroupElement(index)),
    );
    return group;
  }

  function renderSkeletonSubGroupElement(key) {
    return (
      <li className={"list-group-item list-group-item-action"} key={key}>
        <Skeleton />
      </li>
    );
  }

  return (
    <li className={"list-group-item bg-light " + styles.listGroupItemGroup}>
      <Button
        type={"button"}
        variant={null}
        className={
          "align-self-start pe-4 btn justify-content-start " +
          styles.buttonStyle
        }
      >
        <Row className={"my-1"}>
          <Skeleton />
        </Row>
      </Button>
      <Collapse in={true}>
        <ul className="list-group">
          {renderSkeletonSubGroup(props.children ? props.children : 0)}
        </ul>
      </Collapse>
    </li>
  );
}

SkeletonEntryMaskListGroup.propTypes = {
  children: PropTypes.number.isRequired,
};
