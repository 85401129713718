import { Button } from "react-bootstrap";
import styled from "@emotion/styled";
import React from "react";

const PositionedButton = styled(Button)`
  position: absolute;
  z-index: 6;
  width: 56px;
  height: 56px;
  border: 0 !important;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function FloatingActionButton(props) {
  return <PositionedButton {...props}>{props.children}</PositionedButton>;
}

export default FloatingActionButton;
