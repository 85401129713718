export default class LinkedEntriesLinksModel {
  get;
  add;

  static fromResponse(obj) {
    const model = new LinkedEntriesLinksModel();
    model.get = obj.get.href;
    model.add = obj.add.href;
    return model;
  }
}
