import { createSlice, isAnyOf, isAllOf } from "@reduxjs/toolkit";
import { decodeJWT, isTokenExpired } from "basics/dynamicBaseQuery";
import _ from "lodash";
import { authApi } from "basics/services/authApi";

const emptyState = {
  user: null,
  access_token: null,
  expires: 0,
};

const getInitalStateFromLocalStorage = () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken || isTokenExpired(decodeJWT(refreshToken)?.exp))
    return emptyState;
  try {
    return {
      ...emptyState,
      user: decodeJWT(refreshToken),
    };
  } catch {
    return emptyState;
  }
};

const initialState = getInitalStateFromLocalStorage();

const tokenPropsToSelect = [
  "email",
  "privileges",
  "guid",
  "group_id",
  "group_guid",
  "enterprise_guid",
  "username",
  "full_name",
  "telephone",
  "auth_mode",
];

const matchUnauthorizedError = (action) => action.payload.status === 401;

export const matchAuthenticated = isAnyOf(
  authApi.endpoints.refreshToken.matchFulfilled,
  authApi.endpoints.login.matchFulfilled,
  authApi.endpoints.changePassword.matchFulfilled,
  authApi.endpoints.authenticateSSOToken.matchFulfilled,
);
export const matchAuthenticationRevoked = isAnyOf(
  authApi.endpoints.logout.matchFulfilled,
  isAllOf(
    isAnyOf(
      authApi.endpoints.refreshToken.matchRejected,
      authApi.endpoints.logout.matchRejected,
    ),
    matchUnauthorizedError,
  ),
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticatedUser: (state, action) => {
      const decodedToken = decodeJWT(action.payload.refresh_token);
      const newUser = _.pick(decodedToken, tokenPropsToSelect);
      if (!_.isEqual(newUser, state.user)) state.user = newUser;
      state.access_token = action.payload.access_token;
      state.expires = decodeJWT(action.payload.access_token).exp;
    },
    removedUser: () => emptyState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(matchAuthenticated, authSlice.caseReducers.authenticatedUser)
      .addMatcher(
        matchAuthenticationRevoked,
        authSlice.caseReducers.removedUser,
      );
  },
  selectors: {
    selectUserPrivileges: (state) => state.user?.privileges,
    selectCurrentUser: (state) => state.user,
    selectUserGuid: (state) => state.user?.guid,
    selectIsAuthenticated: (state) => !!state.user,
  },
});

export const { authenticatedUser, removedUser } = authSlice.actions;

export const {
  selectUserPrivileges,
  selectCurrentUser,
  selectUserGuid,
  selectIsAuthenticated,
} = authSlice.selectors;

export default authSlice;
