import { Col, Row } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";
import { DateTimePicker } from "@imes/react-date-time-picker";
import PropTypes from "prop-types";
import DateFieldSelect from "./DateFieldSelect";

export default function FromToFilter(props) {
  const { t } = useTranslation(["common", "basics"]);
  return (
    <>
      <Row data-cy={"dateFilterFrom"}>
        <Col xs={"2"}>{t("from")}</Col>
        <Col>
          <DateTimePicker
            value={props.startDate}
            onChange={props.onStartDateChanged}
          />
        </Col>
      </Row>
      <Row data-cy={"dateFromDateToToDate"}>
        <Col xs={"2"}>{t("till")}</Col>
        <Col>
          <DateTimePicker
            value={props.endDate}
            onChange={props.onEndDateChanged}
          />
        </Col>
      </Row>
      <DateFieldSelect
        dateFieldOptions={props.dateFieldOptions}
        dateField={props.dateField}
        onSetDateField={props.onSetDateField}
        dataCyPrefix={"dateFromDateTo"}
      />
    </>
  );
}

FromToFilter.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  onStartDateChanged: PropTypes.func,
  endDate: PropTypes.instanceOf(Date),
  onEndDateChanged: PropTypes.func,
  dateFieldOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.any, label: PropTypes.string }),
  ),
  dateField: PropTypes.any,
  onSetDateField: PropTypes.func,
};
