import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "basics/dynamicBaseQuery";
import packageInfo from "../../../package.json";
import { metaApi } from "basics/services/metaApi";

export const loggingApi = createApi({
  reducerPath: "loggingApi",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getLoggingUris: builder.query({
      query: () => ({
        endpoint: metaApi.endpoints.getBaseUris,
        path: "logging.href",
      }),
      serializeQueryArgs: () => "LoggingUris",
    }),
    sendLoggingMessage: builder.mutation({
      query: ({ body }) => ({
        endpoint: loggingApi.endpoints.getLoggingUris,
        path: "_links.logging.href",
        token: "access",
        method: "POST",
        body: {
          ...body,
          client_version: packageInfo.version,
          instance_name: window.instanceName,
          instance_type: window.instanceType,
        },
      }),
    }),
  }),
});

export const { useSendLoggingMessageMutation } = loggingApi;
