import React from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import styled from "@emotion/styled";

const StyledDiv = styled("div")`
  position: fixed;
  background-color: #000000;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  opacity: 0.2;
`;

export function PopupWindowBlocker(props) {
  return createPortal(
    <StyledDiv data-cy={"PopupWindowBlocker"} {...props}></StyledDiv>,
    document.body,
  );
}

PopupWindowBlocker.propTypes = {
  onClick: PropTypes.func,
};
