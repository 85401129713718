import { FloatingLabel, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export default function PasswordInput(props) {
  const { label, value, onChange, children, ...validationDataProps } = props;

  const [capsLockEnabled, setCapsLockEnabled] = useState(false);
  const { t } = useTranslation("basics");

  return (
    <>
      <OverlayTrigger
        show={capsLockEnabled}
        placement={"top"}
        overlay={
          <Tooltip
            id="CapslockWarning"
            style={{
              "--bs-tooltip-bg": "var(--bs-warning)",
              "--bs-tooltip-color": "#000",
              "--bs-tooltip-opacity": 1,
            }}
          >
            {t("capslock-active")}
          </Tooltip>
        }
      >
        <FloatingLabel label={label}>
          <Form.Control
            type="password"
            placeholder={label}
            value={value}
            name={label}
            onChange={(event) => onChange(event.target.value.toString())}
            onKeyDown={(e) => {
              setCapsLockEnabled(e.getModifierState("CapsLock"));
            }}
            onMouseDown={(e) => {
              setCapsLockEnabled(e.getModifierState("CapsLock"));
            }}
            onBlur={() => setCapsLockEnabled(false)}
            {...validationDataProps}
          />
          {children}
        </FloatingLabel>
      </OverlayTrigger>
    </>
  );
}

PasswordInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  dataCy: PropTypes.string,
};
