import { useGetAssetsQuery } from "basics/services/assetApi";
import { Popover, Position, ToolbarItem } from "devextreme-react/popover";
import selectAssets from "public_basics/selectAssets";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { css } from "@emotion/css";
import { TreeList } from "public_basics/components";
import { uuidRegex } from "basics/utils/utils";

const animationConfig = {
  show: {
    type: "pop",
    from: {
      scale: 0,
    },
    to: {
      scale: 1,
    },
  },
  hide: {
    type: "fade",
    from: 1,
    to: 0,
  },
};

const getTopLevelFilterValuesMatchingColumnId = (columnId, currentFilter) => {
  let values = [];
  let foundColumnFilter = false;
  if (currentFilter === "!") return [];
  if (
    Array.isArray(currentFilter) &&
    Array.isArray(currentFilter[0]) &&
    currentFilter[0][0] === columnId
  )
    values = getColumnFilters(currentFilter, columnId);
  else {
    for (const filterValue of currentFilter) {
      if (Array.isArray(filterValue[0]))
        if (filterValue[0][0] === columnId) {
          if (foundColumnFilter) return [];
          values = getColumnFilters(filterValue, columnId);
          foundColumnFilter = true;
        }
      if (typeof filterValue === "string" && filterValue !== "and") return [];
    }
  }
  return values;
};

const getColumnFilters = (filterValues, columnId) => {
  const columnFilters = [];
  for (const filterValue of filterValues) {
    if (Array.isArray(filterValue)) {
      if (filterValue[0] !== columnId) return [];
      columnFilters.push(filterValue[2]);
    }
    if (typeof filterValue === "string" && filterValue !== "or") return [];
  }
  return columnFilters;
};

export default function AssetHeaderCell(props) {
  const { t } = useTranslation("common");
  const [showPopover, setShowPopover] = useState(false);
  const [value, setValue] = useState([]);

  const { assets } = useGetAssetsQuery(undefined, {
    selectFromResult: (res) => ({
      ...res,
      assets: selectAssets(res, { filterDeleted: true }),
    }),
  });

  useEffect(() => {
    const currentFilter = props.data.component.getCombinedFilter();
    if (currentFilter)
      setValue(
        getTopLevelFilterValuesMatchingColumnId(
          props.data.column.name,
          currentFilter,
        ).filter((value) => value.match(uuidRegex)),
      );
    else setValue([]);
  }, [showPopover, props.data.component, props.data.column.name]);

  return (
    <>
      <div className="dx-datagrid-text-content dx-text-content-alignment-left dx-header-filter-indicator">
        <div className={"overflow-hidden text-truncate"}>{t("assignment")}</div>
      </div>
      <div className={"dx-column-indicators"} id={"asset-column"}>
        <i
          className={`dx-icon-filter ${
            value.length ? "" : "dx-header-filter-empty"
          }`}
          data-cy={"AssetFilterButton"}
          role={"button"}
          tabIndex={0}
          aria-label={t("assignment")}
          aria-haspopup={"dialog"}
          onClick={(event) => {
            setShowPopover(true);
            event.stopPropagation();
          }}
        ></i>
      </div>
      {createPortal(
        <Popover
          animation={animationConfig}
          minWidth={250}
          wrapperAttr={{
            class: css`
              .dx-popup-content {
                padding-left: 0;
                padding-right: 0;
              }
            `,
          }}
          visible={showPopover}
          onHiding={() => {
            setShowPopover(false);
          }}
        >
          <Position
            my={"right top"}
            at={"right bottom"}
            of={"#" + props.data.column.headerId}
            offset={{ x: 0, y: -10 }}
          />
          <ToolbarItem
            data-cy={"AssetOkButton"}
            toolbar={"bottom"}
            widget="dxButton"
            location="after"
            options={{
              text: "OK",
              onClick: () => {
                if (value.length) {
                  props.data.component.columnOption(
                    props.data.column.name,
                    "filterValue",
                    value,
                  );
                  props.data.component.columnOption(
                    props.data.column.name,
                    "selectedFilterOperation",
                    "oneOf",
                  );
                } else {
                  props.data.component.columnOption(
                    props.data.column.name,
                    "selectedFilterOperation",
                    "",
                  );
                }
                setShowPopover(false);
              },
            }}
          ></ToolbarItem>
          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="after"
            options={{
              text: t("cancel"),
              onClick: () => {
                setShowPopover(false);
              },
            }}
          ></ToolbarItem>
          <TreeList
            options={assets}
            disabled={false}
            value={value}
            onChange={setValue}
            disableIndeterminate
          />
        </Popover>,
        document.body,
      )}
    </>
  );
}
