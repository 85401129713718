import React from "react";

/**
 * Creates a ref and keeps it in sync with the given ref.
 * Useful when a ref is needed but RefForwarding should also be supported.
 *
 * @param {React.Ref<HTMLInputElement>} [ref]
 * @return {React.Ref<HTMLInputElement>}
 */
export default function useSyncedRef(ref) {
  const innerRef = React.useRef();
  React.useEffect(() => {
    if (!ref) return;
    if (ref instanceof Function) ref(innerRef.current);
    else ref.current = innerRef.current;
  });
  return innerRef;
}
