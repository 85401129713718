import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import DataGridWrapper from "basics/components/DataGridWrapper/DataGridWrapper";
import BookEntryListLoadOptionsModel from "../../models/BookEntryListLoadOptionsModel";
import EntryMaskEntryListItemsModel from "./EntryMaskEntryListItemsModel";
import { RefreshButton, FloatingActionButton } from "public_basics/components";
import DataSourceTypes from "public_basics/constants/DataSourceTypes";
import useEntryMaskDataGrid from "../../customHooks/useEntryMaskDataGrid";
import { css } from "@emotion/css";
import SkeletonEntryMaskEntryList from "entrymask/components/SkeletonEntryMaskEntryList";

const remoteOperations = { groupPaging: true };
const requestLoadOptions = new BookEntryListLoadOptionsModel();

const selectionProps = { mode: "single", showCheckBoxesMode: "always" };

function LinkEntryDataGrid(props) {
  const { selectedEntryMaskId, onChange } = props;
  const [isLoading, setIsLoading] = useState(false);

  const {
    ref,
    dataColumnDefinitions,
    links,
    refreshData,
    selectedEntryMaskConfig,
  } = useEntryMaskDataGrid(selectedEntryMaskId);

  const onSelectionsChanged = useCallback(
    (e) => {
      onChange(e.selectedRowsData[0]);
    },
    [onChange],
  );
  return selectedEntryMaskConfig ? (
    <>
      <RefreshButton
        button={<FloatingActionButton />}
        iconSize={30}
        className={css`
          bottom: 80px;
          right: 20px;
        `}
        data-cy={"reloadEntries"}
        onRefresh={refreshData}
        isLoading={isLoading}
      />
      <DataGridWrapper
        key={selectedEntryMaskId}
        className={
          "mt-3 " +
          css`
            height: 50vh !important;
          `
        }
        ref={ref}
        remoteOperations={remoteOperations}
        columnResizingMode={"widget"}
        showBorders={false}
        lineNumberColumnEnabled={true}
        dataColumnDefinitions={dataColumnDefinitions}
        sortingSpecs={selectedEntryMaskConfig.initial_sorting_specs}
        requestLoadOptions={requestLoadOptions}
        links={links}
        dataSourceType={DataSourceTypes.remote}
        entryItemsModel={EntryMaskEntryListItemsModel}
        scope={selectedEntryMaskConfig?.guid}
        selectionProps={selectionProps}
        onSelectionChanged={onSelectionsChanged}
        setLoading={setIsLoading}
      />
    </>
  ) : (
    <SkeletonEntryMaskEntryList rows={20} />
  );
}

LinkEntryDataGrid.propTypes = {
  selectedEntryMaskId: PropTypes.number.isRequired,
};

export default React.memo(LinkEntryDataGrid);
