import { ModuleModel } from "@imes/module-host";
import {
  ADMINISTRATION_COMPONENT,
  ADMINISTRATION_NAV_ITEM,
} from "public_administration/components";
import { ADMINISTRATION } from "public_administration/info";
import Privileges from "public_basics/constants/Privileges";
import { lazy } from "react";
import NavItem from "./components/NavItem";

const Administration = lazy(() => import("administration/Administration"));

const adminModule = new ModuleModel(ADMINISTRATION);

adminModule.requiredPrivileges = [Privileges.ADMIN_WEB];
adminModule.publicComponents = {
  [ADMINISTRATION_NAV_ITEM]: NavItem,
  [ADMINISTRATION_COMPONENT]: Administration,
};

export default adminModule;
