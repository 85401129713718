import PropTypes from "prop-types";
import { BaseEntryLink } from "public_basics/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetLinkedEntryInfoQuery } from "basics/services/entryLinksApi";
import moment from "moment/moment";

TaskEntryLink.propTypes = {
  link: PropTypes.shape({
    _links: PropTypes.shape({
      info: PropTypes.shape({ href: PropTypes.string.isRequired }).isRequired,
      delete: PropTypes.shape({ href: PropTypes.string.isRequired }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default function TaskEntryLink(props) {
  const { link } = props;

  const { t } = useTranslation("basics");
  const { data: entryInfo } = useGetLinkedEntryInfoQuery({ link });

  return (
    <BaseEntryLink
      prefix={t("task")}
      link={link}
      name={entryInfo?.title}
      insertDate={moment.utc(entryInfo?.insert_date).local().toDate()}
      to={entryInfo && `/shiftbook/tasks/task/${entryInfo.guid}`}
    />
  );
}
