import { createSlice } from "@reduxjs/toolkit";

const initialFileDropdownState = {
  position: null,
  files: [],
};

const initialState = {
  fileDropdown: initialFileDropdownState,
};

const metaSlice = createSlice({
  name: "fileDropdown",
  initialState: initialState,
  reducers: {
    openFileDropdown(state, action) {
      state.fileDropdown = {
        position: action.payload.position,
        files: action.payload.files,
      };
    },
    closeFileDropdown(state) {
      state.fileDropdown = initialFileDropdownState;
    },
  },
  selectors: {
    selectFileDropdownFiles: (state) => state.fileDropdown.files,
    selectFileDropdownPosition: (state) => state.fileDropdown.position,
  },
});

export const { openFileDropdown, closeFileDropdown } = metaSlice.actions;
export const { selectFileDropdownFiles, selectFileDropdownPosition } =
  metaSlice.selectors;
export default metaSlice;
