import React, { useMemo } from "react";
import CustomizedSelect from "../Selects/CustomizedSelect";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { components } from "react-select";
import { PeopleFill, PersonFill } from "react-bootstrap-icons";
import {
  useGetUserGroupsQuery,
  useGetUsersQuery,
} from "../../services/authApi";

const gridCellStyles = {
  control: (styles) => ({
    ...styles,
    border: "none",
    backgroundColor: "inherit",
    boxShadow: "unset",
  }),
  indicatorsContainer: () => ({ display: "none" }),
};

const customMultiValue = ({ children, ...props }) => {
  return (
    <div
      className="d-flex align-items-center text-black ps-1"
      style={{
        "--bs-bg-opacity": 0.25,
        backgroundColor: `rgba(var(--bs-${
          props.data.optionGroup === "groups" ? "primary" : "imes-yellow"
        }-rgb), var(--bs-bg-opacity))`,
        margin: 2,
        borderRadius: 2,
      }}
    >
      {props.data?.optionGroup === "groups" ? <PeopleFill /> : <PersonFill />}
      <components.MultiValue {...props}>{children}</components.MultiValue>
    </div>
  );
};

function UserAndGroupSelection(props) {
  const { t } = useTranslation(["entry-mask", "common"]);
  const { data: users } = useGetUsersQuery();
  const { data: groups } = useGetUserGroupsQuery();
  const options = useMemo(() => {
    return [
      {
        label: (
          <>
            {t("groups", { ns: "entry-mask" })}
            <PeopleFill className="ms-1" />
          </>
        ),
        options:
          groups
            ?.toSorted((a, b) => a.name.localeCompare(b.name))
            .map((group) => {
              return {
                label: group.name,
                value: group.guid,
                optionGroup: "groups",
              };
            }) ?? [],
      },
      {
        label: (
          <>
            {t("users", { ns: "common" })}
            <PersonFill className="ms-1" />
          </>
        ),
        options:
          users
            ?.toSorted((a, b) => a.username.localeCompare(b.username))
            .map((user) => {
              return {
                label: user.username,
                value: user.guid,
                optionGroup: "users",
                isDisabled: !user.enabled,
              };
            }) ?? [],
      },
    ];
  }, [users, groups, t]);

  const formattedValue = props.value.map((assigneeGuid) => {
    const assignee = [...options[0].options, ...options[1].options].find(
      (item) => item.value === assigneeGuid.toLowerCase(),
    );
    return {
      value: assigneeGuid,
      label: assignee ? assignee.label : assigneeGuid,
      optionGroup: assignee?.optionGroup,
    };
  });

  const onChangeSelection = (values) => {
    props.onChange(values.map((value) => value.value));
  };

  const getValidation = () => {
    if (props.disabled) return null;
    if (props.isValid) return { isValid: true };
    if (props.isValid === undefined && !props.mandatory) return null;
    return { isInvalid: true };
  };

  const customStyles = {
    multiValueRemove: (provided) =>
      props.disabled ? { ...provided, display: "none" } : provided,
    group: (provided) => ({ ...provided, paddingTop: 0 }),
    groupHeading: (provided) => ({
      ...provided,
      position: "sticky",
      top: 0,
      backgroundColor: "#fff",
      marginBottom: 0,
      paddingTop: 4,
      paddingBottom: 4,
      borderBottom: "1px solid var(--bs-border-color)",
      zIndex: 1,
      display: "flex",
      alignItems: "center",
    }),
    menuList: (provided) => ({ ...provided, paddingTop: 0 }),
    option: (provided) => ({ ...provided, opacity: props.disabled ? 0.5 : 1 }),
    multiValue: (provided) => ({
      ...provided,
      margin: 0,
      backgroundColor: null,
    }),
  };

  return (
    <>
      <CustomizedSelect
        onChange={!props.disabled ? onChangeSelection : null}
        options={options}
        isMulti
        disabled={!!props.isGridCell}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        value={formattedValue}
        isClearable={!props.disabled}
        customStyles={
          props.isGridCell
            ? { ...customStyles, ...gridCellStyles }
            : customStyles
        }
        validation={getValidation()}
        components={{ MultiValue: customMultiValue }}
      />
    </>
  );
}

UserAndGroupSelection.defaultProps = {
  value: [],
  mandatory: false,
};

UserAndGroupSelection.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  mandatory: PropTypes.bool,
  isGridCell: PropTypes.bool,
};

export default UserAndGroupSelection;
