import { TASKS } from "public_shiftbook/public_tasks/info";
import { lazy } from "react";
import {
  CalendarEvent,
  ExclamationSquare,
  InfoCircle,
  People,
} from "react-bootstrap-icons";

const Events = lazy(() => import("shiftbook/modules/events/Events"));
const Infoboard = lazy(() => import("shiftbook/modules/infoboard/Infoboard"));
const Instructions = lazy(
  () => import("shiftbook/modules/instructions/Instructions"),
);
const ShiftHandover = lazy(
  () => import("shiftbook/modules/shifthandover/ShiftHandover"),
);

/*
 * This is only a temporary solution while these modules are mocked with entry masks.
 * Once all modules are defined, all that should be left here is SUBMODULES.
 * Use the module TASKS as a template on how to define these in the future.
 */

export const EVENTS = "SHIFTBOOK.EVENTS";
export const SHIFT_HANDOVER = "SHIFTBOOK.SHIFT_HANDOVER";
export const INSTRUCTIONS = "SHIFTBOOK.INSTRUCTIONS";
export const INFOBOARD = "SHIFTBOOK.INFOBOARD";

export const eventsPath = "events";
export const eventsRoute = { path: `${eventsPath}/*`, element: <Events /> };
export const EventsIcon = CalendarEvent;
export const eventsMatches = ["Ereignisse", "Events"];

export const shiftHandoverPath = "shift-handover";
export const shiftHandoverRoute = {
  path: `${shiftHandoverPath}/*`,
  element: <ShiftHandover />,
};
export const ShiftHandoverIcon = People;
export const shiftHandoverMatches = ["Schichtübergabe", "Shift Handover"];

export const instructionsPath = "instructions";
export const instructionsRoute = {
  path: `${instructionsPath}/*`,
  element: <Instructions />,
};
export const InstructionsIcon = ExclamationSquare;
export const instructionsMatches = ["Anweisungen", "Instructions"];

export const infoboardPath = "infoboard";
export const infoboardRoute = {
  path: `${infoboardPath}/*`,
  element: <Infoboard />,
};
export const InfoboardIcon = InfoCircle;
export const infoboardMatches = ["Infotafel", "Information board"];

const SUBMODULES = [TASKS, EVENTS, SHIFT_HANDOVER, INSTRUCTIONS, INFOBOARD];
Object.freeze(SUBMODULES);

export default SUBMODULES;
