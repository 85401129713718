import { authApi } from "basics/services/authApi";
import i18n from "i18next";
import SnackbarAlertColour from "public_basics/constants/SnackbarAlertColour";
import { enqueueSnackbar } from "notistack";
import { isAllOf, isAnyOf } from "@reduxjs/toolkit";
import {
  authenticatedUser,
  removedUser,
  selectUserPrivileges,
  matchAuthenticated,
  matchAuthenticationRevoked,
} from "basics/slices/authSlice";
import { getModuleRegistry, loadModule, unloadModule } from "@imes/module-host";
import { ENTRYMASK } from "public_entrymask/info";
import { TASKS } from "public_shiftbook/public_tasks/info";

const shouldUnloadModules = isAnyOf(matchAuthenticationRevoked, removedUser);

const addAuthListeners = (startListening) => {
  startListening({
    matcher: isAllOf(
      authApi.endpoints.login.matchRejected,
      // 139 is PasswordExpiredError. Forces user to renew his password before logging in.
      (action) => action.payload.data.response_code === 139,
    ),
    effect: (action, listenerApi) => {
      listenerApi.dispatch(
        authApi.util.upsertQueryData("getPasswordCriteria", undefined, {
          username: action.payload.data.error_params.username,
          ...action.payload.data.error_params.password_criteria,
        }),
      );
    },
  });
  startListening({
    matcher: authApi.endpoints.logout.matchFulfilled,
    effect: () => {
      enqueueSnackbar(i18n.t("logout-success", { ns: "basics" }), {
        variant: SnackbarAlertColour.SUCCESS,
      });
    },
  });
  startListening({
    matcher: authApi.endpoints.changePassword.matchFulfilled,
    effect: () => {
      enqueueSnackbar(i18n.t("renew-password-success", { ns: "basics" }), {
        variant: SnackbarAlertColour.SUCCESS,
      });
    },
  });
  startListening({
    matcher: isAnyOf(matchAuthenticated, authenticatedUser),
    effect: (action) => {
      localStorage.setItem("refreshToken", action.payload.refresh_token);
    },
  });
  startListening({
    matcher: isAnyOf(matchAuthenticated, authenticatedUser),
    effect: async (action, listenerApi) => {
      const state = listenerApi.getState();
      const userPrivileges = selectUserPrivileges(state);
      const moduleRegistry = getModuleRegistry();

      const authorizedModules = window.ENABLED_MODULES.filter((module) => {
        const moduleData = moduleRegistry[module];
        return (
          !moduleData.requiredPrivileges ||
          moduleData.requiredPrivileges.every((privilege) =>
            userPrivileges?.includes(privilege),
          )
        );
      });
      authorizedModules.forEach(
        (module) =>
          !(module in state.loadedModules) &&
          listenerApi.dispatch(loadModule(module)),
      );

      // TODO remove once application doesn't break without
      //  Issue: https://gitlab.imes-solutions.com/plant-historian/web/client/-/issues/987
      [ENTRYMASK, TASKS].forEach((module) => {
        if (
          module in state.loadedModules &&
          !authorizedModules.includes(module)
        ) {
          listenerApi.dispatch(unloadModule(module));
        }
      });

      // pause listener until modules are unloaded
      listenerApi.unsubscribe();
      await listenerApi.condition(shouldUnloadModules);
      listenerApi.subscribe();
    },
  });
  startListening({
    matcher: shouldUnloadModules,
    effect: (action, listenerApi) => {
      localStorage.removeItem("refreshToken");
      window.ENABLED_MODULES.forEach((module) => {
        listenerApi.dispatch(unloadModule(module));
      });
    },
  });
};

export default addAuthListeners;
