class FileModel {
  id;
  fileName;
  fileType;
  fileSize;
  fileUri;

  static fromResponse(obj) {
    const model = new FileModel();
    model.id = obj.id;
    model.fileName = obj.file_name;
    model.fileType = obj.file_type;
    model.fileSize = obj.file_size;
    model.fileUri = obj._links.self.href;
    return model;
  }
}

export default FileModel;
