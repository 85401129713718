import { parse } from "date-fns";
import i18n from "i18next";

const timeParser = (time) => {
  parse(
    time,
    i18n.t("date-format.localTimeFormatString", { ns: "common" }),
    new Date(),
  );
};

export default timeParser;
