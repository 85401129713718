import React from "react";
import {
  AssetSelection,
  IconButton,
  StatusColumnView,
  UserAndGroupSelection,
  UserGuidToName,
} from "../components";
import { File, Files } from "react-bootstrap-icons";
import i18n from "i18next";
import { format, parseISO } from "date-fns";
import { openFileDropdown } from "basics/slices/metaSlice";

export default class DataTypes {
  constructor(name, renderCallback) {
    this.name = name;
    this.render = renderCallback;
  }

  toString() {
    return `DataType.${this.name}`;
  }

  /*
                  Available Types from DB:
                  Text, Status, Picture, DateTime, Time, DateBerechnung, Berechnung, Float, Date, DataSource, Bit
                  */
  static type = {
    Bit: "Bit",
    Text: "Text",
    Priority: "Priority",
    Float: "Float",
    Date: "Date",
    Time: "Time",
    DateTime: "DateTime",
    DataSource: "DataSource",
    DataSourceGrid: "DataSourceGrid",
    Status: "Status",
    Picture: "Picture",
    TextMemo: "TextMemo",
    History: "History",
    Button: "Button",
    Calculation: "Berechnung",
    DateCalculation: "DateBerechnung",
    PasswordAuth: "PasswordAuth",
    ReadOnlyDataSourceGrid: "ReadonlyDataSourceGrid",
    File: "File",
    Assets: "Assets",
    Assignees: "Assignees",
    User: "User",
    RecurringTaskIndicator: "RecurringTaskIndicator",
  };
}

DataTypes.Bit = new DataTypes(DataTypes.type.Bit, (value) => {
  if (typeof value === "string") {
    return parseInt(value) === 1 ? "true" : "false";
  }
  return value ? "true" : "false";
});

DataTypes.Text = new DataTypes(DataTypes.type.Text, (value) => value);

DataTypes.Float = new DataTypes(DataTypes.type.Float, (value) => {
  const parsedFloat = parseFloat(value);
  if (isNaN(parsedFloat)) return "";
  return parseFloat(value).toLocaleString(i18n.language);
});

DataTypes.Calculation = new DataTypes(DataTypes.type.Calculation, (value) => {
  const parsedFloat = parseFloat(value);
  if (isNaN(parsedFloat)) return "";
  return parseFloat(value).toLocaleString(i18n.language);
});

DataTypes.Date = new DataTypes(DataTypes.type.Date, (value) => {
  if (value === undefined || value === null) {
    return value;
  }
  return format(
    parseISO(value),
    i18n.t("date-format.pickerDateFormatString", { ns: "common" }),
  );
});

DataTypes.Time = new DataTypes(DataTypes.type.Time, (value) => value);

DataTypes.DateTime = new DataTypes(DataTypes.type.DateTime, (value) => {
  if (value === undefined || value === null) {
    return value;
  }
  return format(
    parseISO(value),
    i18n.t("date-format.pickerDateTimeFormatString", { ns: "common" }),
  );
});

DataTypes.DateCalculation = new DataTypes(
  DataTypes.type.DateCalculation,
  (value) => {
    if (value === undefined || value === null) {
      return value;
    }
    return format(
      parseISO(value),
      i18n.t("date-format.pickerDateTimeFormatString", { ns: "common" }),
    );
  },
);

DataTypes.DataSource = new DataTypes(
  DataTypes.type.DataSource,
  (value) => value,
);

DataTypes.DataSourceGrid = new DataTypes(
  DataTypes.DataSourceGrid,
  (value) => value,
);

DataTypes.Status = new DataTypes(DataTypes.type.Status, (value, valueImage) => {
  if (valueImage === undefined) return value;
  return <StatusColumnView value={value} base64Image={valueImage} />;
});

DataTypes.Picture = new DataTypes(DataTypes.type.Picture, (value) => value);

DataTypes.TextMemo = new DataTypes(DataTypes.type.TextMemo, (value) => value);

DataTypes.History = new DataTypes(DataTypes.type.History, (value) => value);

DataTypes.File = new DataTypes(DataTypes.type.File, (dispatch, files) => {
  if (!files || files.length === 0) return null;

  const getElementPosition = (element) => {
    const elementClientRect = element.getBoundingClientRect();
    return {
      top: elementClientRect.bottom,
      left: elementClientRect.x - 200,
    };
  };

  const onClickFile = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      openFileDropdown({
        files: files,
        position: getElementPosition(e.target),
      }),
    );
  };

  return (
    <IconButton onClick={onClickFile}>
      {files.length > 1 ? (
        <Files size={16} data-cy={"files-icon"} />
      ) : (
        <File size={16} data-cy={"file-icon"} />
      )}
    </IconButton>
  );
});

DataTypes.RecurringTaskIndicator = new DataTypes(
  DataTypes.type.RecurringTaskIndicator,
  (dispatch, data) => {
    const onClick = () => {
      dispatch(data.action(data.target));
    };
    return data.target ? (
      <IconButton
        style={{ height: "24px", width: "24px" }}
        data-cy={"DataGridRecurringTaskButton"}
        onClick={onClick}
      >
        <data.icon size={16} />
      </IconButton>
    ) : (
      ""
    );
  },
);

DataTypes.Assets = new DataTypes(
  DataTypes.type.Assets,
  (assets, isGridCell) => (
    <AssetSelection disabled={true} value={assets} isGridCell={isGridCell} />
  ),
);

DataTypes.Assignees = new DataTypes(
  DataTypes.type.Assignees,
  (assignees, isGridCell) => (
    <UserAndGroupSelection
      disabled={true}
      value={assignees}
      isGridCell={isGridCell}
    />
  ),
);

DataTypes.User = new DataTypes(DataTypes.type.User, (guid) =>
  guid ? <UserGuidToName guid={guid} /> : <>&nbsp;</>,
);

DataTypes.parse = (typeString) => {
  switch (typeString) {
    case DataTypes.type.Status:
      return DataTypes.Status;
    case DataTypes.type.Bit:
      return DataTypes.Bit;
    case DataTypes.type.Text:
      return DataTypes.Text;
    case DataTypes.type.Float:
      return DataTypes.Float;
    case DataTypes.type.Calculation:
      return DataTypes.Calculation;
    case DataTypes.type.Date:
      return DataTypes.Date;
    case DataTypes.type.Time:
      return DataTypes.Time;
    case DataTypes.type.DateTime:
      return DataTypes.DateTime;
    case DataTypes.type.DataSource:
      return DataTypes.DataSource;
    case DataTypes.type.DataSourceGrid:
      return DataTypes.DataSourceGrid;
    case DataTypes.type.Picture:
      return DataTypes.Picture;
    case DataTypes.type.TextMemo:
      return DataTypes.TextMemo;
    case DataTypes.type.History:
      return DataTypes.History;
    case DataTypes.type.File:
      return DataTypes.File;
    case DataTypes.type.DateCalculation:
      return DataTypes.DateCalculation;
    case DataTypes.type.RecurringTaskIndicator:
      return DataTypes.RecurringTaskIndicator;
    default:
      return DataTypes.Text;
  }
};
