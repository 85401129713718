import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import _ from "lodash";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery();

export const refreshEndpoint = { endpoint: undefined };

export function decodeJWT(token) {
  const base64Url = token.split(".")[1];
  return JSON.parse(window.atob(base64Url));
}

export const isTokenExpired = (expires) => {
  if (!expires) return true;
  return (
    expires * 1000 <=
    new Date().getTime() + window.TokenExpirySafetyMargin * 1000
  );
};

const addTokenToHeaders = (token, args) => {
  if (!args.headers) args.headers = {};
  args.headers["Authorization"] = `Bearer ${token}`;
};

const refreshToken = async (state, args, api) => {
  if (!refreshEndpoint.endpoint)
    console.error("token refresh endpoint undefined");
  if (!mutex.isLocked()) {
    const release = await mutex.acquire();
    const promise = api.dispatch(refreshEndpoint.endpoint.initiate());
    const result = await promise;
    // Reset to prevent caching of response data
    promise.reset();
    release();
    return result;
  } else {
    await mutex.waitForUnlock();
  }
};

export const dynamicBaseQuery = async (args, api) => {
  const { path, token, endpoint, urlSuffix, ...adjustedArgs } = args;
  if (token && token !== "refresh") await mutex.waitForUnlock();
  let state = api.getState();
  // TODO works for now but should be refactored
  //  Issue: https://gitlab.imes-solutions.com/plant-historian/web/client/-/issues/1002
  if (endpoint) {
    const promise = api.dispatch(endpoint.initiate());
    const { data } = await promise;
    if (data) adjustedArgs.url = _.get(data, path);
    promise.unsubscribe();
  } else if (path) {
    adjustedArgs.url = _.get(state, path);
  }
  if (urlSuffix) adjustedArgs.url += urlSuffix;

  if (token) {
    if (token === "access" && isTokenExpired(state.auth.expires)) {
      const refreshResult = await refreshToken(state, {}, api);
      if (refreshResult?.error) return refreshResult;
    }
    state = api.getState();
    const token_data =
      token === "refresh"
        ? localStorage.getItem("refreshToken")
        : state.auth.access_token;
    addTokenToHeaders(token_data, adjustedArgs);
  }
  return baseQuery(adjustedArgs, api, {});
};
