import clsx from "clsx";
import IconButton from "../../IconButton";
import { ChevronDown, ChevronRight } from "react-bootstrap-icons";
import React from "react";
import PropTypes from "prop-types";

export default function TreeListExpandButton(props) {
  const Icon = props.open ? ChevronDown : ChevronRight;

  // We are always rendering the button and conditionally use "invisible" to keep the spacing it creates
  return (
    <IconButton
      className={clsx("d-inline me-1", { invisible: !props.hasChildren })}
      onClick={props.onToggleExpanded}
    >
      <Icon className={"mb-1"} />
    </IconButton>
  );
}

TreeListExpandButton.propTypes = {
  hasChildren: PropTypes.bool,
  onToggleExpanded: PropTypes.func,
  open: PropTypes.bool,
};
