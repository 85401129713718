import getDecimalSeparator from "./getDecimalSeparator";

const parseNumber = (value) => {
  const decimalSign = getDecimalSeparator();
  if (!value || value === decimalSign) return 0;
  const valueWithDecimalSign = value
    .replace(new RegExp(`(?!^-)[^${decimalSign}\\d]`, "g"), "")
    .replace(decimalSign, ".");
  const numberDecimalSignIndex = valueWithDecimalSign.indexOf(".");

  let valueWithoutInvalidChars = valueWithDecimalSign;
  if (
    numberDecimalSignIndex !== -1 &&
    numberDecimalSignIndex !== valueWithDecimalSign.length - 1
  ) {
    const splitAtNumberDecimalSign = valueWithDecimalSign.substring(
      numberDecimalSignIndex + 1,
    );
    valueWithoutInvalidChars =
      valueWithDecimalSign.substring(0, numberDecimalSignIndex + 1) +
      splitAtNumberDecimalSign.replaceAll(decimalSign, "");
  }

  return parseFloat(valueWithoutInvalidChars);
};

export default parseNumber;
