import {
  Icon as TasksIcon,
  path,
  TASKS,
} from "public_shiftbook/public_tasks/info";
import React from "react";
import BaseQuickNavItem from "basics/components/QuickNav/QuickNavItem";
import { useGetUnreadTaskCountQuery } from "../services/tasksApi";

export default function QuickNavItem() {
  const { data: unreadTasksCount } = useGetUnreadTaskCountQuery();

  return (
    <BaseQuickNavItem
      name={TASKS}
      icon={TasksIcon}
      to={path}
      badge={unreadTasksCount}
    />
  );
}
