import React, { useRef, useState } from "react";
import { TextareaAutosize } from "@mui/material";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import ExpandBadge from "./ExpandBadge";
import { css } from "@emotion/css";
import { Col, Form, Row } from "react-bootstrap";

const AnimatedTextarea = styled(TextareaAutosize)`
  background-color: white !important;
  resize: none;
  background-image: none;
  margin-bottom: 0 !important;
`;

function ExpandableTextArea(props) {
  const {
    className,
    style,
    value,
    name,
    onChange,
    onSelect,
    dataCy,
    minRows,
    disabled,
    placeholder,
    isValid,
    isInvalid,
    maxRows,
    label,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const [focused, setFocused] = useState(false);
  const [doTransition, setDoTransition] = useState(false);
  const textAreaRef = useRef(null);

  const hasScrollbar =
    textAreaRef.current?.clientHeight < textAreaRef.current?.scrollHeight;

  const displayChip = hasScrollbar || expanded || doTransition;

  function getMaxRows() {
    if (expanded && displayChip) return 0;
    // Don't get confused by the numbers. According to my experience with this component 4 rows equals 6 readable lines of text.
    return maxRows ? maxRows : 4;
  }

  function onTransitionEnd() {
    setDoTransition(false);
  }

  function onExpand(expanded) {
    setExpanded(expanded);
    setDoTransition(true);
  }

  const getClassName = () => {
    let resultClassName = "form-control";
    if (!(focused || expanded) && (!placeholder || value) && hasScrollbar)
      resultClassName +=
        " " +
        css`
          overflow: hidden !important;
          mask-image: linear-gradient(
              rgba(0, 0, 0, 1),
              rgba(0, 0, 0, 0) 99%,
              rgba(0, 0, 0, 1)
            ),
            linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 1%),
            linear-gradient(to right, rgba(0, 0, 0, 0) 99%, rgba(0, 0, 0, 1));
        `;
    if (isValid && !disabled) resultClassName += " is-valid";
    if (isInvalid && !disabled) resultClassName += " is-invalid";
    if (doTransition)
      resultClassName +=
        " " +
        css`
          transition: height 0.25s ease-in-out !important;
        `;
    return resultClassName;
  };

  const wrapInColIfHorizontal = (elements) => {
    if (props.horizontal && props.label)
      return (
        <Col xs={12} lg={10}>
          {elements}
        </Col>
      );
    return elements;
  };
  return (
    <Form.Group
      as={props.horizontal && props.label ? Row : undefined}
      className={className}
    >
      {label && (
        <Form.Label column={props.horizontal} xs={12} lg={2}>
          {label}
        </Form.Label>
      )}
      {wrapInColIfHorizontal(
        <>
          <AnimatedTextarea
            className={getClassName()}
            style={style}
            name={name}
            value={value}
            onChange={onChange}
            ref={textAreaRef} //I believe using a proper ref here is more in line with what was intended
            onSelect={onSelect ? () => onSelect(textAreaRef) : undefined}
            data-cy={dataCy}
            maxRows={getMaxRows()}
            minRows={minRows || 3}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            disabled={disabled}
            placeholder={placeholder}
            onTransitionEnd={onTransitionEnd}
          />
          {!focused && displayChip && (
            <ExpandBadge expanded={expanded} onSetExpanded={onExpand} />
          )}
        </>,
      )}
    </Form.Group>
  );
}

export default ExpandableTextArea;

ExpandableTextArea.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  onBlurCapture: PropTypes.func,
  dataCy: PropTypes.string,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  horizontal: PropTypes.bool,
};
