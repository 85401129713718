import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "basics/dynamicBaseQuery";

export const layoutsApi = createApi({
  reducerPath: "layoutsApi",
  baseQuery: dynamicBaseQuery,
  tagTypes: ["Layouts"],
  endpoints: (builder) => ({
    getAvailableLayouts: builder.query({
      query: ({ entity }) => ({
        url: entity._links.get_layouts.href,
        token: "access",
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue.layouts;
      },
      serializeQueryArgs: ({ queryArgs, endpointName }) =>
        `${endpointName}(${queryArgs.entity?.guid})`,
      providesTags: ["Layouts"],
    }),
    addEnterpriseLayout: builder.mutation({
      query: ({ entity, body }) => ({
        url: entity._links.create_layout_enterprise.href,
        body: body,
        method: "POST",
        token: "access",
      }),
      invalidatesTags: ["Layouts"],
    }),
    addUserLayout: builder.mutation({
      query: ({ entity, body }) => ({
        url: entity._links.create_layout_user.href,
        body: body,
        method: "POST",
        token: "access",
      }),
      invalidatesTags: ["Layouts"],
    }),
    deleteLayout: builder.mutation({
      query: ({ layout }) => ({
        url: layout._links.delete.href,
        method: "DELETE",
        token: "access",
      }),
      invalidatesTags: ["Layouts"],
    }),
    changeLayoutName: builder.mutation({
      query: ({ layout, body }) => ({
        url: layout._links.change_name.href,
        method: "PUT",
        token: "access",
        body: body,
      }),
      invalidatesTags: ["Layouts"],
    }),
    changeLayoutData: builder.mutation({
      query: ({ layout, body }) => ({
        url: layout._links.change_data.href,
        method: "PUT",
        token: "access",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetAvailableLayoutsQuery,
  useAddEnterpriseLayoutMutation,
  useAddUserLayoutMutation,
  useDeleteLayoutMutation,
  useChangeLayoutNameMutation,
  useChangeLayoutDataMutation,
} = layoutsApi;
