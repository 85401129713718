import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "basics/dynamicBaseQuery";
import { metaApi } from "basics/services/metaApi";
import { createUrlFromTemplate } from "public_basics/createUrlFromTemplate";
import { createSocketConnector } from "basics/createSocketConnector";

export const entryMaskSocketConnector = createSocketConnector("", {
  errorMessage: {
    message: "entries-socket-connect-error",
    ns: "entry-mask",
  },
  successMessage: {
    message: "entries-socket-reconnect-success",
    ns: "entry-mask",
  },
});

export const entryMaskApi = createApi({
  reducerPath: "entryMaskApi",
  baseQuery: dynamicBaseQuery,
  tagTypes: [
    "EntryMasks",
    "EntryMaskGroups",
    "EntryMaskConfig",
    "TextModules",
    "Entry",
    "EntryHistory",
    "EntryLinkInfo",
    "Icons",
  ],
  endpoints: (builder) => ({
    /**
     * Entry Masks
     */

    getEntryMaskUris: builder.query({
      query: () => ({
        endpoint: metaApi.endpoints.getBaseUris,
        path: "shiftbook.href",
      }),
    }),
    getEntryMasks: builder.query({
      query: ({ meta }) => ({
        path: `_links.books.${meta.visibility}.href`,
        endpoint: entryMaskApi.endpoints.getEntryMaskUris,
        token: "access",
      }),
      providesTags: ["EntryMasks"],
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue.data;
      },
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, cacheEntryRemoved, dispatch },
      ) {
        const listener = () =>
          dispatch(entryMaskApi.util.invalidateTags(["EntryMasks"]));
        try {
          await cacheDataLoaded;
          await entryMaskSocketConnector.on(
            "relevant_entries_changed",
            listener,
          );
        } catch {}
        await cacheEntryRemoved;
        entryMaskSocketConnector.off("relevant_entries_changed", listener);
      },
    }),
    getEntryMaskGroups: builder.query({
      query: ({ meta }) => ({
        path: `_links.books.${meta.visibility}.href`,
        endpoint: entryMaskApi.endpoints.getEntryMaskUris,
        token: "access",
      }),
      providesTags: ["EntryMaskGroups"],
    }),
    getEntryMaskConfig: builder.query({
      query: ({ entryMask }) => ({
        url: entryMask._links.configuration.href,
        token: "access",
      }),
      serializeQueryArgs: ({ queryArgs, endpointName }) =>
        `${endpointName}(${queryArgs.entryMask?.guid})`,
      providesTags: ["EntryMaskConfig"],
      transformResponse(baseQueryReturnValue) {
        return {
          ...baseQueryReturnValue.data,
          _links: baseQueryReturnValue._links,
        };
      },
    }),
    getTextModules: builder.query({
      query: ({ entryMask }) => ({
        url: entryMask._links.text_modules.href,
        token: "access",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["TextModules"],
    }),
    addTextModule: builder.mutation({
      query: ({ entryMask, body }) => ({
        url: entryMask._links.text_modules.href,
        method: "POST",
        token: "access",
        body: body,
      }),
      invalidatesTags: ["TextModules"],
    }),
    editTextModule: builder.mutation({
      query: ({ textModule, body }) => ({
        url: textModule._links.self.href,
        method: "PUT",
        token: "access",
        body: body,
      }),
      invalidatesTags: ["TextModules"],
    }),
    deleteTextModule: builder.mutation({
      query: ({ textModule }) => ({
        url: textModule._links.self.href,
        method: "DELETE",
        token: "access",
      }),
      invalidatesTags: ["TextModules"],
    }),
    removeEntryMaskFromGroup: builder.mutation({
      query: ({ entryMask, params }) => ({
        url: entryMask._links.group_assignment.href,
        method: "DELETE",
        token: "access",
        params: params,
      }),
      invalidatesTags: ["EntryMaskGroups", "EntryMasks"],
    }),
    deleteEntryMask: builder.mutation({
      query: ({ entryMask }) => ({
        url: entryMask._links.self.href,
        method: "DELETE",
        token: "access",
      }),
      invalidatesTags: ["EntryMaskGroups", "EntryMasks"],
    }),
    addEntryMaskGroup: builder.mutation({
      query: ({ body }) => ({
        path: "_links.books.groups.href",
        endpoint: entryMaskApi.endpoints.getEntryMaskUris,
        method: "POST",
        token: "access",
        body: body,
      }),
      invalidatesTags: ["EntryMaskGroups"],
    }),
    editEntryMaskGroup: builder.mutation({
      query: ({ entryMaskGroup, body }) => ({
        url: entryMaskGroup._links.self.href,
        method: "PUT",
        token: "access",
        body: body,
      }),
      invalidatesTags: ["EntryMaskGroups"],
    }),
    deleteEntryMaskGroup: builder.mutation({
      query: ({ entryMaskGroup }) => ({
        url: entryMaskGroup._links.self.href,
        method: "DELETE",
        token: "access",
      }),
      invalidatesTags: ["EntryMaskGroups"],
    }),
    getIcons: builder.query({
      query: () => ({
        path: "_links.icons.href",
        endpoint: entryMaskApi.endpoints.getEntryMaskUris,
        token: "access",
      }),
      providesTags: ["Icons"],
    }),
    addIcon: builder.mutation({
      query: ({ body }) => ({
        path: "_links.icons.href",
        endpoint: entryMaskApi.endpoints.getEntryMaskUris,
        method: "POST",
        token: "access",
        body: body,
      }),
      invalidatesTags: ["Icons"],
    }),
    deleteIcon: builder.mutation({
      query: ({ icon }) => ({
        url: icon._links.self.href,
        method: "DELETE",
        token: "access",
      }),
      invalidatesTags: ["Icons"],
    }),
    updateEntryMaskConfiguration: builder.mutation({
      query: ({ entryMask, body }) => ({
        url: entryMask._links.self.href,
        method: "PUT",
        token: "access",
        body: body,
      }),
      invalidatesTags: ["EntryMasks", "EntryMaskConfig"],
    }),
    setEntryMaskActive: builder.mutation({
      query: ({ entryMask, body }) => ({
        url: entryMask._links.status.href,
        method: "PUT",
        token: "access",
        body: body,
      }),
      invalidatesTags: ["EntryMasks", "EntryMaskConfig"],
    }),
    assignEntryMaskToGroup: builder.mutation({
      query: ({ entryMask, body }) => ({
        url: entryMask._links.group_assignment.href,
        method: "POST",
        token: "access",
        body: body,
      }),
      invalidatesTags: ["EntryMaskGroups", "EntryMasks"],
    }),

    /**
     * Entries
     */

    getEntryMaskEntry: builder.query({
      query: ({ entryMask, meta }) => ({
        // We have to build the url here because we have no access to entries that are not in the current time-range
        url: createUrlFromTemplate(entryMask._links.get_entry.href, {
          entryId: meta.entryId,
        }),
        method: "GET",
        token: "access",
      }),
      providesTags: ["Entry"],
    }),
    getEntryMaskEntryHistory: builder.query({
      query: ({ entry }) => ({
        url: entry._links.history.href,
        method: "GET",
        token: "access",
      }),
      providesTags: ["EntryHistory"],
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue.data;
      },
    }),
    getEntryMaskEntryLinkInfo: builder.query({
      query: ({ entry }) => {
        return {
          url: entry._links.links.get.href,
          method: "GET",
          token: "access",
        };
      },
      providesTags: ["EntryLinkInfo"],
    }),
    addEntryMaskEntry: builder.mutation({
      query: ({ entryMask, body }) => ({
        url: entryMask._links.entries.create.href,
        method: "POST",
        token: "access",
        body: body,
      }),
    }),
    editEntryMaskEntry: builder.mutation({
      query: ({ entry, body }) => ({
        url: entry._links.edit.href,
        method: "PUT",
        token: "access",
        body: body,
      }),
      invalidatesTags: ["Entry", "EntryHistory", "EntryLinkInfo"],
    }),
    deleteEntryMaskEntry: builder.mutation({
      query: ({ entry }) => ({
        url: entry.links.deleteEntry,
        method: "DELETE",
        token: "access",
      }),
      invalidatesTags: ["Entry", "EntryHistory", "EntryLinkInfo"],
    }),
    splitEntryMaskEntry: builder.mutation({
      query: ({ entry, body }) => ({
        url: entry._links.split.href,
        method: "POST",
        token: "access",
        body: body,
      }),
      invalidatesTags: ["Entry", "EntryHistory", "EntryLinkInfo"],
    }),
  }),
});

entryMaskSocketConnector.endpoint = entryMaskApi.endpoints.getEntryMaskUris;

export const {
  useGetEntryMasksQuery,
  useGetEntryMaskGroupsQuery,
  useGetEntryMaskConfigQuery,
  useGetTextModulesQuery,
  useAddTextModuleMutation,
  useEditTextModuleMutation,
  useDeleteTextModuleMutation,
  useRemoveEntryMaskFromGroupMutation,
  useUpdateEntryMaskConfigurationMutation,
  useSetEntryMaskActiveMutation,
  useAssignEntryMaskToGroupMutation,
  useDeleteEntryMaskMutation,
  useDeleteEntryMaskGroupMutation,
  useAddEntryMaskGroupMutation,
  useEditEntryMaskGroupMutation,
  useGetIconsQuery,
  useAddIconMutation,
  useDeleteIconMutation,
  useGetEntryMaskEntryQuery,
  useGetEntryMaskEntryHistoryQuery,
  useGetEntryMaskEntryLinkInfoQuery,
  useAddEntryMaskEntryMutation,
  useDeleteEntryMaskEntryMutation,
  useEditEntryMaskEntryMutation,
  useSplitEntryMaskEntryMutation,
} = entryMaskApi;
