import styles from "./CustomPopover.module.scss";
import { Popover } from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";

export default function CustomPopoverHeader(props) {
  return (
    <Popover.Header
      bsPrefix={styles.customPopoverHeader}
      className={props.className}
    >
      <div>
        <h5>{props.title}</h5>
      </div>
      {props.children}
    </Popover.Header>
  );
}

CustomPopoverHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
};
