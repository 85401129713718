import CustomPopover from "../CustomPopover/CustomPopover";
import { Tab } from "react-bootstrap";
import React, { useState } from "react";
import TabbedCustomPopoverHeader from "./TabbedCustomPopoverHeader";
import TabbedCustomPopoverBody from "./TabbedCustomPopoverBody";
import PropTypes from "prop-types";
import itemsPropType from "./itemsPropType";

export default function TabbedCustomPopover(props) {
  const [currentTab, setCurrentTab] = useState(props.defaultActiveKey);

  return (
    <CustomPopover
      {...props}
      popoverContent={
        <Tab.Container
          defaultActiveKey={currentTab}
          onSelect={(tab) => {
            setCurrentTab(tab);
            props.onSelect && props.onSelect(tab);
          }}
          className={props.classNames?.popover}
        >
          <TabbedCustomPopoverHeader
            items={props.items}
            className={props.classNames?.popoverHeader}
            title={props.title}
          />
          <TabbedCustomPopoverBody
            items={props.items}
            classNames={{
              popoverBody: props.classNames?.popoverBody,
              tabContent: props.classNames?.tabContent,
            }}
          />
          {props.footer}
        </Tab.Container>
      }
    />
  );
}

TabbedCustomPopover.propTypes = {
  items: itemsPropType,
  classNames: PropTypes.shape({
    popover: PropTypes.string,
    popoverHeader: PropTypes.string,
    popoverBody: PropTypes.string,
    tabContent: PropTypes.string,
  }),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
