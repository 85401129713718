import { ModuleModel } from "@imes/module-host";
import { BASICS } from "public_basics/info";
import {
  ASSET_SELECTION,
  BASE_ENTRY_LINK,
  CHANGE_PASSWORD_DIALOG,
  CHIP,
  CONDITIONAL_OVERLAY,
  CONFIRM_DELETE_DIALOG,
  CONFIRM_DIALOG,
  CUSTOM_MODAL,
  CUSTOMIZED_SELECT,
  DATA_GRID_FABS,
  DATA_GRID_WRAPPER,
  DATE_TIME_PICKER_INPUT,
  DYNAMIC_ICON,
  ENTRY_LINKS,
  EXPAND_BADGE,
  EXPANDABLE_TEXT_AREA,
  FLOATING_ACTION_BUTTON,
  ICON_BUTTON,
  INFO_POPOVER,
  NAVIGATION_ITEM,
  NAVIGATION_LINK,
  NUMBER_INPUT,
  PICTURE_GALLERY,
  POSITIONED_DROPDOWN,
  REFRESH_BUTTON,
  RELEVANT_ENTRIES_COUNT,
  SKELETON_DATA_GRID,
  STATUS_COLUMN_VIEW,
  TABLE_SELECT,
  TEXT_SELECT,
  TIME_FILTER,
  TOOLTIP,
  TRANSITION_ALERT,
  TREE_LIST,
  USER_AND_GROUP_SELECTION,
  USER_GUID_TO_NAME,
} from "public_basics/components";
import { lazy } from "react";
import StatusColumnView from "./components/StatusColumnView";
import TransitionAlert from "./components/TransitionAlert";
import FloatingActionButton from "./components/FloatingActionButton";
import DateTimePickerInput from "./components/DateTimePickerInput";
import IconButton from "./IconButton";
import CustomModal from "./components/CustomModal/CustomModal";
import ConfirmDeleteDialog from "./components/ConfirmDialog/ConfirmDeleteDialog";
import ConfirmDialog from "./components/ConfirmDialog/ConfirmDialog";
import ExpandableTextArea from "./components/ExpandableTextArea/ExpandableTextArea";
import ExpandBadge from "./components/ExpandableTextArea/ExpandBadge";
import PositionedDropdown from "./components/PositionedDropdown";
import Tooltip from "./components/Tooltip";
import AssetSelection from "./components/Assets/AssetSelection";
import TableSelect from "./components/Selects/TableSelect";
import TextSelect from "./components/Selects/TextSelect";
import SkeletonDataGrid from "./components/DataGridWrapper/SkeletonDataGrid";
import CustomizedSelect from "./components/Selects/CustomizedSelect";
import RelevantEntriesCount from "./components/RelevantEntriesCount";
import NavigationItem from "./components/Navigation/NavigationItem";
import NavigationLink from "./components/Navigation/NavigationLink";
import RefreshButton from "./components/Refresh/RefreshButton";
import UserGuidToName from "./components/Users/UserGuidToName";
import DynamicIcon from "./components/DynamicIcon";
import PictureGallery from "./components/PictureGallery/PictureGallery";
import ConditionalOverlay from "./components/ConditionalOverlay";
import InfoPopover from "./components/InfoPopover";
import Chip from "./components/Chip";
import NumberInput from "./components/NumberInput/NumberInput";
import ChangePasswordDialog from "./components/ChangePassword/ChangePasswordDialog";
import TimeFilter from "./components/TimeFilter/TimeFilter";
import DataGridFabs from "./components/DataGridFabs";
import UserAndGroupSelection from "./components/Users/UserAndGroupSelection";
import EntryLinks from "./components/EntryLinks/EntryLinks";
import BaseEntryLink from "./components/EntryLinks/BaseEntryLink";
import TreeList from "./components/TreeList/TreeList";
import { metaApi } from "./services/metaApi";
import { authApi } from "./services/authApi";
import { assetApi } from "./services/assetApi";
import { loggingApi } from "./services/loggingApi";
import { entryLinksApi } from "./services/entryLinksApi";
import { shiftApi } from "./services/shiftApi";
import { mediaApi } from "./services/mediaApi";
import authSlice from "./slices/authSlice";
import addErrorListeners from "basics/listeners/errorListener";
import addAuthListeners from "basics/listeners/authListener";
import { dataGridApi } from "./components/DataGridWrapper/dataGridApi";
import metaSlice from "./slices/metaSlice";
import { pwaSlice } from "basics/slices/pwaSlice";
import { userSettingsSlice } from "basics/slices/userSettingsSlice";
import { currentLocationSlice } from "basics/slices/currentLocationSlice";

const DataGridWrapper = lazy(
  () => import("basics/components/DataGridWrapper/DataGridWrapper"),
);

const plantHistorianBasics = new ModuleModel(BASICS);

plantHistorianBasics.publicComponents = {
  [STATUS_COLUMN_VIEW]: StatusColumnView,
  [TRANSITION_ALERT]: TransitionAlert,
  [FLOATING_ACTION_BUTTON]: FloatingActionButton,
  [DATE_TIME_PICKER_INPUT]: DateTimePickerInput,
  [ICON_BUTTON]: IconButton,
  [DATA_GRID_WRAPPER]: DataGridWrapper,
  [CUSTOM_MODAL]: CustomModal,
  [CONFIRM_DELETE_DIALOG]: ConfirmDeleteDialog,
  [CONFIRM_DIALOG]: ConfirmDialog,
  [EXPANDABLE_TEXT_AREA]: ExpandableTextArea,
  [EXPAND_BADGE]: ExpandBadge,
  [POSITIONED_DROPDOWN]: PositionedDropdown,
  [TOOLTIP]: Tooltip,
  [ASSET_SELECTION]: AssetSelection,
  [TABLE_SELECT]: TableSelect,
  [TEXT_SELECT]: TextSelect,
  [SKELETON_DATA_GRID]: SkeletonDataGrid,
  [CUSTOMIZED_SELECT]: CustomizedSelect,
  [RELEVANT_ENTRIES_COUNT]: RelevantEntriesCount,
  [NAVIGATION_ITEM]: NavigationItem,
  [NAVIGATION_LINK]: NavigationLink,
  [RELEVANT_ENTRIES_COUNT]: RelevantEntriesCount,
  [USER_AND_GROUP_SELECTION]: UserAndGroupSelection,
  [REFRESH_BUTTON]: RefreshButton,
  [USER_GUID_TO_NAME]: UserGuidToName,
  [DYNAMIC_ICON]: DynamicIcon,
  [PICTURE_GALLERY]: PictureGallery,
  [CONDITIONAL_OVERLAY]: ConditionalOverlay,
  [INFO_POPOVER]: InfoPopover,
  [CHIP]: Chip,
  [NUMBER_INPUT]: NumberInput,
  [CHANGE_PASSWORD_DIALOG]: ChangePasswordDialog,
  [TIME_FILTER]: TimeFilter,
  [DATA_GRID_FABS]: DataGridFabs,
  [ENTRY_LINKS]: EntryLinks,
  [BASE_ENTRY_LINK]: BaseEntryLink,
  [TREE_LIST]: TreeList,
};

plantHistorianBasics.slices = [
  metaApi,
  authApi,
  assetApi,
  entryLinksApi,
  loggingApi,
  shiftApi,
  mediaApi,
  authSlice,
  dataGridApi,
  metaSlice,
  pwaSlice,
  userSettingsSlice,
  currentLocationSlice,
];
plantHistorianBasics.middlewares = [
  mediaApi.middleware,
  authApi.middleware,
  metaApi.middleware,
  entryLinksApi.middleware,
  assetApi.middleware,
  loggingApi.middleware,
  shiftApi.middleware,
  dataGridApi.middleware,
];

plantHistorianBasics.addListeners = (startListening) => {
  addAuthListeners(startListening);
  addErrorListeners(startListening);
};

export default plantHistorianBasics;
