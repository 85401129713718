import styles from "basics/components/Navigation/LeftNavigation.module.scss";
import PropTypes from "prop-types";
import React from "react";
import { Badge } from "react-bootstrap";
import { CaretLeftFill } from "react-bootstrap-icons";
import { Link, NavLink as BaseNavLink } from "react-router-dom";

function NavigationLink(props) {
  const onClickLink = (e) => {
    if (props.onClick) {
      e.preventDefault();
      props.onClick(e);
    }
  };
  const getClassName = () => {
    return [styles.navLink, props.isActive ? "active" : 0]
      .filter(Boolean)
      .join(" ");
  };

  // caret means it's the parent of a NavGroup and not actually a link
  if (props.caret)
    return (
      <div
        onClick={onClickLink}
        className={styles.navLink}
        data-cy={"NavigationLink"}
      >
        <NavigationLinkContent
          name={props.name}
          icon={props.icon}
          badge={props.badge}
          caret={true}
        />
      </div>
    );
  else if (props.isActive !== undefined)
    return (
      <Link
        to={props.to}
        className={getClassName()}
        onClick={onClickLink}
        data-cy={"NavigationLink"}
        data-toggle={"tooltip"}
        title={props.name}
        data-placement={"top"}
      >
        <NavigationLinkContent
          name={props.name}
          icon={props.icon}
          badge={props.badge}
        />
      </Link>
    );
  else
    return (
      <BaseNavLink
        to={props.to}
        end={props.end}
        className={styles.navLink}
        onClick={onClickLink}
        data-cy={"NavigationLink"}
        data-toggle={"tooltip"}
        title={props.name}
        data-placement={"top"}
      >
        <NavigationLinkContent
          name={props.name}
          icon={props.icon}
          badge={props.badge}
        />
      </BaseNavLink>
    );
}

NavigationLink.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  end: PropTypes.bool,
  badge: PropTypes.number,
  icon: PropTypes.elementType,
  onClick: PropTypes.func,
  caret: PropTypes.bool,
  isActive: PropTypes.bool,
};

export default NavigationLink;

function NavigationLinkContent({ icon: Icon, ...props }) {
  return (
    <>
      <div>
        <Icon size={25} />
      </div>
      <span className={"text-truncate"}>{props.name}</span>
      {!!props.badge && (
        <Badge pill bg={"secondary"} className={"ms-2"}>
          {props.badge}
        </Badge>
      )}
      {props.caret && <CaretLeftFill className={`ms-2 ${styles.caret}`} />}
    </>
  );
}

NavigationLinkContent.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  badge: PropTypes.number,
  caret: PropTypes.bool,
};
