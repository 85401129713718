import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectSortedLocations } from "basics/slices/currentLocationSlice";

/**
 * Returns a string representation of the current location.
 * The individual parts are in ascending order regarding their depth (deepest element is last).
 *
 * @param {boolean} [reversed=false] - Concatenate locations in reversed order.
 * @return {string}
 */
export default function useLocationDisplayString(reversed = false) {
  const translatedLocations = useSelector(selectSortedLocations);

  return useMemo(() => {
    const locations = [
      window.APPLICATION_NAME,
      ...translatedLocations.filter(Boolean),
    ];
    if (reversed) locations.reverse();
    return locations.join(" - ");
  }, [reversed, translatedLocations]);
}
