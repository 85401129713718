import React, { useState } from "react";
import LinkEntryDataGrid from "./EntryMaskEntryList/LinkEntryDataGrid";
import TargetEntryMaskSelector from "entrymask/components/AddEntryDialog/components/CopyEntryDialog/components/TargetEntryMaskSelector";
import PropTypes from "prop-types";

export default function EntryMaskEntrySelect(props) {
  const [selectedEntryMask, setSelectedEntryMask] = useState(null);

  const changeSelectedBook = (option) => {
    setSelectedEntryMask(option.value);
  };

  return (
    <>
      <TargetEntryMaskSelector
        onChange={changeSelectedBook}
        value={selectedEntryMask?.value}
      ></TargetEntryMaskSelector>
      {selectedEntryMask && (
        <LinkEntryDataGrid
          onChange={props.onChange}
          selectedEntryMaskId={selectedEntryMask?.id}
        />
      )}
    </>
  );
}

EntryMaskEntrySelect.propTypes = {
  onChange: PropTypes.func,
};
