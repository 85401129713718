import adminModule from "administration/module";
import dashboardModule from "dashboard/module";
import entryMaskModule from "entrymask/module";
import taskListModule from "shiftbook/modules/tasks/module";
import userProfileModule from "userProfile/module";

const modules = [
  entryMaskModule,
  dashboardModule,
  taskListModule,
  adminModule,
  userProfileModule,
];

export default modules;
