import PropTypes from "prop-types";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { SkeletonDataGrid } from "public_basics/components";

function SkeletonEntryMaskEntryList(props) {
  return (
    <div className="w-100 h-100">
      <div className="d-flex flex-column col-12 overflow-hidden px-1 pt-1">
        <div className="d-flex flex-row justify-content-between align-items-center pt-3 pb-2">
          <h2 className="d-none d-md-inline m-0">
            <Skeleton width={300} />
          </h2>
          <div className="flex-shrink-0 mb-1">
            <Skeleton height={30} width={80} />
          </div>
        </div>
        <SkeletonDataGrid columns={5} rows={props.rows} />
      </div>
    </div>
  );
}

SkeletonEntryMaskEntryList.propTypes = {
  rows: PropTypes.number,
};

SkeletonEntryMaskEntryList.defaultProps = {
  rows: 60,
};

export default SkeletonEntryMaskEntryList;
