import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "basics/dynamicBaseQuery";

export const mediaApi = createApi({
  reducerPath: "mediaApi",
  baseQuery: dynamicBaseQuery,
  tagTypes: ["File"],
  endpoints: (builder) => ({
    uploadFiles: builder.mutation({
      query: ({ url, files }) => {
        const formData = new FormData();
        for (const file of files) {
          formData.append(file.name, file);
        }
        return {
          url: url,
          token: "access",
          method: "POST",
          body: formData,
          formData: true,
        };
      },
    }),
    getFile: builder.query({
      query: (url) => ({
        url: url,
        token: "access",
      }),
      providesTags: ["File"],
    }),
    downloadFile: builder.mutation({
      query: ({ file, params }) => {
        return {
          url: file.fileUri || file._links.self.href,
          token: "access",
          params: params,
          responseHandler: async (response) => {
            if (response.status === 200) {
              const data = await response.blob();
              const new_blob = new Blob([data], {
                type: response.headers["content-type"],
              });
              const url = window.URL.createObjectURL(new_blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = `${file.file_name || file.fileName}.${
                file.file_type || file.fileType
              }`;
              a.click();
            }
          },
          cache: "no-cache",
        };

      },
    }),
    getPicture: builder.query({
      query: (url) => ({
        url,
        token: "access",
        responseHandler: async (response) => {
          const data = await response.blob();
          const new_blob = new Blob([data], {
            type: response.headers["content-type"],
          });
          return window.URL.createObjectURL(new_blob);
        },
      }),
    }),
  }),
});

export const {
  useUploadFilesMutation,
  useDownloadFileMutation,
  useGetPictureQuery,
} = mediaApi;
