import { CaretDown, CaretRight, CaretUp } from "react-bootstrap-icons";

const TaskPriority = {
  low: "low",
  medium: "medium",
  high: "high",
};

export const Icons = {
  [TaskPriority.high]: {
    Icon: CaretUp,
    variant: "danger",
  },
  [TaskPriority.medium]: {
    Icon: CaretRight,
    variant: "secondary",
  },
  [TaskPriority.low]: {
    Icon: CaretDown,
    variant: "primary",
  },
};

Object.freeze(TaskPriority);
Object.freeze(Icons);

export default TaskPriority;
