export default class LinksModel {
  getGroups;
  getEntries;
  getFilterValues;

  constructor(getGroups, getEntries, getFilterValues) {
    this.getEntries = getEntries;
    this.getGroups = getGroups;
    this.getFilterValues = getFilterValues;
  }
}
