import { route as tasksRoute } from "public_shiftbook/public_tasks/info";
import { lazy, Suspense } from "react";
import ShiftbookSkeleton from "shiftbook/components/ShiftbookSkeleton";
import {
  eventsRoute,
  infoboardRoute,
  instructionsRoute,
  shiftHandoverRoute,
} from "shiftbook/constants/submodules";

const Shiftbook = lazy(() => import("shiftbook/Shiftbook"));

export const SHIFTBOOK = "SHIFTBOOK.";
export const path = "/shiftbook";
export const route = {
  path: `${path}/*`,
  element: (
    <Suspense fallback={<ShiftbookSkeleton />}>
      <Shiftbook />
    </Suspense>
  ),
  children: [
    tasksRoute,
    eventsRoute,
    infoboardRoute,
    shiftHandoverRoute,
    instructionsRoute,
  ],
};
