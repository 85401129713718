import PropTypes from "prop-types";
import React from "react";
import { Badge, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

function QuickNavItem({ icon: Icon, ...props }) {
  const { t } = useTranslation("basics");

  return (
    <Col xs="auto">
      <NavLink
        to={props.to}
        className="d-flex align-items-center text-decoration-none"
      >
        {({ isActive }) => (
          <>
            {Icon && (
              <Icon
                size={24}
                className="me-2"
                color={isActive ? "var(--bs-link-color)" : "#595959"}
              />
            )}
            <span className={isActive ? "fw-semibold" : null}>
              {t(props.name)}
            </span>
            {!!props.badge && (
              <Badge
                pill
                variant="warning"
                bg="warning"
                className="text-bg-warning ms-2"
              >
                {props.badge}
              </Badge>
            )}
          </>
        )}
      </NavLink>
    </Col>
  );
}

QuickNavItem.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  badge: PropTypes.number,
};

export default QuickNavItem;
