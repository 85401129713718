import NavigationLink from "basics/components/Navigation/NavigationLink";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./LeftNavigation.module.scss";

function NavigationItem(props) {
  const [open, setOpen] = useState(false);
  const onClick = () => setOpen(!open);
  const location = useLocation();
  const isActive =
    location.pathname === props.to ||
    (location.pathname.startsWith(props.to) &&
      location.pathname.charAt(props.to.length) === "/");
  const getClassName = () => {
    return [styles.navItem, isActive ? "active" : 0, open ? "open" : 0]
      .filter(Boolean)
      .join(" ");
  };

  return (
    <div className={getClassName()} data-cy={props.dataCy ?? "NavigationItem"}>
      <NavigationLink
        name={props.name}
        icon={props.icon}
        to={props.to}
        onClick={props.children ? onClick : null}
        caret={!!props.children}
      />
      {props.children && (
        <div
          className={styles.navGroup}
          style={{ height: `${props.length * 37}px` }}
          data-cy="NavigationGroup"
        >
          {props.children}
        </div>
      )}
    </div>
  );
}

NavigationItem.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  to: PropTypes.string,
  length: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  dataCy: PropTypes.string,
};

export default NavigationItem;
