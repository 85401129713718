import {
  shiftOperationRoute,
  shiftOperationPath,
} from "basics/components/Administration/ShiftOperation";
import {
  policyManagementRoute,
  policyManagementPath,
} from "basics/components/Administration/PolicyManagement";
import { Administration } from "public_administration/components";
import { EntryMaskAdministration } from "public_entrymask/components";
import { RecurringTaskAdministration } from "public_shiftbook/public_tasks/components";
import { lazy } from "react";

const AssetAdministration = lazy(
  () => import("basics/components/Administration/Assets/Administration"),
);

export const ADMINISTRATION = "ADMINISTRATION";
export const path = `/${ADMINISTRATION.toLowerCase()}`;
export const entryMaskPath = "entry-masks";
export const assetPath = "assets";
export const RECURRING_TASKS = "SHIFTBOOK.RECURRING_TASKS";
export const recurringTaskPath = "recurring-tasks";
export { shiftOperationPath, policyManagementPath };

export const route = {
  path: `${path}/*`,
  element: <Administration />,
  children: [
    {
      path: `${entryMaskPath}/*`,
      element: <EntryMaskAdministration />,
      handle: { adminSections: 2 },
    },
    {
      path: `${assetPath}/*`,
      element: <AssetAdministration />,
      handle: { adminSections: 2 },
    },
    {
      path: `${recurringTaskPath}/*`,
      element: <RecurringTaskAdministration />,
      handle: { adminSections: 1 },
    },
    shiftOperationRoute,
    policyManagementRoute,
  ],
};
