import { format } from "date-fns";
import i18n from "i18next";

const dateTimeFormatter = (dateTime) => {
  return format(
    dateTime,
    i18n.t("date-format.pickerDateTimeFormatString", { ns: "common" }),
    new Date(),
  );
};

export default dateTimeFormatter;
