import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { selectUserPrivileges } from "basics/slices/authSlice";

function PrivilegeRequiredWrapper(props) {
  const { privilege, children } = props;

  const currentPrivileges = useSelector(selectUserPrivileges);

  if (currentPrivileges && currentPrivileges.includes(privilege)) {
    return children;
  }
  return <></>;
}

PrivilegeRequiredWrapper.propTypes = {
  privilege: PropTypes.string.isRequired,
  children: PropTypes.element,
};

export default PrivilegeRequiredWrapper;
