import { ModuleModel } from "@imes/module-host";
import { lazy } from "react";
import { USER_PROFILE } from "public_userProfile/info";
import { USER_PROFILE_COMPONENT } from "public_userProfile/components";

const userProfile = lazy(() => import("userProfile/UserProfile"));

const userProfileModule = new ModuleModel(USER_PROFILE);

userProfileModule.publicComponents = {
  [USER_PROFILE_COMPONENT]: userProfile,
};

export default userProfileModule;
