import { useGetEntryMaskConfigQuery } from "../services/entryMaskApi";
import useGetEntryMaskById from "./useGetEntryMaskById";
import useSelectedEntryMaskId from "./useSelectedEntryMaskId";
import { createSelector } from "@reduxjs/toolkit";

const selectMergedEntryMask = createSelector(
  [(config) => config, (config, mask) => mask],
  (config, mask) => ({
    uuid: mask?.guid,
    ...mask,
    ...config,
  }),
);

export default function useSelectedEntryMaskConfig(manualEntryMaskId) {
  const paramEntryMaskId = useSelectedEntryMaskId();

  const desiredEntryMaskId = manualEntryMaskId ?? paramEntryMaskId;
  const { entryMask, isFetching, isUninitialized } = useGetEntryMaskById(
    { meta: { visibility: "all_books" } },
    desiredEntryMaskId,
  );

  return useGetEntryMaskConfigQuery(
    { entryMask },
    {
      skip: !entryMask,
      selectFromResult: (res) => ({
        ...res,
        data: res.data && selectMergedEntryMask(res.data, entryMask),
        currentData:
          res.currentData && selectMergedEntryMask(res.currentData, entryMask),
        entryMaskMissing: !isUninitialized && !isFetching && !!!entryMask,
        selectedEntryMaskId: desiredEntryMaskId,
        selectedEntryMaskUUID: entryMask?.guid,
      }),
    },
  );
}
