import { ModuleModel } from "@imes/module-host";
import {
  RECURRING_TASK_ADMINISTRATION,
  TASK_ENTRY_LINK,
  TASK_SELECT,
  TASKS_COMPONENT,
  TASKS_NAV_ITEM,
  TASKS_QUICK_NAV_ITEM,
  TASKS_SKELETON,
} from "public_shiftbook/public_tasks/components";
import { TASKS } from "public_shiftbook/public_tasks/info";
import { lazy } from "react";
import NavItem from "shiftbook/modules/tasks/components/NavItem";
import QuickNavItem from "shiftbook/modules/tasks/components/QuickNavItem";
import TasksSkeleton from "shiftbook/modules/tasks/components/TasksSkeleton";
import recurringTasksSlice from "shiftbook/modules/tasks/slices/recurringTasksSlice";
import { recurringTasksApi } from "shiftbook/modules/tasks/services/recurringTasksApi";
import { tasksApi } from "shiftbook/modules/tasks/services/tasksApi";
import TaskSelect from "./components/TaskSelect";
import TaskEntryLink from "./components/TaskEntryLink";
import tasksSlice from "shiftbook/modules/tasks/slices/tasksSlice";

const Tasks = lazy(() => import("shiftbook/modules/tasks/Tasks"));
const Administration = lazy(
  () =>
    import("shiftbook/modules/tasks/components/Administration/Administration"),
);

const taskListModule = new ModuleModel(TASKS);

taskListModule.publicComponents = {
  [TASKS_COMPONENT]: Tasks,
  [TASKS_NAV_ITEM]: NavItem,
  [TASKS_QUICK_NAV_ITEM]: QuickNavItem,
  [RECURRING_TASK_ADMINISTRATION]: Administration,
  [TASKS_SKELETON]: TasksSkeleton,
  [TASK_SELECT]: TaskSelect,
  [TASK_ENTRY_LINK]: TaskEntryLink,
};

taskListModule.slices = [
  tasksSlice,
  recurringTasksSlice,
  tasksApi,
  recurringTasksApi,
];
taskListModule.middlewares = [
  tasksApi.middleware,
  recurringTasksApi.middleware,
];

export default taskListModule;
