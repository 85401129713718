import i18n from "i18next";
import createRecursiveFn from "./createRecursiveFn";

export const alphanumericSortingFn = (data) => {
  if (typeof data === "object") {
    data = Object.values(data);
  }
  return data?.sort(alphanumericSortingCompare) ?? [];
};

export const alphanumericSortingCompare = (a, b) =>
  a.name.localeCompare(b.name, i18n.resolvedLanguage, { numeric: true });

export const sortAssets = (data) => {
  return createRecursiveFn("items", alphanumericSortingFn)(data);
};
