import { Dashboard, DashboardSkeleton } from "public_dashboard/components";
import { Suspense } from "react";

export const DASHBOARD = "DASHBOARD";
export const path = `/${DASHBOARD.toLowerCase()}`;
export const route = {
  path: `${path}/*`,
  element: (
    <Suspense fallback={<DashboardSkeleton />}>
      <Dashboard />
    </Suspense>
  ),
};
