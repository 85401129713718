import { isRejectedWithValue } from "@reduxjs/toolkit";
import SnackbarAlertColour from "public_basics/constants/SnackbarAlertColour";
import i18n from "i18next";
import { ERROR_MAP } from "basics/listeners/errorMap";
import { enqueueSnackbar } from "notistack";

const addErrorListeners = (startListening) => {
  startListening({
    matcher: isRejectedWithValue,
    effect: (action) => {
      console.log("handling error for action: ", action);
      let userMessage = i18n.t("unknown-error", { ns: "basics" });
      let severity = null;
      if (action.payload.data) {
        const errorMap = ERROR_MAP[action.payload.data.response_code];
        const paramFn = errorMap?.transformParams;
        userMessage = errorMap
          ? i18n.t(errorMap.message, {
              ns: errorMap.ns,
              ...(paramFn
                ? paramFn(action.payload.data.error_params)
                : action.payload.data.error_params),
              interpolation: {
                skipOnVariables: false,
              },
            })
          : i18n.t("unknown-error", { ns: "basics" });
        severity = ERROR_MAP[action.payload.data.response_code]?.severity;
        if (typeof severity == "function") {
          severity = severity(action.payload.data.error_params);
        }
      }
      enqueueSnackbar(userMessage, {
        variant: severity || SnackbarAlertColour.ERROR,
        persist: severity === SnackbarAlertColour.ERROR,
      });
    },
  });
};

export default addErrorListeners;
