import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isToleranceSwitchOn: true,
  isMeasurementSwitchOn: true,
};

const entrymaskViewSlice = createSlice({
  name: "entrymaskViewSlice",
  initialState: initialState,
  reducers: {
    setMeasuringSwitchStatus(state, action) {
      state.isMeasurementSwitchOn = action.payload;
    },
    setToleranceSwitchStatus(state, action) {
      state.isToleranceSwitchOn = action.payload;
    },
  },
  selectors: {
    selectIsToleranceSwitchOn: (state) => state.isToleranceSwitchOn,
    selectIsMeasurementSwitchOn: (state) => state.isMeasurementSwitchOn,
  },
});

export const { setMeasuringSwitchStatus, setToleranceSwitchStatus } =
  entrymaskViewSlice.actions;
export const { selectIsToleranceSwitchOn, selectIsMeasurementSwitchOn } =
  entrymaskViewSlice.selectors;
export default entrymaskViewSlice;
