import PropTypes from "prop-types";
import { BaseEntryLink } from "public_basics/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetLinkedEntryInfoQuery } from "basics/services/entryLinksApi";
import moment from "moment";

EntryMaskEntryLink.propTypes = {
  link: PropTypes.shape({
    _links: PropTypes.shape({
      info: PropTypes.shape({ href: PropTypes.string.isRequired }).isRequired,
      delete: PropTypes.shape({ href: PropTypes.string.isRequired }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default function EntryMaskEntryLink(props) {
  const { link } = props;

  const { t } = useTranslation("basics");
  const { data: entryInfo } = useGetLinkedEntryInfoQuery({ link });
  return (
    <BaseEntryLink
      prefix={t("entry-mask")}
      link={link}
      name={entryInfo?.entry_mask_name}
      insertDate={moment.utc(entryInfo?.insert_date).local().toDate()}
      to={
        entryInfo &&
        `/entry-masks/${entryInfo.entry_mask_id}/entry/${entryInfo.id}`
      }
    />
  );
}
