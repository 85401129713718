import React from "react";
import Skeleton from "react-loading-skeleton";
import { Col, Row } from "react-bootstrap";

function DashboardSkeleton() {
  return (
    <div style={{ padding: "1em 4em 1em 2em" }}>
      <Row className="pb-3">
        <h2>
          <Skeleton />
        </h2>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={5} xl={4} className="mb-1">
          <Skeleton height={38} />
        </Col>
        <Col xs={12} md={6} lg={5} xl={4} className="mb-1">
          <Skeleton height={38} />
        </Col>
        <Col style={{ top: -12, marginLeft: "2em", position: "relative" }}>
          <Skeleton width={56} height={56} circle />
        </Col>
      </Row>
    </div>
  );
}

export default DashboardSkeleton;
