import moment from "moment";
import DaysOfWeek from "public_basics/constants/DaysOfWeek";
import { parseISO, isEqual, format, parse } from "date-fns";

export const parseUtcDate = (value) => moment.utc(value).local().toDate();

export const parseUtcDateNullable = (value) =>
  value ? parseUtcDate(value) : null;

export const reformatUtcDateString = (value, dateFormat) =>
  format(parseUtcDate(value), dateFormat);

export const formatISODate = (date, dateFormat) => {
  return format(new Date(parseISO(date)), dateFormat);
};

export const areDatesEqual = (leftDate, rightDate) => {
  if (!leftDate) {
    if (!rightDate) return true;
  }
  if (!rightDate) {
    if (leftDate) return false;
  }
  return isEqual(leftDate, rightDate);
};

export const reformatDateString = (date, oldFormat, newFormat) => {
  return format(parse(date, oldFormat, new Date()), newFormat);
};

export const convertWeekdaysNumToStr = (weekdays) => {
  return Object.entries(DaysOfWeek)
    .map(([k, v]) => weekdays.includes(v) && k)
    .filter(Boolean);
};

export const convertWeekdaysStrToNum = (weekdays) =>
  weekdays?.map((day) => DaysOfWeek[day]).filter((day) => day !== undefined);
