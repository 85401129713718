import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPwaInstallable: false,
  pwaInstallPrompt: null,
  isNewClientVersionAvailable: false,
};

export const pwaSlice = createSlice({
  name: "pwa",
  initialState,
  reducers: {
    savePwaInstallPrompt: (state, action) => {
      state.isPwaInstallable = true;
      state.pwaInstallPrompt = action.payload;
    },
    setNewClientVersionAvailable: (state, action) => {
      state.isNewClientVersionAvailable = action.payload;
    },
    setPwaInstalled: (state) => {
      state.isPwaInstallable = false;
    },
  },
  selectors: {
    selectPwaInstallPrompt: (state) => state.pwaInstallPrompt,
    selectIsPwaInstallable: (state) => state.isPwaInstallable,
    selectIsNewClientVersionAvailable: (state) =>
      state.isNewClientVersionAvailable,
  },
});

export const {
  selectIsNewClientVersionAvailable,
  selectPwaInstallPrompt,
  selectIsPwaInstallable,
} = pwaSlice.selectors;

export const {
  savePwaInstallPrompt,
  setNewClientVersionAvailable,
  setPwaInstalled,
} = pwaSlice.actions;
