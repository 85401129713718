import EntryItemsModel from "public_basics/models/EntryItemsModel";
import EntryLinksModel from "../../models/EntryLinksModel";
import DataTypes from "public_basics/constants/DataTypes";
import FileModel from "public_basics/models/FileModel";

export default class EntryMaskEntryListItemsModel extends EntryItemsModel {
  __createRow(entry) {
    const row = super.__createRow(entry);
    row.links = new EntryLinksModel(entry._links);
    row["-1"] = entry.measurement_date;
    row["-2"] = entry.update_date;
    row["-3"] = entry.insert_user;
    row["-4"] = entry.update_user;
    row["-5"] = entry.insert_date;
    entry.dynamic_values.forEach((dynamicValue) => {
      if (dynamicValue.data_type === DataTypes.type.File) {
        row[dynamicValue.field_id.toString()] = dynamicValue.files.map((file) =>
          FileModel.fromResponse(file),
        );
      } else if (this.__isDateOrTime(dynamicValue.data_type)) {
        row[dynamicValue.field_id.toString()] = dynamicValue.data;
      } else {
        row[dynamicValue.field_id.toString()] = dynamicValue.data;
      }
    });
    row["-6"] = entry.assets;
    row["guid"] = entry.uuid;
    return row;
  }

  __isDateOrTime(dataType) {
    return (
      dataType === DataTypes.type.Date ||
      dataType === DataTypes.type.DateTime ||
      dataType === DataTypes.type.Time
    );
  }
}
