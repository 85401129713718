import React from "react";
import PropTypes from "prop-types";
import CustomizedSelect from "./CustomizedSelect";
import { Table } from "react-bootstrap";
import { css } from "@emotion/css";
import { useTranslation } from "react-i18next";

export default function TableSelect(props) {
  const { t } = useTranslation(["entry-mask"]);
  const value = props.value ? { label: props.value, value: props.value } : null;

  const getRowClassName = (disabled) => {
    let className = "mx-2 ";
    if (!disabled)
      className += css`
        &:hover {
          background-color: #f8f9fa !important;
          cursor: pointer;
        }
      `;
    return className;
  };

  const customOption = ({ innerProps, value, isDisabled }) => {
    if (Array.isArray(value)) {
      return (
        <tr
          {...innerProps}
          className={getRowClassName(isDisabled)}
          onClick={isDisabled ? undefined : innerProps.onClick}
        >
          {value.map((text, index) => (
            <td
              key={"customOptionTableDefinition-" + innerProps.id + "-" + index}
            >
              {text}
            </td>
          ))}
        </tr>
      );
    }
    return null;
  };

  const customMenuList = ({ selectProps, children }) => {
    return (
      <div
        className={css`
          max-height: 440px;
          overflow-y: scroll;
        `}
        data-cy={"customizedSelect"}
      >
        <Table>
          <thead className={"table-primary sticky-top"}>
            <tr>
              {selectProps.headline.map((text, index) => (
                <th key={"customMenuListTableHeader-" + index}>{text}</th>
              ))}
            </tr>
          </thead>
          <tbody
            className={css`
              font-size: 14px;
              border-top: none !important;
            `}
          >
            {children}
          </tbody>
        </Table>
      </div>
    );
  };

  const customNoOptionsMessage = () => {
    return (
      <tr>
        <td
          colSpan={props.headline.length}
          className={css`
            border-bottom: none;
          `}
        >
          {t("no-options", { ns: "entry-mask" })}
        </td>
      </tr>
    );
  };

  return (
    <CustomizedSelect
      value={value}
      onChange={props.onChange}
      options={props.options}
      components={{
        Option: customOption,
        MenuList: customMenuList,
        NoOptionsMessage: customNoOptionsMessage,
      }}
      validation={props.validation}
      disabled={props.disabled}
      headline={props.headline}
    />
  );
}
TableSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string,
      ]).isRequired,
    }),
  ),
  disabled: PropTypes.bool,
  headline: PropTypes.arrayOf(PropTypes.string),
  validation: PropTypes.object,
};
