import React from "react";
import { CheckCircle, ExclamationCircle } from "react-bootstrap-icons";
import PropTypes from "prop-types";

export default function CheckedListEntry(props) {
  return (
    <li style={{ lineHeight: "1.25rem" }}>
      <span>
        {props.checked ? (
          <CheckCircle
            data-cy={"SuccessMark"}
            color={"currentColor"}
            className={"mx-1 text-success"}
            size={"0.8em"}
          />
        ) : (
          <ExclamationCircle
            data-cy={"FailMark"}
            color={"currentColor"}
            className={"mx-1 text-danger"}
            size={"0.8em"}
          />
        )}
      </span>
      <span>{props.children}</span>
    </li>
  );
}

CheckedListEntry.propTypes = {
  checked: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
