import PropTypes from "prop-types";
import { useGetUsersQuery } from "../../services/authApi";

const findUser = (guid, users) =>
  users?.find((user) => user.guid?.toLowerCase() === guid?.toLowerCase());

export default function UserGuidToName(props) {
  const { data: users, isLoading } = useGetUsersQuery();
  if (isLoading) return props.guid;
  return findUser(props.guid, users)?.username ?? props.guid;
}

UserGuidToName.propTypes = {
  guid: PropTypes.string.isRequired,
};
