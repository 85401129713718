import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "basics/dynamicBaseQuery";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { entryMaskApi } from "entrymask/services/entryMaskApi";
import { compareDesc } from "date-fns";
import { selectLiveUpdateEnabled } from "../slices/dashboardSlice";
import { createSocketConnector } from "basics/createSocketConnector";

const dashboardAdapter = createEntityAdapter({
  selectId: (entity) => entity.uuid,
  sortComparer: (a, b) => compareDesc(a.update_date, b.update_date),
});

export const dashboardSelectors = dashboardAdapter.getSelectors();

const initialState = dashboardAdapter.getInitialState();

const dashboardSocketConnector = createSocketConnector("/dashboard", {
  errorMessage: {
    message: "dashboard-socket-connect-error",
    ns: "entry-mask",
  },
  successMessage: {
    message: "dashboard-socket-reconnect-success",
    ns: "entry-mask",
  },
});
dashboardSocketConnector.endpoint = entryMaskApi.endpoints.getEntryMaskUris;

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: dynamicBaseQuery,
  tagTypes: ["DashboardEntries"],
  endpoints: (builder) => ({
    getDashboardEntries: builder.query({
      providesTags: ["DashboardEntries"],
      query: ({ params }) => ({
        params: params,
        token: "access",
        endpoint: entryMaskApi.endpoints.getEntryMaskUris,
        path: "_links.dashboard.href",
      }),
      transformResponse(baseQueryReturnValue) {
        return dashboardAdapter.setAll(initialState, baseQueryReturnValue.data);
      },
      async onCacheEntryAdded(
        arg,
        {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          getState,
          dispatch,
        },
      ) {
        const listener = () => {
          const state = getState();
          const liveUpdateEnabled = selectLiveUpdateEnabled(state);
          // todo: Remove socket connection when liveUpdateEnabled is false instead of checking if its true.
          //  https://gitlab.imes-solutions.com/plant-historian/web/client/-/issues/1046
          if (liveUpdateEnabled)
            dispatch(dashboardApi.util.invalidateTags(["DashboardEntries"]));
        };

        const deleteListener = ({ uuids }) => {
          const state = getState();
          const liveUpdateEnabled = selectLiveUpdateEnabled(state);
          if (!liveUpdateEnabled) return;
          updateCachedData((draft) => {
            dashboardAdapter.removeMany(draft, uuids);
          });
        };
        try {
          await cacheDataLoaded;

          await dashboardSocketConnector.on("changed", listener);
          await dashboardSocketConnector.on("delete", deleteListener);
        } catch {}
        await cacheEntryRemoved;
        dashboardSocketConnector.off("changed", listener);
        dashboardSocketConnector.off("delete", deleteListener);
      },
    }),
  }),
});

export const { useGetDashboardEntriesQuery } = dashboardApi;
