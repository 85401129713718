import PropTypes from "prop-types";
import EntryTypes from "public_basics/constants/EntryTypes";
import React, { useState } from "react";
import { Button, ListGroup } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { EntryMaskEntryLink } from "public_entrymask/components";
import { TaskEntryLink } from "public_shiftbook/public_tasks/components";
import AddLinkDialog from "./AddLinkDialog";
import { useGetEntryLinksQuery } from "basics/services/entryLinksApi";

// TODO: Consider removing these special components as they are virtually identical save for 2 props they
//  pass differently to `BaseEntryLink`.
//  Issue: https://gitlab.imes-solutions.com/plant-historian/web/client/-/issues/1015
const entryLinkComponentMapping = {
  [EntryTypes.entryMasksEntry]: EntryMaskEntryLink,
  [EntryTypes.task]: TaskEntryLink,
};

export default function EntryLinks(props) {
  const { t } = useTranslation(["common", "basics"]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data: entryLinksObj, isLoading } = useGetEntryLinksQuery({
    entry: props.entry,
  });

  const entryLinks = entryLinksObj?.links;

  if (entryLinks === undefined) {
    return null;
  }

  return (
    !isLoading && (
      <>
        <AddLinkDialog
          entry={props.entry}
          open={dialogOpen}
          linkedEntryUuids={[
            ...entryLinks.map((link) => link.entity_uuid),
            props.entry?.guid,
          ]}
          entryType={props.entryType}
          onClose={() => setDialogOpen(false)}
        />
        <Button
          data-cy={"link-entry-button"}
          variant={"success"}
          className={"mb-2"}
          onClick={() => setDialogOpen(true)}
        >
          <Plus /> {t("add-linked-entry", { ns: "basics" })}
        </Button>
        <ListGroup data-cy={"entry-link-table"}>
          {entryLinks.map((link) => {
            const Component = entryLinkComponentMapping[link.entity_type];

            return <Component link={link} key={link.entity_uuid} />;
          })}
        </ListGroup>
      </>
    )
  );
}

EntryLinks.propTypes = {
  entry: PropTypes.object,
  entryType: PropTypes.oneOf(Object.values(EntryTypes)).isRequired,
};
