import { Tab } from "react-bootstrap";
import React from "react";
import CustomPopoverBody from "../CustomPopover/CustomPopoverBody";
import PropTypes from "prop-types";
import styles from "./TabbedCustomPopover.module.scss";
import clsx from "clsx";
import itemsPropType from "./itemsPropType";

export default function TabbedCustomPopoverBody(props) {
  return (
    <CustomPopoverBody className={props.classNames?.popoverBody}>
      <Tab.Content
        className={clsx(
          styles.tabbedCustomPopoverBody,
          props.classNames?.tabContent,
        )}
      >
        {props.items.map((item) => (
          <Tab.Pane eventKey={item.key} key={item.key}>
            {item.content}
          </Tab.Pane>
        ))}
      </Tab.Content>
    </CustomPopoverBody>
  );
}

TabbedCustomPopoverBody.propTypes = {
  items: itemsPropType,
  classNames: PropTypes.shape({
    popoverBody: PropTypes.string,
    tabContent: PropTypes.string,
  }),
};
