export default class FilterArray {
  filter;

  push(filterExpression, linkedByOR = false) {
    if (!filterExpression) return;
    if (this.filter) {
      // Single Filter
      if (!Array.isArray(this.filter[0])) {
        const tempFilter = this.filter;
        this.filter = [];
        this.filter.push(
          tempFilter,
          this.__getOperator(linkedByOR),
          filterExpression,
        );
      } else {
        // filter contains already multiple filterExpressions
        this.filter.push(this.__getOperator(linkedByOR), filterExpression);
      }
    } else {
      this.filter = filterExpression;
    }
  }

  __getOperator(operator) {
    switch (operator) {
      case false:
        return "and";
      case true:
        return "or";
      default:
    }
  }
}
