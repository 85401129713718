import IconButton from "basics/IconButton";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import { ListGroupItem } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { useRemoveLinkedEntryMutation } from "../../services/entryLinksApi";

BaseEntryLink.propTypes = {
  prefix: PropTypes.string.isRequired,
  name: PropTypes.string,
  insertDate: PropTypes.instanceOf(Date),
  to: PropTypes.string,
  link: PropTypes.shape({
    _links: PropTypes.shape({
      delete: PropTypes.shape({ href: PropTypes.string.isRequired }).isRequired,
    }).isRequired,
  }),
};

export default function BaseEntryLink(props) {
  const { prefix, name, insertDate, to, link } = props;
  const [removeLink] = useRemoveLinkedEntryMutation();
  const { t } = useTranslation("basics");

  const hasInfo = name && insertDate;
  const handleDelete = () => removeLink({ link });

  return (
    <ListGroupItem className="d-flex align-items-center">
      {hasInfo ? (
        <Link data-cy="entry-link" className="me-auto" to={to}>
          {prefix}: {name}, {format(insertDate, "Ppp")}
        </Link>
      ) : (
        <Skeleton containerClassName="w-100" />
      )}
      <IconButton
        variant="danger"
        title={t("delete")}
        onClick={handleDelete}
        className="flex-shrink-0"
      >
        <Trash />
      </IconButton>
    </ListGroupItem>
  );
}
