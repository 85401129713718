import { lazy, Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AddShiftGroup, EditShiftGroup, ShiftGroup } from "./ShiftGroup";
import { AddShiftModel, EditShiftModel, ShiftModel } from "./ShiftModel";
import ShiftModelPageSkeleton from "./ShiftModel/ShiftModelPage/ShiftModelPageSkeleton";
import {
  AddTimeInterval,
  EditTimeInterval,
  TimeInterval,
} from "./TimeInterval";

const ShiftOperation = lazy(() => import("./ShiftOperation"));

const shiftOperationPath = "shift-operation";

const shiftOperationRoute = {
  path: shiftOperationPath,
  children: [
    {
      element: <ShiftOperation />,
      children: [
        {
          path: "shift-groups",
          element: <ShiftGroup />,
          children: [
            { path: ":uuid", element: <EditShiftGroup /> },
            { path: "add", element: <AddShiftGroup /> },
          ],
        },
        { path: "shift-models", element: <ShiftModel /> },
        {
          path: "time-intervals",
          element: <TimeInterval />,
          children: [
            { path: ":uuid", element: <EditTimeInterval /> },
            { path: "add", element: <AddTimeInterval /> },
          ],
        },
      ],
    },
    {
      element: (
        <Suspense fallback={<ShiftModelPageSkeleton />}>
          <Outlet />
        </Suspense>
      ),
      children: [
        { path: "shift-models/:uuid", element: <EditShiftModel /> },
        { path: "shift-models/add", element: <AddShiftModel /> },
      ],
    },
    { path: "*", element: <Navigate to="shift-groups" replace /> },
  ],
};

export { shiftOperationPath, shiftOperationRoute };
