import styles from "./CustomPopover.module.scss";
import { Popover } from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";

export default function CustomPopoverBody(props) {
  return (
    <Popover.Body
      bsPrefix={styles.customPopoverBody}
      className={props.className}
    >
      {props.children}
    </Popover.Body>
  );
}

CustomPopoverBody.propTypes = {
  className: PropTypes.string,
};
