import { Container, Row, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CustomModal } from "public_basics/components";
import { useTranslation } from "react-i18next";

export default function ChangeLayoutNameDialog(props) {
  const [name, setName] = useState("");
  const { t } = useTranslation(["layouts", "common"]);

  useEffect(() => {
    if (props.layoutToChange) setName(props.layoutToChange.name);
  }, [props.layoutToChange, setName]);

  return (
    <CustomModal
      open={!!props.layoutToChange}
      onClose={props.onClose}
      title={t("rename")}
      onSave={() => props.onSave(name)}
    >
      <Container>
        <Row>
          <Form.Control
            value={name}
            onChange={(event) => setName(event.target.value)}
            autoFocus
            id="name"
            label={t("name", { ns: "common" })}
            type="text"
          />
        </Row>
      </Container>
    </CustomModal>
  );
}

ChangeLayoutNameDialog.propTypes = {
  layoutToChange: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
