import TreeListOption from "./TreeListOption";
import useTreeSelect from "basics/customHooks/useTreeSelect";
import PropTypes from "prop-types";

export default function TreeList(props) {
  const { options, expanded, onToggleExpanded, onClickOption } = useTreeSelect(
    props.options,
    props.onChange,
    props.value,
    props.disabled,
    props.disableIndeterminate,
    false,
  );

  return options.map((option) => (
    <TreeListOption
      data={option}
      disableIndeterminate={props.disableIndeterminate}
      key={"tree-option-" + option.value}
      onToggleExpanded={() => onToggleExpanded(option.value)}
      onClick={() => onClickOption(option)}
      expandedItems={expanded}
    />
  ));
}

TreeList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      guid: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  disableIndeterminate: PropTypes.bool,
};
