import CustomizedSelect from "./CustomizedSelect";
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import TreeListOption from "../TreeList/TreeListOption";
import useTreeSelect from "basics/customHooks/useTreeSelect";

export default function TreeSelect(props) {
  const {
    options,
    onChangeSelected,
    formattedValue,
    expanded,
    onToggleExpanded,
  } = useTreeSelect(
    props.options,
    props.onChange,
    props.value,
    props.disabled,
    props.disableIndeterminate,
  );

  const customStyles = {
    multiValueRemove: (provided) => {
      return props.disabled ? { ...provided, display: "none" } : provided;
    },
  };

  const getValidation = () => {
    if (props.isValid) return { isValid: true };
    if (props.isValid === false) return { isInvalid: true };
    return null;
  };

  const customOption = (optionProps) => {
    return (
      <TreeListOption
        data={optionProps.data}
        onClick={optionProps.innerProps.onClick}
        disabled={props.disabled}
        onToggleExpanded={() => onToggleExpanded(optionProps.data.value)}
        expandedItems={expanded}
        forceExpanded={!!optionProps.selectProps.inputValue}
      />
    );
  };

  const onChange = (values) =>
    onChangeSelected(values.map((asset) => asset.value));

  return (
    <CustomizedSelect
      options={options}
      isMulti
      portalTarget={props.portalTarget}
      onChange={!props.disabled ? onChange : undefined}
      components={{
        Option: customOption,
      }}
      value={formattedValue}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      customStyles={
        props.customStyles
          ? _.merge(customStyles, props.customStyles)
          : customStyles
      }
      isClearable={!props.disabled}
      validation={getValidation()}
      className={props.className}
    />
  );
}

TreeSelect.defaultProps = {
  value: [],
};

TreeSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      guid: PropTypes.string,
    }),
  ),
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  customStyles: PropTypes.object,
  isValid: PropTypes.bool,
};
