const Privileges = {
  DELETE_ENTRIES: "DeleteHandValues",
  EDIT_TEXTBLOCK: "EditTextblock",
  ABORT_TASK: "AbortTask",
  CREATE_EXCEL_REPORTS: "HandValuesToExcel",
  ADMIN_WEB: "AdminWeb",
  BOOK: "Book",
  CREATE_API_TOKEN: "CreateAPIToken",
};

Object.freeze(Privileges);

export default Privileges;
