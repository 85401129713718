import GroupModel from "./GroupModel";

export default class GroupItemsModel {
  data;
  groupCount;
  totalCount;

  constructor(response) {
    this.data = this.__getData(response);
    this.groupCount = this.__getGroupCount(response);
    this.totalCount = this.__getTotalCount(response);
  }

  __getData(response) {
    if (!response) return [];
    return response.data.groups.map(this.__createGroup);
  }

  __createGroup(group) {
    return new GroupModel(group.key, group.items, group.count);
  }

  __getGroupCount(response) {
    if (response && response.data.group_count) return response.data.group_count;
    return 0;
  }

  __getTotalCount(response) {
    if (response && response.data.total_count) return response.data.total_count;
    return 0;
  }

  getItems() {
    return {
      data: this.data,
      groupCount: this.groupCount,
      totalCount: this.totalCount,
    };
  }
}
