import {
  ADMINISTRATION,
  assetPath,
  entryMaskPath,
  path,
  RECURRING_TASKS,
  recurringTaskPath,
  shiftOperationPath,
  policyManagementPath,
} from "public_administration/info";
import { NavigationItem, NavigationLink } from "public_basics/components";
import { ENTRYMASK, icon as EntryMaskIcon } from "public_entrymask/info";
import React from "react";
import { Building, ClockHistory, Gear, PersonLock } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { Icon as RecurringTaskIcon } from "public_shiftbook/public_tasks/info";

export default function NavItem() {
  const { t } = useTranslation("basics");
  return (
    <NavigationItem name={t(ADMINISTRATION)} icon={Gear} to={path} length={5}>
      <NavigationLink
        name={t(ENTRYMASK)}
        icon={EntryMaskIcon}
        to={`${path}/${entryMaskPath}`}
      />
      <NavigationLink
        name={t("physical-model")}
        icon={Building}
        to={`${path}/${assetPath}`}
      />
      <NavigationLink
        name={t(RECURRING_TASKS)}
        icon={RecurringTaskIcon}
        to={`${path}/${recurringTaskPath}`}
      />
      <NavigationLink
        name={t("shift-operation")}
        icon={ClockHistory}
        to={`${path}/${shiftOperationPath}`}
      />
      <NavigationLink
        name={t("policy-management")}
        icon={PersonLock}
        to={`${path}/${policyManagementPath}`}
      />
    </NavigationItem>
  );
}
