import React, { Suspense } from "react";
import { UiChecks } from "react-bootstrap-icons";
import { Tasks, TasksSkeleton } from "public_shiftbook/public_tasks/components";

export const TASKS = "SHIFTBOOK.TASKS";
export const path = "tasks";
export const route = {
  path: `${path}/*`,
  element: (
    <Suspense fallback={<TasksSkeleton />}>
      <Tasks />
    </Suspense>
  ),
};
export const Icon = UiChecks;

export const addTaskPath = "/shiftbook/tasks/new";
export const tasksPath = "shiftbook/tasks";

export const deriveTask = (navigate, addLinkedEntryModel) =>
  navigate(addTaskPath, { state: { addLinkedEntryModel } });
