import { parse } from "date-fns";
import i18n from "i18next";

const dateTimeParser = (dateTime) => {
  return parse(
    dateTime,
    i18n.t("date-format.pickerDateTimeFormatString", { ns: "common" }),
    new Date(),
  );
};

export default dateTimeParser;
