import _ from "lodash";
import { parseUtcDateNullable, parseUtcDate } from "basics/utils/dateTimeUtils";
import TaskPositionTypes from "shiftbook/modules/tasks/constants/TaskPositionTypes";
import TaskPriority from "shiftbook/modules/tasks/constants/TaskPriority";

const getInitPositionTypeFromPrevID = (referenceID) => {
  // 0 -> first, not undefined -> below, undefined -> last (for add dialog)
  if (referenceID === 0) return TaskPositionTypes.FIRST;
  if (referenceID) return TaskPositionTypes.BELOW;
  return TaskPositionTypes.LAST;
};

export const transformTask = (obj) => ({
  ...obj,
  uuid: obj.guid, // needed for linking a task (props.entry.uuid)
  assignees: obj.assignees.map((assignee) => assignee.toLowerCase()),
  earliest_start_date: parseUtcDateNullable(obj.earliest_start_date),
  deadline_date: parseUtcDateNullable(obj.deadline_date),
  position: {
    type: getInitPositionTypeFromPrevID(obj.prev_id),
    reference: { id: obj.prev_id },
  },
});

export const compareTasks = (obj, other) =>
  obj.title === other.title &&
  obj.description === other.description &&
  _.isEqual(obj.assignees, other.assignees) &&
  _.isEqual(obj.assets, other.assets) &&
  _.isEqual(obj.files, other.files) &&
  _.isEqual(obj.pictures, other.pictures) &&
  obj.deadline_date === other.deadline_date &&
  obj.priority === other.priority;

export const validateTask = (obj) =>
  obj.title !== "" &&
  obj.description !== "" &&
  obj.assets.length > 0 &&
  (!!obj.position.reference.id ||
    obj.position.type.value === TaskPositionTypes.FIRST.value ||
    obj.position.type.value === TaskPositionTypes.LAST.value);

const attributesToSubmit = [
  "title",
  "description",
  "assets",
  "files_to_attach",
  "files_to_delete",
  "deadline_date",
  "assignees",
  "position",
  "priority",
];

const attributesToCopy = [
  "assignees",
  "title",
  "description",
  "earliest_start_date",
  "assets",
  "deadline_date",
  "assignees",
  "priority",
];

export const taskToSubmitState = (obj) => {
  return _.pick(
    {
      ...obj,
      title: obj.title.trimEnd(),
      description: obj.description.trimEnd(),
      position: {
        type: obj.position.type.value,
        reorder_guid: obj.position.reference.guid,
      },
    },
    attributesToSubmit,
  );
};

export const createCleanTaskCopy = (obj, copyAttachments) => ({
  ..._.pick(obj, attributesToCopy),
  ...(copyAttachments
    ? {
        pictures: obj.pictures,
        files: obj.files.map((file) => ({
          ...file,
          toAttach: true,
        })),
      }
    : {
        pictures: [],
        files: [],
      }),
  position: {
    type: TaskPositionTypes.LAST,
    reference: {},
  },
});

export const getEmptyTask = () => ({
  id: null,
  guid: null,
  uuid: null,
  title: "",
  description: "",
  assignees: [],
  assets: [],
  files: [],
  files_to_attach: [],
  files_to_delete: [],
  deadline_date: null,
  comments: [],
  _links: {},
  pictures: [],
  position: {
    type: TaskPositionTypes.LAST,
    reference: {},
  },
  recurring_task_guid: null,
  earliest_start_date: null,
  priority: TaskPriority.medium,
});

export const transformTaskComment = (obj) => ({
  ...obj,
  insert_date: parseUtcDate(obj.insert_date),
  replies: obj.replies?.map(transformTaskComment),
});

export const compareTaskPositions = (position1, position2) => {
  // Ideally we compare guids here, but since we initially do not receive guids from the
  // response, but only the ID we need to compare IDs here.
  return (
    position1.type.value === position2.type.value &&
    position1.reference.id === position2.reference.id
  );
};

export const getFilesToAttachAndDelete = (
  newRecurringTask,
  oldRecurringTask = { files: [], pictures: [] },
) => {
  const newFiles = [
    ...newRecurringTask.files,
    ...newRecurringTask.pictures,
  ].map((file) => file.guid);
  const oldFiles = [
    ...oldRecurringTask.files,
    ...oldRecurringTask.pictures,
  ].map((file) => file.guid);

  return {
    files_to_attach: _.difference(newFiles, oldFiles),
    files_to_delete: _.difference(oldFiles, newFiles),
  };
};
