import { ModuleModel } from "@imes/module-host";
import DashboardSkeleton from "dashboard/components/DashboardSkeleton";
import {
  DASHBOARD_COMPONENT,
  DASHBOARD_NAV_ITEM,
  DASHBOARD_SKELETON,
} from "public_dashboard/components";
import { DASHBOARD } from "public_dashboard/info";
import NavItem from "dashboard/components/NavItem";
import { lazy } from "react";
import { dashboardApi } from "./services/dashboardApi";
import dashboardSlice from "dashboard/slices/dashboardSlice";

const Dashboard = lazy(() => import("dashboard/Dashboard"));

const dashboardModule = new ModuleModel(DASHBOARD);

dashboardModule.reducerPrefix = "dashboard";
dashboardModule.middlewares = [dashboardApi.middleware];
dashboardModule.slices = [dashboardApi, dashboardSlice];
dashboardModule.publicComponents = {
  [DASHBOARD_COMPONENT]: Dashboard,
  [DASHBOARD_NAV_ITEM]: NavItem,
  [DASHBOARD_SKELETON]: DashboardSkeleton,
};

export default dashboardModule;
