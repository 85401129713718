import { Button, Col, FormControl, InputGroup, Row } from "react-bootstrap";
import TimeSpanDirection from "../../constants/TimeSpanDirection";
import { CaretLeftFill, CaretRightFill } from "react-bootstrap-icons";
import React from "react";
import { add, sub } from "date-fns";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/css";

export default function TimeSpanSelection(props) {
  const { t } = useTranslation("common");
  const onTimeSpanChanged = (e) => {
    const parsedValue = parseInt(e.currentTarget.value);
    if (parsedValue && parsedValue > 0) {
      props.onTimeSpanChanged(parsedValue);
    }
  };

  const onClickTimeSpanPlus = () => {
    const newTimeSpan = props.timeSpan + 1;
    props.onTimeSpanChanged(newTimeSpan);
  };

  const onClickTimeSpanMinus = () => {
    if (props.timeSpan > 1) {
      const newTimeSpan = props.timeSpan - 1;
      props.onTimeSpanChanged(newTimeSpan);
    }
  };

  const updateDate = (direction = TimeSpanDirection.forward) => {
    if (!props.endDate) return;

    const duration = { [props.timeSpanUnit]: props.timeSpan };

    const newEndDate = (direction === TimeSpanDirection.forward ? add : sub)(
      props.endDate,
      duration,
    );

    props.onEndDateChanged(newEndDate);
  };

  return (
    <Row>
      <Col className={"d-flex justify-content-end"}>
        <Button
          data-cy={"unitsOfTimeToDateBackward"}
          className={"w-100"}
          onClick={() => updateDate(TimeSpanDirection.backward)}
        >
          <CaretLeftFill />
          <span className="align-text-bottom">{t("backward")}</span>
        </Button>
      </Col>
      <Col>
        <InputGroup>
          <Button
            data-cy={"unitsOfTimeToDateMinus"}
            onClick={onClickTimeSpanMinus}
            disabled={props.timeSpan <= 1}
          >
            -
          </Button>
          <FormControl
            value={props.timeSpan}
            data-cy={"unitsOfTimeToDateInput"}
            onChange={(e) => {
              onTimeSpanChanged(e);
            }}
            className={css`
              text-align: center;
            `}
          />
          <Button
            data-cy={"unitsOfTimeToDatePlus"}
            onClick={onClickTimeSpanPlus}
          >
            +
          </Button>
        </InputGroup>
      </Col>
      <Col>
        <Button
          data-cy={"unitsOfTimeToDateForward"}
          className={"w-100"}
          onClick={() => updateDate(TimeSpanDirection.forward)}
        >
          <span className={"align-text-bottom"}>{t("forward")}</span>
          <CaretRightFill />
        </Button>
      </Col>
    </Row>
  );
}
