const createRecursiveFn = (recursiveField, action) => (data) => {
  return action(data).map((obj) => {
    const { [recursiveField]: childData, ...rest } = obj;
    return {
      ...rest,
      [recursiveField]: createRecursiveFn(recursiveField, action)(childData),
    };
  });
};
export default createRecursiveFn;
