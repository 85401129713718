import LinkedEntriesLinksModel from "public_basics/models/LinkedEntriesLinksModel";

export default class EntryLinksModel {
  getEntry;
  editEntry;
  deleteEntry;
  getEntryHistory;
  splitEntry;
  links;

  constructor(obj) {
    this.getEntry = obj.get.href;
    this.editEntry = obj.edit.href;
    this.deleteEntry = obj.delete.href;
    this.getEntryHistory = obj.history.href;
    this.splitEntry = obj.split.href;
    this.links = LinkedEntriesLinksModel.fromResponse(obj.links);
  }
}
