import React from "react";
import { Stack } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

function ShiftModelPageSkeleton() {
  return (
    <>
      <div className="mb-2 d-flex align-items-center">
        <h5 className="me-3">
          <Skeleton width={150} />
        </h5>
      </div>
      <Stack gap={2}>
        <Skeleton height={140} />
        <hr />
        <Skeleton height={60} />
        <hr />
        <Skeleton height={70} />
        <hr />
        <Skeleton height={120} />
      </Stack>
    </>
  );
}

export default ShiftModelPageSkeleton;
