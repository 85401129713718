import styles from "./TabbedCustomPopover.module.scss";
import { Nav } from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";

export default function TabbedCustomPopoverTabLink(props) {
  return (
    <Nav.Link
      eventKey={props.eventKey}
      as={"button"}
      data-cy={props.eventKey}
      bsPrefix={styles.tabbedCustomPopoverHeaderButton}
    >
      {props.title}
    </Nav.Link>
  );
}

TabbedCustomPopoverTabLink.propTypes = {
  eventKey: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};
