import { NavigationLink } from "public_basics/components";
import {
  Icon as TasksIcon,
  path,
  TASKS,
} from "public_shiftbook/public_tasks/info";
import { path as shiftbookPath } from "public_shiftbook/info";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetUnreadTaskCountQuery } from "../services/tasksApi";

export default function NavItem() {
  const { t } = useTranslation("basics");
  const { data: unreadTasksCount } = useGetUnreadTaskCountQuery();

  return (
    <NavigationLink
      to={`${shiftbookPath}/${path}`}
      name={t(TASKS)}
      icon={TasksIcon}
      badge={unreadTasksCount}
    />
  );
}
