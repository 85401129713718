import { NavigationItem } from "public_basics/components";
import { DASHBOARD, path } from "public_dashboard/info";
import React from "react";
import { Columns } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function NavItem() {
  const { t } = useTranslation("basics");
  return <NavigationItem to={path} name={t(DASHBOARD)} icon={Columns} />;
}
