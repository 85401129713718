import { createSlice } from "@reduxjs/toolkit";

const recurringTasksSlice = createSlice({
  name: "recurringTasks",
  initialState: { guidToShow: null },
  reducers: {
    openedRecurringTaskModal: (state, action) => {
      state.guidToShow = action.payload;
    },
    closedRecurringTaskModal: (state) => {
      state.guidToShow = null;
    },
  },
  selectors: {
    selectRecurringTaskGuidToShow: (state) => state.guidToShow,
  },
});

export const { openedRecurringTaskModal, closedRecurringTaskModal } =
  recurringTasksSlice.actions;

export const { selectRecurringTaskGuidToShow } = recurringTasksSlice.selectors;

export default recurringTasksSlice;
