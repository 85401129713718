import { OverlayTrigger, Popover } from "react-bootstrap";
import React from "react";
import { InfoCircle } from "react-bootstrap-icons";
import PropTypes from "prop-types";

function InfoPopover(props) {
  const {
    infoDescription,
    overlayPlacement = "left",
    showDelay = 250,
    hideDelay = 500,
    iconClassName = "",
  } = props;

  const createIcon = () => {
    return (
      <div className={"d-inline " + iconClassName}>
        <InfoCircle />
      </div>
    );
  };

  const descriptionPopover = (
    <Popover id={"popover-basic"} data-cy={"FieldDescriptionPopup"}>
      <Popover.Body>{infoDescription}</Popover.Body>
    </Popover>
  );

  if (infoDescription == null || infoDescription === "") {
    return null;
  } else {
    return (
      <OverlayTrigger
        trigger={["hover", "focus", "click"]}
        delay={{ show: showDelay, hide: hideDelay }}
        overlay={descriptionPopover}
        placement={overlayPlacement}
      >
        {createIcon()}
      </OverlayTrigger>
    );
  }
}

InfoPopover.propTypes = {
  infoDescription: PropTypes.string,
  overlayPlacement: PropTypes.oneOf([
    "auto-start",
    "auto",
    "auto-end",
    "top-start",
    "top",
    "top-end",
    "right-start",
    "right",
    "right-end",
    "bottom-end",
    "bottom",
    "bottom-start",
    "left-end",
    "left",
    "left-start",
  ]),
  showDelay: PropTypes.number,
  hideDelay: PropTypes.number,
  iconClassName: PropTypes.string,
};
export default InfoPopover;
