const DataGridColumnsDataTypes = {
  date: "date",
  number: "number",
  boolean: "boolean",
  datetime: "datetime",
  string: "string",
  buttons: "buttons",
  assets: "assets",
  assignees: "assignees",
  user: "user"
};

Object.freeze(DataGridColumnsDataTypes);

export default DataGridColumnsDataTypes;
