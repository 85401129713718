import { parse } from "date-fns";
import i18n from "i18next";

const dateParser = (date) => {
  parse(
    date,
    i18n.t("date-format.pickerDateFormatString", { ns: "common" }),
    new Date(),
  );
};

export default dateParser;
