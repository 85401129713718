import React from "react";
import CustomPopover from "../../CustomPopover/CustomPopover";
import CustomPopoverHeader from "../../CustomPopover/CustomPopoverHeader";
import CustomPopoverBody from "../../CustomPopover/CustomPopoverBody";
import { useTranslation } from "react-i18next";

export default function ShowSettingsPopover(props) {
  const { t } = useTranslation("basics");

  const updatePopover = (
    <>
      <CustomPopoverHeader title={t("settings")}></CustomPopoverHeader>

      <CustomPopoverBody className="p-2">
        {props.additionalSettings && props.additionalSettings()}
        {props.displayView}{" "}
      </CustomPopoverBody>
    </>
  );

  return (
    <CustomPopover
      show={props.showPopover}
      onHide={() => props.setShowPopover(false)}
      popoverContent={updatePopover}
    >
      {props.children}
    </CustomPopover>
  );
}
