import React, { useCallback, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import TimeSpanMode from "public_basics/constants/TimeSpanMode";
import TimeUnit from "../../constants/TimeUnit";
import { Funnel } from "react-bootstrap-icons";
import FromToFilter from "./FromToFilter";
import UntilFilter from "./UntilFilter";
import PopoverFooter from "./PopoverFooter";
import { isValid, sub } from "date-fns";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CurrentFilterOptionsPreview from "./CurrentFilterOptionsPreview";
import TabbedCustomPopover from "../TabbedCustomPopover/TabbedCustomPopover";

function TimeFilter(props) {
  const getDateFieldOption = useCallback(
    (value) => {
      return props.dateFieldOptions.find((option) => option.value === value);
    },
    [props.dateFieldOptions],
  );

  const [timeSpanUnit, setTimeSpanUnit] = useState(props.defaultTimeUnit);
  const [timeSpan, setTimeSpan] = useState(props.defaultTimeSpan);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const { t } = useTranslation(["basics", "common"]);
  const [fromToStartDate, setFromToStartDate] = useState(props.startDate);
  const [fromToEndDate, setFromToEndDate] = useState(props.endDate);
  const [untilEndDate, setUntilEndDate] = useState(props.endDate);
  const [dateField, setDateField] = useState(
    getDateFieldOption(props.dateField),
  );
  const latestActiveDateOptionTab = useRef(TimeSpanMode.dateFromDateTo);
  const initialState = useRef(null);

  const getUntilStartDate = () => {
    if (!untilEndDate) return null;
    const duration = { [timeSpanUnit]: timeSpan };
    return sub(untilEndDate, duration);
  };

  const onActiveTabChanged = (nextActiveTab) => {
    if (
      nextActiveTab === TimeSpanMode.dateFromDateTo ||
      nextActiveTab === TimeSpanMode.unitsOfTimeToDate
    )
      latestActiveDateOptionTab.current = nextActiveTab;
  };

  const getDatesToApply = () => {
    const dates = {};
    if (latestActiveDateOptionTab.current === TimeSpanMode.dateFromDateTo) {
      dates.startDate = fromToStartDate;
      dates.endDate = fromToEndDate;
    } else {
      dates.startDate = getUntilStartDate();
      dates.endDate = untilEndDate;
    }
    if (dates.startDate) dates.startDate.setSeconds(0, 0);
    if (dates.endDate) dates.endDate.setSeconds(0, 0);
    return dates;
  };

  const saveAsInitialState = () => {
    initialState.current = {
      ...getDatesToApply(),
      dateField,
      timeSpan,
      timeSpanUnit,
    };
  };

  const resetToInitialState = () => {
    setTimeSpanUnit(initialState.current.timeSpanUnit);
    setTimeSpan(initialState.current.timeSpan);
    setFromToStartDate(initialState.current.startDate);
    setFromToEndDate(initialState.current.endDate);
    setUntilEndDate(initialState.current.endDate);
    setDateField(initialState.current.dateField);
  };

  const hidePopover = () => {
    setIsPopoverVisible(false);
    resetToInitialState();
    props.onHide && props.onHide();
  };

  const showPopover = () => {
    setIsPopoverVisible(true);
    saveAsInitialState();
    props.onShow && props.onShow();
  };

  const onApply = () => {
    const { startDate, endDate } = getDatesToApply();
    saveAsInitialState();
    props.onApply(startDate, endDate, dateField.value);
    hidePopover();
  };

  const getApplyDisabled = () => {
    if (latestActiveDateOptionTab.current === TimeSpanMode.dateFromDateTo) {
      const areDatesUnset = !fromToStartDate && !fromToEndDate;
      const areDatesValid = isValid(fromToStartDate) && isValid(fromToEndDate);

      return !(areDatesUnset || areDatesValid);
    } else return !(!untilEndDate || isValid(untilEndDate));
  };

  return (
    <Row className="align-items-center justify-content-end gx-2 gy-1">
      <CurrentFilterOptionsPreview
        startDate={props.startDate}
        endDate={props.endDate}
        filterActive={props.filterActive}
        onSetFilterActive={props.onSetFilterActive}
        additionalItems={props.additionalItems}
        dateField={getDateFieldOption(props.dateField).label || ""}
      />

      <TabbedCustomPopover
        title={t("filter-settings")}
        onSelect={onActiveTabChanged}
        defaultActiveKey={TimeSpanMode.dateFromDateTo}
        show={isPopoverVisible}
        onHide={hidePopover}
        footer={
          <PopoverFooter
            disabled={getApplyDisabled()}
            onReset={() => {
              setFromToEndDate(null);
              setFromToStartDate(null);
              setUntilEndDate(null);
              setTimeSpanUnit(props.defaultTimeUnit);
              setTimeSpan(props.defaultTimeSpan);
              setDateField(getDateFieldOption(props.dateField));
              props.onReset && props.onReset();
            }}
            onApply={onApply}
            onCancel={hidePopover}
          />
        }
        items={[
          {
            key: TimeSpanMode.dateFromDateTo,
            title: t("from-till", { ns: "common" }),
            content: (
              <FromToFilter
                startDate={fromToStartDate}
                endDate={fromToEndDate}
                onStartDateChanged={setFromToStartDate}
                onEndDateChanged={setFromToEndDate}
                dateFieldOptions={props.dateFieldOptions}
                dateField={dateField}
                onSetDateField={setDateField}
              />
            ),
          },
          {
            key: TimeSpanMode.unitsOfTimeToDate,
            title: t("till", { ns: "common" }),
            content: (
              <UntilFilter
                endDate={untilEndDate}
                onEndDateChanged={setUntilEndDate}
                timeSpan={timeSpan}
                onTimeSpanChanged={setTimeSpan}
                timeSpanUnit={timeSpanUnit}
                onTimeSpanUnitChanged={setTimeSpanUnit}
                dateFieldOptions={props.dateFieldOptions}
                dateField={dateField}
                onSetDateField={setDateField}
              />
            ),
          },
          ...props.additionalItems,
        ]}
      >
        <Col xs="auto">
          <Button
            variant={"outline-primary"}
            className={"my-auto"}
            data-cy={"openFilterPopoverButton"}
            onClick={showPopover}
          >
            <Funnel />
          </Button>
        </Col>
      </TabbedCustomPopover>
    </Row>
  );
}

export default TimeFilter;

TimeFilter.propTypes = {
  defaultTimeUnit: PropTypes.string,
  defaultTimeSpan: PropTypes.number,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  dateFieldOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.any, label: PropTypes.string }),
  ),
  additionalCheckText: PropTypes.string,
  filterActive: PropTypes.bool,
  onSetFilterActive: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  additionalItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.element.isRequired,
    }),
  ),
  onHide: PropTypes.func,
  onShow: PropTypes.func,
};

TimeFilter.defaultProps = {
  additionalItems: [],
  defaultTimeUnit: TimeUnit.days,
  defaultTimeSpan: 1,
};
