import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "basics/dynamicBaseQuery";
import { tasksApi } from "shiftbook/modules/tasks/services/tasksApi";
import { transformRecurringTask } from "shiftbook/modules/tasks/utils/recurringTaskUtils";

const recurringTasksAdapter = createEntityAdapter({
  selectId: (entity) => entity.guid,
});
const initialState = recurringTasksAdapter.getInitialState();

//TODO merge with tasksApi
// Issue: https://gitlab.imes-solutions.com/plant-historian/web/client/-/issues/1004
export const recurringTasksApi = createApi({
  reducerPath: "recurringTasksApi",
  baseQuery: dynamicBaseQuery,
  tagTypes: ["RecurringTask"],
  endpoints: (build) => ({
    getRecurringTasks: build.query({
      query: () => ({
        path: "_links.recurring_tasks.get.href",
        endpoint: tasksApi.endpoints.getTasksUris,
        token: "access",
      }),
      transformResponse: (baseQueryReturnValue) =>
        recurringTasksAdapter.setAll(initialState, baseQueryReturnValue),
      onQueryStarted: async (arg, api) => {
        try {
          const { data } = await api.queryFulfilled;

          Object.values(data.entities).forEach((recurringTask) => {
            api.dispatch(
              recurringTasksApi.util.updateQueryData(
                "getRecurringTask",
                { guid: recurringTask.guid },
                () => recurringTask,
              ),
            );
          });
        } catch {}
      },
    }),
    getRecurringTask: build.query({
      query: ({ guid }) => ({
        path: "_links.recurring_tasks.get.href",
        endpoint: tasksApi.endpoints.getTasksUris,
        urlSuffix: guid,
        token: "access",
      }),
      onQueryStarted: async (arg, api) => {
        try {
          const { data } = await api.queryFulfilled;
          api.dispatch(
            recurringTasksApi.util.updateQueryData(
              "getRecurringTasks",
              undefined,
              (draft) =>
                recurringTasksAdapter.setOne(draft ?? initialState, data),
            ),
          );
        } catch {}
      },
      providesTags: (result) =>
        result
          ? [{ type: "RecurringTask", id: result.guid }]
          : ["RecurringTask"],
    }),
    addRecurringTask: build.mutation({
      query: ({ body }) => ({
        path: "_links.recurring_tasks.add.href",
        endpoint: tasksApi.endpoints.getTasksUris,
        method: "POST",
        body: body,
        token: "access",
      }),
    }),
    editRecurringTask: build.mutation({
      query: ({ recurringTask, body }) => ({
        url: recurringTask._links.get.href,
        method: "PUT",
        body: body,
        token: "access",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "RecurringTask", id: arg.recurringTask.guid },
      ],
    }),
  }),
});

export const {
  useGetRecurringTasksQuery,
  useLazyGetRecurringTasksQuery,
  useGetRecurringTaskQuery,
  useAddRecurringTaskMutation,
  useEditRecurringTaskMutation,
} = recurringTasksApi;

export const recurringTasksSelectors = recurringTasksAdapter.getSelectors();

export const selectTransformedRecurringTask = createSelector(
  [(res) => res],
  transformRecurringTask,
);
